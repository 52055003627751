import React from "react";

import {noop, zip} from "lodash";

import {FormRenderer, ImagesSectionRenderer, UploaderContentState, UploaderSubmitHandler} from "../Uploader";

import Uploader from "../../containers/Uploader";
import UploaderFormSection from "../../containers/UploaderFormSection";
import UploaderImagesSection from "../../containers/UploaderImagesSection";
import {IsolationBatchMetadata, IsolationImageData} from "../../redux/actions";
import IsolationUploadItem, {UploadedImageMetadata} from "./IsolationUploadItem";
import {UploadListRenderer} from "./UploaderImagesSection";
import {API_URL, MAX_UPLOAD_RESOLUTION} from "../../env";
import {DefaultApi} from "../../api/apis";
import {Configuration} from "../../api";

export interface SubmittedBatch {
    imagesMetadata: Array<IsolationImageData>;
    batchMetadata: IsolationBatchMetadata;
}

interface IsolationUploaderProps extends React.Props<IsolationUploader> {
    disabledFields: string[];
    erasedFields: string[];
    existingFilenames: string[];
    initialValues?: SubmittedBatch;
    onError: (title: string, text: string) => void;
    onStateChanged: (state: UploaderContentState) => void;
    onSubmit: (submittedBatch: SubmittedBatch) => void;
}

class IsolationUploader extends React.Component<IsolationUploaderProps> {
    private api = new DefaultApi(new Configuration({basePath: API_URL}));

    public static defaultProps = {
        onStateChanged: noop
    };

    private makeFormRenderer = (): FormRenderer<IsolationBatchMetadata> => {
        return (onSubmit, onChange) => <UploaderFormSection
            initialValues={this.props.initialValues !== undefined ? this.props.initialValues.batchMetadata : undefined}
            onStateChanged={onChange}
            onSubmit={onSubmit}
            disabledFields={this.props.disabledFields}
            erasedFields={this.props.erasedFields}
            hiddenFields={[]}
        />;
    };

    private findInitialMetadata(name: string): UploadedImageMetadata | undefined {
        if (this.props.initialValues === undefined) {
            return undefined;
        }

        const meta = this.props.initialValues.imagesMetadata.find(i => i.name === name);

        if (meta === undefined) {
            return undefined;
        }

        return {
            umPerPx: meta!.umPerPx !== undefined ? String(meta!.umPerPx) : ""
        };
    }

    private uploadListRenderer: UploadListRenderer = items =>
        items.map((item) => [
                <IsolationUploadItem fileName={item.name} key={item.name} done={item.uploadStatus.done}
                                     error={item.uploadStatus.error} selected={item.isSelected}
                                     initialState={this.findInitialMetadata(item.name)}/>,
                item.name
            ] as [React.ReactElement<any>, string]
        );

    private imagesSectionRenderer: ImagesSectionRenderer<IsolationImageData> = (onChange) => <UploaderImagesSection
        buttonText={"Add images"}
        existingFilenames={this.props.existingFilenames}
        hint={<>
            <h4>Upload hint</h4>
            <p>Images uploaded to each Batch are analyzed together as replicate samples using the parameters stated in Batch
               properties. Pixel size assigned to an individual image will, for that particular image, override the value stated
               in the Batch properties. Islets below Minimum islet size are excluded from analysis and their contours and
               ellipses are not displayed. Session code cannot be changed after the first batch was uploaded. All other parameters 
               can be modified and images can be removed or added throughout the Session. Acceptable formats include png, jpg, tiff.</p>
        </>}
        imageAttributeNames={["Pixel size"]}
        onImagesChange={onChange}
        prefilledImages={this.props.initialValues !== undefined ? this.props.initialValues.imagesMetadata : []}
        uploadListRenderer={this.uploadListRenderer}
        maxImageSizeNote={<>
            <p>Maximum image size: {MAX_UPLOAD_RESOLUTION}&times;{MAX_UPLOAD_RESOLUTION}px.</p>
        </>}
    />;

    private onSubmit: UploaderSubmitHandler<IsolationImageData, IsolationBatchMetadata> =
        async (uploadIds, imagesMetadata, batchMetadata) => {
        if (imagesMetadata.length === 0) {
            this.props.onError("Validation error", "Please, upload at least one image");
            return false;
        }

        const imagesMetadataWithId = imagesMetadata.map((imageMeta) => ({
            imageId: imageMeta.imageId || uploadIds[imageMeta.name!],
            minIsletSize: imageMeta.minIsletSize,
            name: imageMeta.name!,
            umPerPx: imageMeta.umPerPx
        }));

        try {
            const responses = await Promise.all(imagesMetadataWithId.map(image => this.api.imageInfo({imageId: image.imageId})));

            const highResolutionResults = zip(imagesMetadataWithId, responses)
                .filter(([_, response]) => response!.resolution.some(dim => dim > MAX_UPLOAD_RESOLUTION))
                .map(([image, _]) => image!.name);

            if (highResolutionResults.length > 0) {
                this.props.onError(
                    "Validation error",
                    `The resolution of following images is too large (larger than ${MAX_UPLOAD_RESOLUTION}px): `
                    + highResolutionResults.join(", ")
                );

                return false;
            } else {
                this.props.onSubmit({batchMetadata, imagesMetadata: imagesMetadataWithId});
                return true;
            }
        } catch(e) {
            this.props.onError(
                "Application error",
                "There was an error while submitting data to the server"
            );

            return false;
        }
    };

    public render() {
        return <Uploader
            onStateChanged={this.props.onStateChanged}
            onSubmit={this.onSubmit}
            formRenderer={this.makeFormRenderer()}
            imagesSectionRenderer={this.imagesSectionRenderer}
        />;
    }
}

export default IsolationUploader;
