import {connect} from "react-redux";

import UploaderImagesSectionComponent from "../components/upload/UploaderImagesSection";
import {displayErrorMessage, StoreShape} from "../redux/actions";

export default connect(
    (state: StoreShape, props) => ({
        uploadStatus: state.imageUploadStatus
    }),
    (dispatch) => ({
        onError: (title: string, text: string) => dispatch(displayErrorMessage({title, text}))
    })
)(UploaderImagesSectionComponent);
