import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    simpleComparisonBatchCount,
    simpleComparisonFailures, simpleComparisonImageCount,
    simpleComparisonResultData,
    simpleComparisonStatus,
    simpleComparisonSubmissionStatus, simpleComparisonSummaryRequest
} from "../redux/selectors";
import PromptWrapper from "../components/PromptWrapper";
import SimpleComparisonResultsComponent from "../components/results/SimpleComparisonResults";
import {AsyncRequestState, setSimpleComparisonImageReported} from "../redux/actions";
import LoadingScreen from "../components/LoadingScreen";
import download from "downloadjs";
import {DefaultApi} from "../api/apis";
import {Configuration} from "../api";
import {API_URL} from "../env";

const getFilename = (response: Response) => {
    const header = response.headers.get("content-disposition");

    if (header === null) {
        return null;
    }

    return header.match(/filename=(.+)/)![1]
};

const api = new DefaultApi(new Configuration({basePath: API_URL}));

const SimpleComparisonResults = () => {
    const submissionStatus = useSelector(simpleComparisonSubmissionStatus);
    const resultsStatus = useSelector(simpleComparisonStatus);
    const batchCount = useSelector(simpleComparisonBatchCount);
    const items = useSelector(simpleComparisonResultData);
    const summaryRequest = useSelector(simpleComparisonSummaryRequest);
    const failedRequests = useSelector(simpleComparisonFailures);
    const dispatch = useDispatch();

    const [zipDownloadState, setZipDownloadState] = useState(AsyncRequestState.NONE);
    const [completeZipDownloadState, setCompleteZipDownloadState] = useState(AsyncRequestState.NONE);

    const itemsCount = useSelector(simpleComparisonImageCount);

    const downloadZip = async () => {
        setZipDownloadState(AsyncRequestState.PENDING);

        try {
            const response = await api.comparisonZipRaw({body: summaryRequest});
            const blob = await response.value();

            download(blob, getFilename(response.raw) || 'results.zip');
            setZipDownloadState(AsyncRequestState.DONE);
        } catch (e) {
            setZipDownloadState(AsyncRequestState.FAILED);
        }
    };

    const downloadCompleteZip = async () => {
        setCompleteZipDownloadState(AsyncRequestState.PENDING);

        try {
            const response = await api.comparisonCompleteZipRaw({body: summaryRequest});
            const blob = await response.value();

            download(blob, getFilename(response.raw) || 'results.zip');
            setCompleteZipDownloadState(AsyncRequestState.DONE);
        } catch (e) {
            setCompleteZipDownloadState(AsyncRequestState.FAILED);
        }
    };

    const Wrapper: React.SFC<React.Props<any>> = props =>
        <div className="card-wrapper bg-gray">{props.children}</div>;

    const Alert: React.SFC<any> = () => <PromptWrapper
        when={batchCount > 0}
        message="If you leave this page, the data in this session will be lost. Continue?"
    />;

    if (submissionStatus === AsyncRequestState.PENDING) {
        return <Wrapper>
            <Alert/>
            <LoadingScreen text={<>Sending data to the server<br />(estimated remaining time is {itemsCount * 0.25} minutes)</>}/>
        </Wrapper>;
    }

    if (submissionStatus === AsyncRequestState.FAILED) {
        return <Wrapper>
            <Alert/>
            <p>There was an error while sending the data to the server. Please make sure your internet connection is
                not down, try refreshing the page (F5) and contact us at isletnet@mild.blue if problems persist.</p>
        </Wrapper>;
    }

    if (resultsStatus === AsyncRequestState.EXPIRED) {
        return <Wrapper>
            <Alert/>
            <p>The results for this batch are not available anymore. Try refreshing the page (F5).</p>
        </Wrapper>;
    }

    if (resultsStatus === AsyncRequestState.PENDING || resultsStatus === AsyncRequestState.NONE) {
        return <Wrapper>
            <Alert/>
            <LoadingScreen text={<>Waiting for the server to process the data<br />(estimated remaining time is {itemsCount * 0.25} minutes)</>}/>
        </Wrapper>;
    }

    if (resultsStatus === AsyncRequestState.FAILED) {
        return <Wrapper>
            <Alert/>
            <p>Unexpected error happened and some of the requests could not be processed by the server. Please make sure
                your internet connection is not down, try refreshing the page (F5) and contact us at isletnet@mild.blue
                if problems persist.</p>
        </Wrapper>;
    }

    return <Wrapper>
        <Alert/>
        <SimpleComparisonResultsComponent items={items}
                                          failedRequests={failedRequests}
                                          onZip={downloadZip}
                                          onCompleteZip={downloadCompleteZip}
                                          zipDownloadState={zipDownloadState}
                                          onSetReported={((imageIndex, isReported) => dispatch(setSimpleComparisonImageReported({
                                              imageIndex,
                                              isReported,
                                              batchId: '0'
                                          })))}
                                          completeZipDownloadState={completeZipDownloadState} />
    </Wrapper>;
};

export default SimpleComparisonResults
