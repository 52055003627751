import React from "react";

import {noop, sum} from "lodash";

import BatchResultList, {BatchResultListProps, ResultComponentProps} from "../../components/results/BatchResultList";

import Pix2PixUploader from "../../containers/upload/Pix2PixUploader";
import TruncatedText from "../TruncatedText";
import {SubmittedBatch} from "../upload/Pix2PixUploader";

import Pix2PixBatchResults from "../../containers/results/Pix2PixBatchResultContainer";
import ResultsNavigation from "./ResultsNavigation";
import {Pix2PixBatchResult, Pix2PixItemResult} from "./types";

import Pix2PixImageDetails from "../../containers/results/Pix2PixImageDetails";
import {AsyncRequestState} from "../../redux/actions";
import AsyncButton from "../AsyncButton";

class ResultContentRenderer extends React.Component<ResultComponentProps<Pix2PixBatchResult>> {
    private renderItem = (batch: Pix2PixBatchResult) => (item: Pix2PixItemResult, i: number) => {
        return <Pix2PixImageDetails key={i} userMaskStatistics={item.result.userMaskStatistics}
                                    comparisonResults={item.result} batchMetadata={batch.metadata}
                                    computedMaskStatistics={item.result.maskStatistics} imageIndex={i}
                                    isReported={false} onSetReported={noop} setReportedState={AsyncRequestState.NONE} umPerPx={1} /> // TODO this line is a workaround for a strange bug in typing of connected components
    };

    private renderItemHeader = (item: Pix2PixItemResult, i: number) => <TruncatedText text={item.userMaskName}/>;

    public render() {
        return <><div className="col-3">
            <ResultsNavigation<Pix2PixItemResult> onSelect={this.props.onSelect}
                                                  batchId={this.props.batch.metadata.batchId}
                                                  items={this.props.batch.items} selectedItem={this.props.selectedItem}
                                                  itemRenderer={this.renderItem(this.props.batch)}
                                                  itemHeaderRenderer={this.renderItemHeader} />
        </div>
            <div className="col-9">
                <Pix2PixBatchResults batchId={this.props.batch.metadata.batchId} items={this.props.batch.items}
                                     selectedItem={this.props.selectedItem} tabState={this.props.tabState}
                                     onSelect={this.props.onSelect} onTabStateChanged={this.props.onTabStateChanged}/>
            </div>
        </>
    }
}

const batchEditationTransformer = (batch: Pix2PixBatchResult): SubmittedBatch => ({
    batchMetadata: batch.metadata,
    imagesMetadata: batch.items
});

interface Pix2PixBatchResultListProps {
    batches: {
        [batchId: string]: Pix2PixBatchResult;
    };
    xlsxZipDownloadState: AsyncRequestState;
    onBatchSubmit: (batch: SubmittedBatch) => void;
    onRemoveBatch: (batchId: string) => void;
    onXlsxZip: BatchResultListProps<Pix2PixBatchResult, SubmittedBatch>["onSummary"];
    onZip: BatchResultListProps<Pix2PixBatchResult, SubmittedBatch>["onSummary"];
    zipDownloadState: AsyncRequestState;
}

const doNothing = () => {return};

const Pix2PixBatchResultList: React.SFC<Pix2PixBatchResultListProps> = (props) => {
    const numFiles = sum(Object.values(props.batches).map(b => b.items.length));
    const fileSize = (numFiles * 43).toFixed(0);

    return (
        <BatchResultList<Pix2PixBatchResult, SubmittedBatch>
            batchEditationTransformer={batchEditationTransformer}
            batches={props.batches}
            onBatchSubmit={props.onBatchSubmit}
            onExcludeBatch={doNothing}
            onIncludeBatch={doNothing}
            onRemoveBatch={props.onRemoveBatch}
            resultComponent={ResultContentRenderer}
            summaryButton={
                <div className="pix2pix-summary-btns">
                    <AsyncButton className="pix2pix-summary-btn" onClick={props.onXlsxZip} asyncActionState={props.xlsxZipDownloadState} title="Image-wise XLSX and islet-wise XLSX">
                        Download XLSX ZIP <span className="file-size">(1 MB)</span>
                    </AsyncButton>
                    <AsyncButton className="pix2pix-summary-btn" onClick={props.onZip} asyncActionState={props.zipDownloadState} title="Uploaded images and masks, computed masks, islet contours, islet ellipses, mask comparison, histogram, image-wise XLSX, islet-wise XLSX, PDF report">
                        Download ZIP <span className="file-size">({fileSize} MB)</span>
                    </AsyncButton>
                </div>
            }
            uploaderComponent={Pix2PixUploader}
        />
    );
};

export default Pix2PixBatchResultList;