import React from "react";

import classNames from "classnames";
import produce from "immer";
import {fromPairs, memoize, noop, uniqueId} from "lodash";
import Mousetrap from "mousetrap";
import {actions, Control, LocalForm} from "react-redux-form";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

import Icon from "../Icon";
import ModalWindow from "../ModalWindow";
import ZoomedImage from "../ZoomedImage";

import ImageFetcher from "../../containers/ImageFetcher";
import {makePngDataUrl} from "../../utils/images";
import {DEFAULT_ZOOM_INFO, ZoomInfo} from "../ZoomableStack";

import {DISABLE_EXPERIMENTAL} from "../../env";
import ClickableLink from "../ClickableLink";
import noScroll from "no-scroll";

export interface MenuItem {
    extendedDescription?: string;
    id: string;
    isExperimental?: boolean;
    isZoomable?: boolean;
    title: string;
    hotkey?: string;
    legend?: React.ReactElement<any>;
}

export enum TabArea {
    LEFT = "left",
    RIGHT = "right",
}

export interface TabState {
    leftActiveTabIndex: number;
    rightActiveTabIndex: number;
    zoomed: boolean;
    zoomedArea: TabArea;
    batchId: string;
}

export const defaultTabState: TabState = {
    leftActiveTabIndex: 0,
    rightActiveTabIndex: 0,
    zoomed: false,
    zoomedArea: TabArea.LEFT,
    batchId: ""
};

export interface ResultsContentProps {
    tabState: TabState;
    included?: boolean;
    isLegendVisible: boolean;
    leftMenuItems: Array<MenuItem>
    onNext: () => void;
    onPrevious: () => void;
    onSetIncluded?: (included: boolean) => void;
    onSetLegendVisible: (isVisible: boolean) => void;
    onTabStateChanged: (state: TabState) => void;
    rightMenuItems: Array<MenuItem>;
    fileName: string;
    failedItemNames?: string[];
}

interface ResultsContentState {
    zoomInfo?: ZoomInfo;
    esqModalOpen: boolean;
    isLineChanged: boolean;
    showCustomLines: boolean;
}

interface NavigationHintProps {
    shortcuts: {[shortcut: string]: string}
}

const NavigationHint: React.SFC<NavigationHintProps> = props => <div className="row">
    <h3>Navigation hints</h3>
    <h4>Zoom in/out</h4>
    <ul>
        <li>Mouse wheel</li>
        <li>Touchpad</li>
        <li><strong>i</strong> and <strong>o</strong> keys</li>
        <li>Drag with mouse to pan</li>
    </ul>
    <h4>Move between tabs</h4>
    <ul>
        <li>Arrows, left/right</li>
        <li>Numeric shortcuts</li>
    </ul>
    <h4>Move between images</h4>
    <ul>
        <li>Arrows, up/down</li>
    </ul>
    <h4>Separating adjacent islets</h4>
    <ul>
        <li>Draw line: left-clicks</li>
        <li>End line: right-click</li>
        <li>Cancel nascent line: right-click</li>
        <li>Erase this line: right-click a point</li>
        <li>Erase all lines: right-click image</li>
    </ul>

    <h3>Shortcuts</h3>
    <ul className="list-unstyled">
    {Object.entries(props.shortcuts).map(([shortcut, title]) => <li key={shortcut}>{shortcut.toUpperCase()}: {title}</li>)}
    </ul>
</div>;

const HighlightedTitle: React.SFC<{ item: MenuItem }> = ({item}) => {
    if (item.hotkey === undefined) {
        return <>{item.title}</>;
    }

    const isHotkeyInTitle = item.title.includes(item.hotkey.toLowerCase())
        || item.title.includes(item.hotkey.toUpperCase());

    if (!isHotkeyInTitle) {
        return <>{item.title}</>;
    }

    const index = item.title.search(new RegExp(
        `[${item.hotkey.toUpperCase()}${item.hotkey.toLowerCase()}]`
    ));

    const leftPart = item.title.substring(0, index);
    const rightPart = item.title.substring(index + 1);

    return (
        <span title="Keyboard shortcuts can be used.">
            {leftPart}<strong>{item.title[index]}</strong>{rightPart}
        </span>
    );
};

class ResultsContent extends React.Component<ResultsContentProps, ResultsContentState> {
    private MAX_SCALE = 5;

    private formDispatch: any;

    constructor(props: ResultsContentProps) {
        super(props);
        this.state = {
            esqModalOpen: false,
            isLineChanged: false,
            showCustomLines: !!this.props.tabState.batchId
        };
    }

    public static defaultProps = {
        imagesLoading: false,
        onNext: noop,
        onPrevious: noop,
        onStartup: noop
    };

    public componentDidUpdate(prevProps: ResultsContentProps) {
        this.setupBindings();

        if (this.formDispatch) {
            if (prevProps.included !== this.props.included) {
                this.formDispatch(actions.change("data.included", this.props.included ? "include" : "exclude"));
            }
        }
    }

    public componentDidMount() {
        this.setupBindings();
        this.setupHotkeyBindings();
    }

    private setupHotkeyBindings() {
        this.props.leftMenuItems.concat(this.props.rightMenuItems)
            .map((item, i): [MenuItem, number] => [item, i])
            .filter(([item, i]) => item.hotkey !== undefined)
            .forEach(([item, i]) => {
                Mousetrap.bind(item.hotkey!, () => this.tabSelectedFromMerged(i));
            });
    }

    private setupBindings() {
        // Repeated calls to Mousetrap.bind for the same key combination replace the callback. Thus, we refresh the
        // bindings every time the props are updated so that the correct bindings are in place when we zoom something.
        // It's ugly, but it works.
        if (this.props.tabState.zoomed) {
            this.setupHotkeyBindings();

            Mousetrap.bind("esc", (e) => {
                e.preventDefault();
                this.closeModal()
            });

            Mousetrap.bind("down", (e) => {
                e.preventDefault();
                this.props.onNext();
            });

            Mousetrap.bind("up", (e) => {
                e.preventDefault();
                this.props.onPrevious();
            });

            Mousetrap.bind("left", (e) => {
                e.preventDefault();
                this.tabSelectedFromMerged(this.getZoomedTabIndex() - 1);
            });

            Mousetrap.bind("right", (e) => {
                e.preventDefault();
                this.tabSelectedFromMerged(this.getZoomedTabIndex() + 1);
            });

            Mousetrap.bind("i", this.zoomIn);
            Mousetrap.bind("o", this.zoomOut);
            Mousetrap.bind("0", this.zoomActual);
        }
    }

    public componentWillUnmount() {
        this.props.leftMenuItems.concat(this.props.rightMenuItems)
            .filter(i => i.hotkey !== undefined)
            .forEach(i => {
                Mousetrap.unbind(i.hotkey!);
            });

        Mousetrap.unbind("down");
        Mousetrap.unbind("up");
        Mousetrap.unbind("left");
        Mousetrap.unbind("right");

        Mousetrap.unbind("i");
        Mousetrap.unbind("o");
        Mousetrap.unbind("0");
    }

    private attachFormDispatch = (formDispatch: any) => this.formDispatch = formDispatch;

    private onFormChanged = (data: any) => {
        const included = data.included === "include";

        if (this.props.onSetIncluded && this.props.included !== included) {
            this.props.onSetIncluded(included);
        }
    };

    private onHideLinesChanged = (hide: boolean) => {
        this.setState({showCustomLines: hide})
    };

    private zoomIn = () => {
        this.setState(prevState => produce(prevState, draft => {
            if (!this.props.tabState.zoomed) {
                return;
            }

            draft.zoomInfo = {...DEFAULT_ZOOM_INFO, ...draft.zoomInfo};
            draft.zoomInfo.scale = Math.min(draft.zoomInfo.scale + 0.1, this.MAX_SCALE);
        }));
    };

    private zoomOut = () => {
        this.setState(prevState => produce(prevState, draft => {
            if (!this.props.tabState.zoomed) {
                return;
            }

            draft.zoomInfo = {...DEFAULT_ZOOM_INFO, ...draft.zoomInfo};
            draft.zoomInfo.scale = Math.max(draft.zoomInfo.scale - 0.1, draft.zoomInfo.minScale);
        }));
    };

    private zoomActual = () => {
        this.setState(prevState => produce(prevState, draft => {
            if (!this.props.tabState.zoomed) {
                return;
            }

            draft.zoomInfo = {...DEFAULT_ZOOM_INFO, ...draft.zoomInfo};
            draft.zoomInfo.scale = draft.zoomInfo.minScale;
        }));
    };

    private zoom = memoize((tabId: string) => () => {
        const area = this.props.leftMenuItems.map(i => i.id).includes(tabId) ? TabArea.LEFT : TabArea.RIGHT;

        this.props.onTabStateChanged(produce(this.props.tabState, draft => {
            draft.zoomed = true;
            draft.zoomedArea = area;

            switch (area) {
                case TabArea.LEFT:
                    draft.leftActiveTabIndex = this.props.leftMenuItems.map(i => i.id).indexOf(tabId);
                    break;
                case TabArea.RIGHT:
                    draft.rightActiveTabIndex = this.props.rightMenuItems.map(i => i.id).indexOf(tabId);
                    break;
            }
        }));
    });

    private zoomChanged = (zoomInfo: ZoomInfo) => this.setState(prevState => produce(prevState, (draft: ResultsContentState) => {
        draft.zoomInfo = {...draft.zoomInfo, ...zoomInfo};
        if (draft.zoomInfo.scale === 0) {
            draft.zoomInfo.scale = zoomInfo.minScale;
        }
    }));

    private unzoom = () => {
        this.props.onTabStateChanged(produce(this.props.tabState, draft => {
            draft.zoomed = false;
        }));
    };

    private toggleLegend = () => this.props.onSetLegendVisible(!this.props.isLegendVisible);

    private tabSelected = (area: TabArea) => (index: number) => {
        this.props.onTabStateChanged(produce(this.props.tabState, draft => {
            switch (area) {
                case TabArea.LEFT:
                    draft.leftActiveTabIndex = index;
                    break;
                case TabArea.RIGHT:
                    draft.rightActiveTabIndex = index;
                    break;
            }
        }));
    };

    private tabSelectedFromMerged = (index: number) => {
        const leftLength = this.props.leftMenuItems.filter(i => !i.isExperimental || !DISABLE_EXPERIMENTAL).length;
        const rightLength = this.props.rightMenuItems.filter(i => !i.isExperimental || !DISABLE_EXPERIMENTAL).length;

        this.props.onTabStateChanged(produce(this.props.tabState, draft => {
            if (index >= 0 && index < leftLength) {
                draft.leftActiveTabIndex = index;
                draft.zoomedArea = TabArea.LEFT;
            } else if (index >= 0 && index < leftLength + rightLength) {
                draft.rightActiveTabIndex = index - leftLength;
                draft.zoomedArea = TabArea.RIGHT;
            }
        }));
    };

    private renderTabArea(area: TabArea, items: MenuItem[]) {
        let zoomedTabIndex = 0;
        switch (area) {
            case TabArea.LEFT:
                zoomedTabIndex = this.props.tabState.leftActiveTabIndex;
                break;
            case TabArea.RIGHT:
                zoomedTabIndex = this.props.tabState.rightActiveTabIndex;
                break;
        }

        return <div className="col-6" key={area}>
            <Tabs className="results-content-tabs"
                  onSelect={this.tabSelected(area)}
                  selectedIndex={zoomedTabIndex}>
                <div className="results-content-tabs-img-wrap">
                    {items.filter((item: MenuItem) => !item.isExperimental || !DISABLE_EXPERIMENTAL).map((item: MenuItem) =>
                        <TabPanel key={item.id}>
                            <ImageFetcher imageId={item.id} imageRenderer={(data) =>
                                <img onClick={this.zoom(item.id)} alt={item.title}
                                     src={makePngDataUrl(data)} title={item.extendedDescription}/>}
                            />
                        </TabPanel>
                    )}
                </div>
                <TabList>
                    {items.filter((item: MenuItem) => !item.isExperimental || !DISABLE_EXPERIMENTAL).map((item: MenuItem) =>
                        <Tab key={item.id}><HighlightedTitle item={item}/></Tab>
                    )}
                </TabList>
                <ClickableLink onClick={noop} title="Full screen" className="zoom-btn"><Icon name="arrow-alt"/></ClickableLink>
            </Tabs>
        </div>;
    }

    private getZoomedTabIndex() {
        switch (this.props.tabState.zoomedArea) {
            case TabArea.LEFT:
                return this.props.tabState.leftActiveTabIndex;
            case TabArea.RIGHT:
                return this.props.leftMenuItems.length + this.props.tabState.rightActiveTabIndex;
        }

        return -1;
    }

    private closeModal() {
        if (this.state.isLineChanged) {
            this.setState({esqModalOpen: true})
            return;
        }
        this.unzoom()
        noScroll.off();
    }

    public render() {
        const mergedItems = this.props.leftMenuItems.concat(this.props.rightMenuItems).filter(item => !item.isExperimental || !DISABLE_EXPERIMENTAL);

        const modalStyles = {
            content: {
                bottom: 0,
                height: "100%",
                left: 0,
                maxWidth: "none",
                position: "fixed",
                right: 0,
                top: 0
            }
        };

        const idLeft = uniqueId();
        const idRight = uniqueId();

        const initialFormState = {
            included: this.props.included ? "include" : "exclude"
        };

        const title = this.props.onSetIncluded
            ? <>
              {this.props.fileName} <div className="switch-field">
                  <LocalForm model="data" onChange={this.onFormChanged} getDispatch={this.attachFormDispatch} initialState={initialFormState}>
                      <Control.radio model=".included" id={idLeft} value="include"/>
                      <label htmlFor={idLeft} title="Include image in the Overall Summary."><Icon name="check-circle"/> Include</label>
                      <Control.radio model=".included" id={idRight} value="exclude"/>
                      <label htmlFor={idRight} title="Remove image (artifact) from Overall Summary and use it for training."><Icon name="close"/> Exclude</label>
                  </LocalForm>
              </div>
          </>
            : this.props.fileName;

        const hideLinesSwitch = this.props.onSetIncluded
          ? <>
              Custom lines
             <div className="switch-field">
                 <form>
                     <input onChange={(e) => this.onHideLinesChanged(true)} type="radio" checked={this.state.showCustomLines} id="showLinesBtn"/>
                     <label htmlFor={"showLinesBtn"} title="Show custom lines"><Icon name="check-circle"/> Show</label>
                     <input onChange={(e) => this.onHideLinesChanged(false)} type="radio" checked={!this.state.showCustomLines} id="hideLinesBtn"/>
                     <label htmlFor={"hideLinesBtn"} title="Hide custom lines"><Icon name="close"/> Hide</label>
                 </form>
          </div>
          </>
          : this.props.fileName;

        return (
            <>
                <ModalWindow onRequestClose={() => this.closeModal()} isOpen={this.props.tabState.zoomed} style={modalStyles} title={title} hideLinesSwitch={hideLinesSwitch} overlayClassName="zoom-overlay">{requestClose =>
                    <Tabs className="zoomed-results" selectedIndex={this.getZoomedTabIndex()}
                          onSelect={this.tabSelectedFromMerged}>
                        <div className="tabs">
                            <TabList className="results-content-tabs modal">
                                {mergedItems.map((item: MenuItem) =>
                                    <Tab key={item.id} className={classNames({
                                        "tablist-item-left": this.props.leftMenuItems.some(i => i.id === item.id),
                                        "tablist-item-right": this.props.rightMenuItems.some(i => i.id === item.id)
                                    })}>
                                        <HighlightedTitle item={item}/>
                                    </Tab>
                                )}
                                <li className="right legend-toggle" onClick={this.toggleLegend}>{this.props.isLegendVisible ? "Hide" : "Show"} legend</li>
                            </TabList>
                        </div>
                        <div className="content">
                            {mergedItems.map((item: MenuItem) =>
                                <TabPanel key={item.id}>
                                    <>
                                        <ImageFetcher imageId={item.id} imageRenderer={
                                            (data) => <ZoomedImage image={makePngDataUrl(data)}
                                                                   maxScale={this.MAX_SCALE}
                                                                   zoom={this.state.zoomInfo}
                                                                   batchId={this.props.tabState.batchId}
                                                                   imageName={this.props.fileName}
                                                                   isZoomAdjustable={
                                                                       item.isZoomable === undefined || item.isZoomable
                                                                   }
                                                                   onZoomChanged={this.zoomChanged}
                                                                   showEscMenu={this.state.esqModalOpen}
                                                                   onEsqClose={() =>  this.setState({esqModalOpen: false})}
                                                                   onEsqQuit={() => {
                                                                     this.setState({esqModalOpen: false});
                                                                     this.unzoom();
                                                                   }}
                                                                   showCustomLines={this.state.showCustomLines}
                                                                   isLineChanged={this.state.isLineChanged}
                                                                   onLineChanged={(isChanged) => this.setState({isLineChanged: isChanged})}
                                            />
                                        }/>
                                        {this.props.isLegendVisible && <div className="image-legend">
                                            <div className="image-legend-content">
                                                <NavigationHint shortcuts={fromPairs(mergedItems.filter(i => i.hotkey !== undefined).map(i => [i.hotkey, i.title]))}/>
                                                {item.legend || null}
                                            </div>
                                        </div>}
                                    </>
                                </TabPanel>
                            )}
                        </div>
                    </Tabs>
                }</ModalWindow>
                <div className="row full result-tabs">
                    {this.renderTabArea(TabArea.LEFT, this.props.leftMenuItems)}
                    {this.renderTabArea(TabArea.RIGHT, this.props.rightMenuItems)}
                </div>
            </>
        );
    }
}

export default ResultsContent;
