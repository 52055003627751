import React, {useState} from 'react'
import DropZone from "react-dropzone";
import ModalWindow from "../ModalWindow";
import ZoomedImage from "../ZoomedImage";
import ClickableLink from "../ClickableLink";
import imagePlaceholder from "../../images/image-placeholder-files.jpg";
import {makePngDataUrl, readImage} from "../../utils/images";
import UploaderImagesSection from "./UploaderImagesSection";
import {MAX_UPLOAD_RESOLUTION} from "../../env";
import ClassNames from "classnames";
import TruncatedText from "../TruncatedText";
import Icon from "../Icon";
import {useAsync} from "react-async-hook";
import XLSX from 'xlsx';
import {Dictionary, fromPairs} from 'lodash';
import {useSelector} from "react-redux";
import {StoreShape} from "../../redux/actions";
import {removeExtension} from "../../utils/fileNames";

const spreadsheetType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export interface SimpleComparisonTemplate {
    name: string
    imageOrder: string[]
    parameters: {
        [imageName: string]: {
            isletCount: number
            minIsletSize: number
            purity: number
            umPerPx: number
            volumeIe: number
        }
    }
}

const ImageEntry = (props: {onSelect: () => void, onRemove: () => void, selected: boolean, fileName: string}) => {
    const uploadStatus = useSelector((state: StoreShape) => state.imageUploadStatus)[props.fileName];

    // Set up status text.
    let statusText = "Uploading...";

    const done = uploadStatus && uploadStatus.done;
    const error = uploadStatus && uploadStatus.error;

    if (done) {
        statusText = "";
    }

    if (error) {
        statusText = "Upload error";
    }

    return (
        <li onClick={props.onSelect} className={ClassNames({
            done,
            error,
            selected: props.selected
        })}>
            <TruncatedText text={props.fileName} />
            <span className="status-text">{statusText}</span>
            <ClickableLink title="Remove image" onClick={(e: any) => {
                e.preventDefault();
                props.onRemove();
            }}><Icon name="close"/></ClickableLink>
        </li>
    );
};

interface Props {
    images: File[]
    onImagesChanged: (images: File[]) => void
    template?: SimpleComparisonTemplate
    onTemplateChanged: (template?: SimpleComparisonTemplate) => void
}

const loadTemplate = async (file: File): Promise<SimpleComparisonTemplate> => {
    const workbookBinary = await new Promise(((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as any);
        reader.readAsBinaryString(file);
    }));

    const workbook = XLSX.read(workbookBinary, {type: "binary"});
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(sheet, {header: ["name", "umPerPx", "minIsletSize", "isletCount", "volumeIe", "purity"]}).slice(1);

    const range = XLSX.utils.decode_range(sheet['!ref']!);
    const columnCount = range.e.c - range.s.c + 1;

    if (columnCount < 6) {
        throw new Error("Missing columns");
    }

    const relevantRows = data.filter((row: Dictionary<string>) => Object.values(row).every(v => Boolean(v)));

    return {
        name: file.name,
        imageOrder: relevantRows.map((row: Dictionary<string>) => row["name"]),
        parameters: fromPairs(relevantRows.map((row: Dictionary<string>) => {
            const isletCount = parseInt(row["isletCount"], 10);

            if (isNaN(isletCount) || isletCount < 0) {
                throw new Error(`Islet count is not valid for image ${row["name"]}`);
            }

            const minIsletSize = parseInt(row["minIsletSize"], 10);

            if (isNaN(minIsletSize) || minIsletSize < 0) {
                throw new Error(`Minimum islet size is not valid for image ${row["name"]}`);
            }

            const purity = parseFloat(row["purity"]);

            if (isNaN(purity) || purity < 0 || purity > 1) {
                throw new Error(`Purity is not valid for image ${row["name"]}`);
            }

            const umPerPx = parseFloat(row["umPerPx"]);

            if (isNaN(umPerPx) || umPerPx < 0) {
                throw new Error(`Pixel size is not valid for image ${row["name"]}`);
            }

            const volumeIe = parseFloat(row["volumeIe"]);

            if (isNaN(volumeIe) || volumeIe < 0) {
                throw new Error(`Volume is not valid for image ${row["name"]}`);
            }

            return [removeExtension(row["name"]), {
                isletCount,
                minIsletSize,
                purity,
                umPerPx,
                volumeIe,
            }]
        }))
    };
};

const loadImage = async (file?: File) => {
    if (!file) {
        return undefined;
    }

    return await readImage(file);
};

const SimpleComparisonImagesSection = (props: Props) => {
    const [zoomed, setZoomed] = useState(false);
    const [showHint, setShowHint] = useState(true);
    const [selectedImage, setSelectedImage] = useState<File|undefined>(undefined);

    const imageData = useAsync(loadImage, [selectedImage]);

    const onDrop = async (files: File[]) => {
        const images = files.filter(f => UploaderImagesSection.defaultTypes.includes(f.type));
        const template = files.find(f => f.type === spreadsheetType);

        if (images) {
            props.onImagesChanged([...props.images, ...images]);
        }

        if (template) {
            try {
                const parsedTemplate = await loadTemplate(template);
                props.onTemplateChanged(parsedTemplate);
            } catch (e) {
                window.alert(e.message)
            }
        }
    };

    return <DropZone style={{}} onDrop={onDrop} accept={[...UploaderImagesSection.defaultTypes, spreadsheetType]} disableClick={true}>
        <ModalWindow isOpen={zoomed && imageData.result !== undefined} onRequestClose={() => setZoomed(false)}>{requestClose =>
            <ZoomedImage image={imageData.result ? makePngDataUrl(imageData.result.imageBase64) : ""} maxScale={5}/>
        }</ModalWindow>
        <div className="row">

            <div className="col-6">
                <h2>Upload images and parameters</h2>

                <ul className="uploaded-images-list custom-scrollbar-style">
                    {(props.images.length > 0 || props.template !== undefined || !showHint) && <>
                        <h4>Uploaded parameter form</h4>
                        <li>{props.template === undefined ? "None" : <>
                            <span>{props.template.name}</span>
                            <span className="status-text"/>
                            <ClickableLink title="Remove image" onClick={(e: any) => {
                                e.preventDefault();
                                props.onTemplateChanged(undefined);
                            }}>
                                <Icon name="close"/>
                            </ClickableLink>
                        </>}</li>
                        <h4>{props.images.length} uploaded images</h4>
                        {
                            props.images.map(image =>
                                <ImageEntry
                                    key={image.name}
                                    selected={selectedImage === image}
                                    onSelect={() => {
                                        setSelectedImage(image)
                                    }}
                                    onRemove={() => {
                                        props.onImagesChanged(props.images.filter(i => i !== image))
                                    }}
                                    fileName={image.name}
                                />
                            )
                        }
                    </>
                    }
                    {(showHint && props.images.length === 0 && props.template === undefined) &&
                    <div className="hint-box upload">
                        <h4>Upload hint</h4>
                        <p>
                            1. Download an empty, pre-formatted form <a href="/simple-comparison-form.xlsx">here</a>
                        </p>
                        <p>
                            2. Fill the parameters of all your images into the form in the following order: Image name
                            (with or without extension), Pixel size [μm/px], Minimum islet size [μm], Number of islets, 
                            Total islet volume [IE], Purity (number between 0 and 1, i.e. 50% is 0.5).
                        </p>
                        <p>
                            3. Acceptable formats include png, jpg, tiff, bmp and xlsx.
                        </p>
                        <p><br/></p>
                        <p>
                            IsletNet will compare your uploaded results to its own image analysis in terms of islet
                            count, volume and purity. Exported graphs (svg) and corresponding table (xlsx) will include
                            volumes calculated by IsletNet using all listed methods. Report will contain segmented
                            images and graph for selected method (pdf).
                        </p>
                        <ClickableLink className="close-btn" onClick={() => setShowHint(false)}/>
                    </div>
                    }
                </ul>

                <div className="uploader-images-buttons">
                    <DropZone style={{}} onDrop={onDrop} accept={UploaderImagesSection.defaultTypes}>
                        <button>Add images</button>
                    </DropZone>
                    <DropZone style={{}} onDrop={onDrop} accept={spreadsheetType}>
                        <button>Add parameter form</button>
                    </DropZone>
                </div>

                <div className="max-image-size-note">
                    <p>Maximum image size: {MAX_UPLOAD_RESOLUTION}&times;{MAX_UPLOAD_RESOLUTION}px.</p>
                </div>
            </div>

            <div className="col-6">
                {
                    <img src={imageData.result !== undefined ? makePngDataUrl(imageData.result.imageBase64) : imagePlaceholder} className="image-placeholder" alt="Your images will be shown here" onClick={() => setZoomed(() => {
                        if (selectedImage) {
                            return true
                        }

                        return false
                    })}/>
                }
            </div>

        </div>
    </DropZone>
};

export default SimpleComparisonImagesSection