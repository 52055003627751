import React from 'react';
import PropTypes, {string} from 'prop-types';
import Icon from "../Icon";

const ImageDetailsIcon = (props) => {
    const iconType = (props.included ? "check-circle" : "close");
    return <Icon style={{color: props.color}} name={iconType}/>
};

ImageDetailsIcon.propTypes = {
    included: PropTypes.bool.isRequired,
    color: string
};

export default ImageDetailsIcon;
