import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {RouteComponentProps, withRouter} from "react-router-dom";

import PromptWrapper from "../components/PromptWrapper";
import {UploaderContentState} from "../components/Uploader";

import {SubmittedBatch} from "../components/upload/IsolationUploader";
import IsolationUploader from "../containers/upload/IsolationUploader";
import {IsolationBatchMetadata, IsolationImageData, isolationRequestDone} from "../redux/actions";
import {addBatch, newSession} from "../redux/actions";

interface IsolationProps extends RouteComponentProps<any> {
    onBatchAdded: (batchMetadata: IsolationBatchMetadata, images: Array<IsolationImageData>) => void;
    onStartup: () => void;
    onSuccess: (batchMetadata: IsolationBatchMetadata, images: Array<IsolationImageData>) => void;
}

interface IsolationState {
    uploading: boolean;
    uploaderState: UploaderContentState;
}

class Isolation extends React.Component<IsolationProps, IsolationState> {
    constructor(props: IsolationProps) {
        super(props);

        this.state = {
            uploaderState: {
                modified: false
            },
            uploading: false
        };
    }

    public componentDidMount() {
        this.props.onStartup();
    }

    private onSubmit = (batch: SubmittedBatch) => {
        this.props.onBatchAdded(batch.batchMetadata, batch.imagesMetadata);
        this.setState({
            uploading: true
        });
    };

    private onUploaderStateChanged = (uploaderState: UploaderContentState) => this.setState({uploaderState});

    public render() {
        if (this.state.uploading) {
            return <Redirect to="/isolation/results"/>;
        }

        return <div className="card-wrapper">
            <PromptWrapper
                message="If you leave this page, the data in this session will be lost. Continue?"
                when={this.state.uploaderState.modified}
            />
            <IsolationUploader onSubmit={this.onSubmit} disabledFields={[]} erasedFields={[]}
                               onStateChanged={this.onUploaderStateChanged} />
        </div>;
    }
}

export default connect(
    () => ({}),
    (dispatch) => ({
        onBatchAdded: (batchData: IsolationBatchMetadata, items: Array<IsolationImageData>) => {
            dispatch(addBatch({
                items,
                metadata: batchData
            }));
        },
        onRequestIdReceived: (batchId: string, imageName: string, requestId: string) => {
            dispatch(isolationRequestDone({batchId, imageName, requestId}))
        },
        onStartup: () => {
            dispatch(newSession());
        }
    })
)(withRouter(Isolation));
