import React from "react";
import ContextMenuItem from "./ContextMenuItem";

interface CustomPointMenuProps {
  onBgClick: { (arg0: boolean): void; (arg0: boolean): void; },
  contextMenuData: CustomMenuData,
  onDelete: (arg0?: { lineId: any; }) => void,
}

export type CustomMenuData = {
  x: number;
  y: number;
  lineId?: number;
}

const CustomPointMenu = (props: CustomPointMenuProps) => {
  return <div
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1000
    }}>
    <div style={{
      position: "fixed",
      left: "50%",
      top: "50%",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
      transform: "translate(-50%, -50%)",
      opacity: 0.2
    }}
         onClick={() => {
           props.onBgClick(true);
         }}>
    </div>
    <div
      style={{
        position: "absolute",
        left: props.contextMenuData.x + 2,
        top: props.contextMenuData.y + 25,
        minWidth: "200px",
        backgroundColor: "white",
        borderRadius: "0.5rem",
        overflow: "hidden",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
      }}
    >
      {props.contextMenuData.lineId !== undefined && <ContextMenuItem onClickMenu={() => {
        props.onDelete({
          lineId: props.contextMenuData.lineId
        });
        props.onBgClick(true);
      }}>Delete line</ContextMenuItem>}
      <ContextMenuItem onClickMenu={() => {
        props.onDelete();
        props.onBgClick(true);
      }}>Delete all lines</ContextMenuItem>
    </div>
  </div>;
};

export default CustomPointMenu;
