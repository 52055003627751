import {connect} from "react-redux";

import Pix2PixImageDetails, {Pix2PixImageDetailsProps} from "../../components/results/Pix2PixImageDetails";
import {setPix2PixImageReported, StoreShape} from "../../redux/actions";

export default connect(
    (state: StoreShape, props: Pix2PixImageDetailsProps) => ({
        isReported: state.pix2pixBatches[props.batchMetadata.batchId].items[props.imageIndex].reported,
        setReportedState: state.pix2pixBatches[props.batchMetadata.batchId].items[props.imageIndex].setReportedState,
        umPerPx: state.pix2pixBatches[props.batchMetadata.batchId].items[props.imageIndex].umPerPx || props.batchMetadata.umPerPx
    }),
    (dispatch) => ({
        onSetReported: (batchId: string, imageIndex: number, isReported: boolean) => dispatch(setPix2PixImageReported({
            batchId,
            imageIndex,
            isReported
        }))
    })
)(Pix2PixImageDetails);