import React, {useState} from 'react';
import ResultsNavigation from "./ResultsNavigation";
import {SimpleComparisonItemResult} from "./types";
import SimpleComparisonBatchResults from "./SimpleComparisonBatchResults";
import {defaultTabState, TabState} from "./ResultsContent";
import {useSelector} from "react-redux";
import {AsyncRequestState, SimpleComparisonImageState, StoreShape} from "../../redux/actions";
import TruncatedText from "../TruncatedText";
import SimpleComparisonImageDetails from "./SimpleComparisonImageDetails";
import AsyncButton from "../AsyncButton";

interface Props {
    items: SimpleComparisonItemResult[]
    failedRequests: SimpleComparisonImageState[]
    onZip: () => void
    zipDownloadState: AsyncRequestState
    onCompleteZip: () => void
    completeZipDownloadState: AsyncRequestState
    onSetReported: (imageIndex: number, value: boolean) => void
}

const SimpleComparisonResults = (props: Props) => {
    const [selectedItem, setSelectedItem] = useState(0);
    const [tabState, setTabState] = useState<TabState>(defaultTabState);

    const imageLoadingStatus = useSelector((state: StoreShape) => state.imageDownloadStatus);
    const displayedImages = props.items.flatMap(item => [
        item.imageId,
        item.result.contoursId,
        item.result.ellipsesId,
        item.result.contoursLabeledId,
        item.result.imageContoursExoId,
        item.result.maskCombinedId,
        item.result.maskCombinedId,
        item.result.maskIsletsId,
        item.result.maskExoColorId,
        item.result.histogramId,
    ]);
    const imagesLoading =  displayedImages.some(imageId => [AsyncRequestState.NONE, AsyncRequestState.PENDING].includes(imageLoadingStatus[imageId]));

    const onSelect = (selectedItem: number) => {
        if (selectedItem >= 0 && selectedItem < props.items.length) {
            setSelectedItem(selectedItem);
        }
    };

    return <>
        <div className="results-content-collapsible showing-results">
            <div className="results-header row full">
                <div className="Collapsible">
                    <div className="Collapsible__contentOuter" style={{height: 'auto', transition: "none 0s ease 0s", overflow: "hidden"}}>
                        <div className="Collapsible__contentInner">
                            <div className="col-3">
                                <ResultsNavigation<SimpleComparisonItemResult> onSelect={onSelect}
                                                                      batchId={""}
                                                                      items={props.items} selectedItem={selectedItem}
                                                                      itemRenderer={(item, i) => <SimpleComparisonImageDetails
                                                                          method={item.methodUser}
                                                                          result={item.result}
                                                                          imageIndex={i}
                                                                          isReported={item.reported}
                                                                          onSetReported={props.onSetReported}
                                                                          setReportedState={item.setReportedState}
                                                                          umPerPx={item.umPerPx}
                                                                          minIsletSize={item.minIsletSize}
                                                                      />}
                                                                      failedItemNames={props.failedRequests.map(i => i.name)}
                                                                      itemHeaderRenderer={(item, i) => <TruncatedText text={item.name} key={i}/>} />
                            </div>
                            <div className="col-9">
                                <SimpleComparisonBatchResults items={props.items} selectedItem={selectedItem} tabState={tabState}
                                                              onSelect={onSelect} onTabStateChanged={setTabState}
                                                              imagesLoading={imagesLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Collapsible add-item">
                <span className="Collapsible__trigger is-closed" style={{visibility: "hidden"}}>Add another batch</span>
                <div className="Collapsible__contentOuter"
                     style={{height: "0px", transition: "height 100ms linear 0s", overflow: "hidden"}}>
                    <div className="Collapsible__contentInner">
                        <div className="summary-btn">
                            <AsyncButton onClick={props.onZip} asyncActionState={props.zipDownloadState}>Download graphs and table</AsyncButton>{" "}
                            <AsyncButton onClick={props.onCompleteZip} asyncActionState={props.completeZipDownloadState}>Download graphs, table and PDF</AsyncButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default SimpleComparisonResults;