import React from 'react';

import "babel-polyfill";

import {Provider} from 'react-redux';
import {BrowserRouter, Link, NavLink, Route, Switch} from 'react-router-dom';
import {Store} from "redux";

import {APP_VERSION, MODEL_VERSION} from "./env";

import LoadingScreen from "./components/LoadingScreen";
import ErrorWindow from "./containers/ErrorWindow";
import About from './pages/About';
import Analysis from './pages/Analysis';
import AnalysisResults from "./pages/AnalysisResults";
import Contact from './pages/Contact';
import Isolation from './pages/Isolation';
import IsolationResults from './pages/IsolationResults';
import Pix2Pix from './pages/Pix2Pix';
import Pix2PixResults from "./pages/Pix2PixResults";
import SimpleComparison from "./pages/SimpleComparison";
import SimpleComparisonResults from "./pages/SimpleComparisonResults";

import logo from './images/ikem_logo.png';
import getStore from './redux/store';

import 'font-awesome/css/font-awesome.css';
import 'normalize.css';
import './App.scss';
import Icon from "./components/Icon";
import {StoreShape} from "./redux/actions";

export interface AppState {
    conflict: boolean;
    error: boolean;
    loading: boolean;
    store: Store<StoreShape>|null;
}

class App extends React.Component<{}, AppState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            conflict: false,
            error: false,
            loading: true,
            store: null
        };

        getStore()
            .then(store => this.setState({loading: false, store}))
            .catch(() => this.setState({loading: false, conflict: true}));
    }

    public render() {
        if (this.state.loading) {
            return <LoadingScreen />
        }

        if (this.state.conflict) {
            return <AppErrorMessage message="Only one instance of the IsletNet client can be running in a browser session at a time. If you are sure no other instance is running, try refreshing the page."/>;
        }

        if (this.state.error) {
            return <AppErrorMessage />;
        }

        return (
            <Provider store={this.state.store!}>
                <BrowserRouter>
                    <div className="App">
                        <Navbar/>
                        <Switch>
                            <Route path="/" component={About} exact={true}/>
                            <Route path="/isolation/results" component={IsolationResults}/>
                            <Route path="/isolation" component={Isolation}/>
                            <Route path="/contact" component={Contact}/>
                            <Route path="/analysis/results" component={AnalysisResults}/>
                            <Route path="/analysis" component={Analysis}/>
                            <Route path="/pix2pix/results" component={Pix2PixResults}/>
                            <Route path="/pix2pix" component={Pix2Pix}/>
                            <Route path="/simple-comparison/results" component={SimpleComparisonResults}/>
                            <Route path="/simple-comparison" component={SimpleComparison}/>
                        </Switch>
                        <ErrorWindow />
                    </div>
                </BrowserRouter>
            </Provider>
        );
    }

    public componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({error: true});
    }
}

const Navbar: React.SFC<{}> = () => 
    <div className="navbar">
        <Link className="logo-wrap" to="/">
            <img src={logo} className="logo" alt="logo"/>
            <span>Current version: {APP_VERSION}<br/>
            Last network training: {MODEL_VERSION}</span>
        </Link>
        <nav>
            <NavLink activeClassName="active" to="/" exact={true}>Home</NavLink>
            <NavLink activeClassName="active" to="/isolation">Clinical Islet Isolation</NavLink>
            <div className="validation-links-wrap">
                <span><em>Validation Tools</em></span>
                <NavLink activeClassName="active" to="/simple-comparison">Simple Comparison</NavLink>
                <NavLink activeClassName="active" to="/pix2pix">Pixel-to-Pixel Comparison</NavLink>
                <NavLink activeClassName="active" to="/analysis">My Segmentations</NavLink>
            </div>
            {/*<NavLink activeClassName="active" to="/contact">Contact</NavLink>*/}
        </nav>
    </div>;

const AppErrorMessage: React.SFC<{message?: string}> = ({message = "An unexpected error occurred. Please try refreshing the page in your browser."}) =>
    <div className="app-error-msg">
        <span><Icon name="warning"/>{message}</span>
    </div>;

export default App;
