import {Action} from "redux-act";
import {delay} from "redux-saga";
import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";

import {actions as formActions} from "react-redux-form";

import {
    addAnalysisBatch,
    addBatch as addIsolationBatch,
    addPix2PixBatch,
    addSimpleComparisonBatch,
    AnalysisBatchMetadata,
    AnalysisMaskData,
    analysisRequestDone,
    analysisRequestExpired,
    analysisRequestFailed,
    analysisResultFailed,
    analysisResultReceived,
    analysisResultRequested,
    AsyncRequestState,
    editAnalysisBatch,
    editBatch as editIsolationBatch,
    editPix2PixBatch,
    imageDownloadFailed,
    imageDownloadFinished,
    imageDownloadRequested,
    imageDownloadStarted,
    imageExpired,
    ImageIdPayload,
    ImageRequestIdPayload,
    imageUploaded,
    imageUploadFailed,
    imageUploadRequested,
    ImageUploadRequestPayload,
    imageUploadStarted,
    IncludeImagePayload,
    IsolationBatchMetadata,
    IsolationBatchState,
    IsolationImageData,
    isolationRequestDone,
    isolationRequestExpired,
    isolationRequestFailed,
    isolationResultFailed,
    isolationResultReceived,
    isolationResultRequested,
    newAnalysisSession,
    newPix2PixSession,
    newSession,
    Pix2PixBatchMetadata,
    Pix2PixBatchState,
    Pix2PixMaskData,
    pix2pixRequestDone,
    pix2PixRequestExpired,
    pix2pixRequestFailed,
    pix2pixResultFailed,
    pix2pixResultReceived,
    pix2pixResultRequested,
    ReportImagePayload,
    setIncluded,
    setIsolationImageReported,
    setIsolationImageReportedDone,
    setIsolationImageReportedFailed,
    setPix2PixImageReported,
    setPix2PixImageReportedDone,
    setPix2PixImageReportedFailed, setSimpleComparisonImageReported,
    setSimpleComparisonImageReportedDone,
    setSimpleComparisonImageReportedFailed,
    SimpleComparisonBatchState,
    simpleComparisonRequestDone,
    simpleComparisonRequestExpired,
    simpleComparisonRequestFailed,
    simpleComparisonResultFailed,
    simpleComparisonResultReceived,
    simpleComparisonResultRequested,
    StoreShape,
} from "./actions";

import {
    AnalysisResult,
    ApiResponse, ComparisonRequest,
    Configuration,
    DefaultApi,
    FetchImageResponse, ImagesUploadResponse,
    IsolationResult,
    Pix2PixResult,
    SubmissionResponse
} from "../api";
import {API_URL} from "../env";
import {fromPairs, partition, Dictionary} from "lodash";
import {contentHash, readImage, ReadImageResult} from "../utils/images";

const api = new DefaultApi(new Configuration({basePath: API_URL}));

function* submitIsolationBatches() {
    const batches: StoreShape["isolationBatches"] = yield select((store: StoreShape) => store.isolationBatches);

    for (const batch of Object.values(batches)) {
        for (const item of batch.items) {
            if (item.isolationRequestId === undefined) {
                yield fork(submitIsolationRequest, item, batch.metadata);
            }
        }
    }
}

function* submitIsolationRequest(item: IsolationImageData, metadata: IsolationBatchMetadata) {
    try {
        const response: SubmissionResponse = yield call([api, api.submitIsolation], {
            body: {
                imageId: item.imageId,
                minIsletSize: item.minIsletSize || metadata.minIsletSize,
                umPerPx: item.umPerPx || metadata.umPerPx,
                userCutLines: item.userCutLines
            }
        });

        yield put(isolationRequestDone({
            batchId: metadata.batchId,
            imageName: item.name,
            requestId: response.requestId
        }));
    } catch (e) {
        yield put(isolationRequestFailed({
            batchId: metadata.batchId,
            imageName: item.name
        }));
    }
}

function* fetchIsolationResultWatcher(action: Action<ImageRequestIdPayload>) {
    yield* fetchIsolationResult(action.payload.requestId);
}

function* fetchIsolationResult(requestId: string) {
    try {
        yield put(isolationResultRequested({requestId}));

        while (true) {
            const fetchResult: ApiResponse<IsolationResult> = yield call([api, api.isolationResultRaw], {requestId});

            if (fetchResult.raw.status === 200) {
                const result = yield call([fetchResult, fetchResult.value]);
                yield put(isolationResultReceived({requestId, result}));
                break;
            }

            if (fetchResult.raw.status === 404) {
                yield put(isolationRequestExpired({requestId}));
                break;
            }

            if (fetchResult.raw.status === 202) {
                yield delay(1000);
                continue;
            }

            throw new Error("Unexpected return code");
        }
    } catch (e) {
        yield put(isolationResultFailed({requestId}));
    }
}

function* submitAnalysisBatches() {
    const batches: StoreShape["analysisBatches"] = yield select((store: StoreShape) => store.analysisBatches);

    for (const batch of Object.values(batches)) {
        for (const item of batch.items) {
            if (item.requestId === undefined) {
                yield fork(submitAnalysisRequest, item, batch.metadata);
            }
        }
    }
}

function* submitAnalysisRequest(item: AnalysisMaskData, metadata: AnalysisBatchMetadata) {
    try {
        const response: SubmissionResponse = yield call([api, api.submitAnalysis], {
            body: {
                isletCountUser: item.isletCountUser,
                minIsletSize: metadata.minIsletSize,
                umPerPx: metadata.umPerPx,
                userMaskId: item.maskId,
                volumeTableUser: item.volumeTableUser,
                volumeUser: item.volumeUser
            }
        });

        yield put(analysisRequestDone({
            batchId: metadata.batchId,
            imageName: item.name,
            requestId: response.requestId
        }));
    } catch (e) {
        yield put(analysisRequestFailed({
            batchId: metadata.batchId,
            imageName: item.name
        }));
    }
}

function* fetchAnalysisResultWatcher(action: Action<ImageRequestIdPayload>) {
    yield* fetchAnalysisResult(action.payload.requestId);
}

function* fetchAnalysisResult(requestId: string) {
    try {
        yield put(analysisResultRequested({requestId}));

        while (true) {
            const fetchResult: ApiResponse<AnalysisResult> = yield call([api, api.analysisResultRaw], {requestId});

            if (fetchResult.raw.status === 200) {
                const result = yield call([fetchResult, fetchResult.value]);
                yield put(analysisResultReceived({requestId, result}));
                break;
            }

            if (fetchResult.raw.status === 404) {
                yield put(analysisRequestExpired({requestId}));
                break;
            }

            if (fetchResult.raw.status === 202) {
                yield delay(1000);
                continue;
            }

            throw new Error("Unexpected return code");
        }
    } catch (e) {
        yield put(analysisResultFailed({requestId}));
    }
}

function* submitPix2pixBatches() {
    const batches: StoreShape["pix2pixBatches"] = yield select((store: StoreShape) => store.pix2pixBatches);

    for (const batch of Object.values(batches)) {
        for (const item of batch.items) {
            if (item.requestId === undefined) {
                yield fork(submitPix2pixRequest, item, batch.metadata);
            }
        }
    }
}

function* submitPix2pixRequest(item: Pix2PixMaskData, metadata: Pix2PixBatchMetadata) {
    try {
        const response: SubmissionResponse = yield call([api, api.submitPix2pix], {
            body: {
                imageId: item.imageId,
                minIsletSize: metadata.minIsletSize,
                umPerPx: item.umPerPx || metadata.umPerPx,
                userMaskId: item.userMaskId
            }
        });

        yield put(pix2pixRequestDone({
            batchId: metadata.batchId,
            imageName: item.userMaskName,
            requestId: response.requestId
        }));
    } catch (e) {
        yield put(pix2pixRequestFailed({
            batchId: metadata.batchId,
            imageName: item.userMaskName
        }));
    }
}

function* fetchPix2pixResultWatcher(action: Action<ImageRequestIdPayload>) {
    yield* fetchPix2pixResult(action.payload.requestId);
}

function* fetchPix2pixResult(requestId: string) {
    try {
        yield put(pix2pixResultRequested({requestId}));

        while (true) {
            const fetchResult: ApiResponse<Pix2PixResult> = yield call([api, api.pix2pixResultRaw], {requestId});

            if (fetchResult.raw.status === 200) {
                const result = yield call([fetchResult, fetchResult.value]);
                yield put(pix2pixResultReceived({requestId, result}));
                break;
            }

            if (fetchResult.raw.status === 404) {
                yield put(pix2PixRequestExpired({requestId}));
                break;
            }

            if (fetchResult.raw.status === 202) {
                yield delay(1000);
                continue;
            }

            throw new Error("Unexpected return code");
        }
    } catch (e) {
        yield put(pix2pixResultFailed({requestId}));
    }
}

function* submitSimpleComparisonBatches() {
    const batches: StoreShape["simpleComparisonBatches"] = yield select((store: StoreShape) => store.simpleComparisonBatches);
    let i = 0;

    for (const batch of batches.batches) {
        for (const item of batch.items) {
            if (item.requestId === undefined) {
                yield fork(submitSimpleComparisonRequest, item, i.toString());
            }
        }

        i += 1;
    }
}

function* submitSimpleComparisonRequest(item: ComparisonRequest, batchId: string) {
    try {
        const response: SubmissionResponse = yield call([api, api.submitComparison], {
            body: {
                umPerPx: item.umPerPx,
                minIsletSize: item.minIsletSize,
                methodUser: item.methodUser,
                imageId: item.imageId,
                volumeUserIe: item.volumeUserIe,
                purityUser: item.purityUser,
                isletCountUser: item.isletCountUser,
            }
        });

        yield put(simpleComparisonRequestDone({
            batchId,
            imageName: item.imageId,
            requestId: response.requestId
        }));
    } catch (e) {
        yield put(simpleComparisonRequestFailed({
            batchId,
            imageName: item.imageId
        }));
    }
}

function* fetchSimpleComparisonResultWatcher(action: Action<ImageRequestIdPayload>) {
    yield* fetchSimpleComparisonResult(action.payload.requestId);
}

function* fetchSimpleComparisonResult(requestId: string) {
    try {
        yield put(simpleComparisonResultRequested({requestId}));

        while (true) {
            const fetchResult: ApiResponse<Pix2PixResult> = yield call([api, api.comparisonResultRaw], {requestId});

            if (fetchResult.raw.status === 200) {
                const result = yield call([fetchResult, fetchResult.value]);
                yield put(simpleComparisonResultReceived({requestId, result}));
                break;
            }

            if (fetchResult.raw.status === 404) {
                yield put(simpleComparisonRequestExpired({requestId}));
                break;
            }

            if (fetchResult.raw.status === 202) {
                yield delay(1000);
                continue;
            }

            throw new Error("Unexpected return code");
        }
    } catch (e) {
        yield put(simpleComparisonResultFailed({requestId}));
    }
}

function* init() {
    const isolationBatches: StoreShape["isolationBatches"] = yield select((state: StoreShape) => state.isolationBatches);

    for (const batch of Object.values(isolationBatches)) {
        for (const item of batch.items) {
            if (item.isolationRequestId === undefined) {
                yield fork(submitIsolationRequest, item, batch.metadata);
            } else {
                yield fork(fetchIsolationResult, item.isolationRequestId);
            }
        }
    }

    const analysisBatches: StoreShape["analysisBatches"] = yield select((state: StoreShape) => state.analysisBatches);

    for (const batch of Object.values(analysisBatches)) {
        for (const item of batch.items) {
            if (item.requestId === undefined) {
                yield fork(submitAnalysisRequest, item, batch.metadata);
            } else {
                yield fork(fetchAnalysisResult, item.requestId);
            }
        }
    }

    const pix2pixBatches: StoreShape["pix2pixBatches"] = yield select((state: StoreShape) => state.pix2pixBatches);

    for (const batch of Object.values(pix2pixBatches)) {
        for (const item of batch.items) {
            if (item.requestId === undefined) {
                yield fork(submitPix2pixRequest, item, batch.metadata);
            } else {
                yield fork(fetchPix2pixResult, item.requestId);
            }
        }
    }

    const simpleComparisonBatches: StoreShape["simpleComparisonBatches"] = yield select((state: StoreShape) => state.simpleComparisonBatches);
    let id = 0;

    for (const batch of simpleComparisonBatches.batches) {
        for (const item of batch.items) {
            if (item.requestId === undefined) {
                yield fork(submitSimpleComparisonRequest, item, id.toString());
            } else {
                yield fork(fetchSimpleComparisonResult, item.requestId);
            }
        }

        id += 1;
    }
}

function* isolationSetIncludedWatcher(action: Action<IncludeImagePayload>) {
    if (!action.payload.included) {
        yield put(setIsolationImageReported({
            batchId: action.payload.batchId,
            imageIndex: action.payload.imageIndex,
            isReported: true
        }));
    }
}

function* setIsolationImageReportedWatcher(action: Action<ReportImagePayload>) {
    const batch: IsolationBatchState = yield select((store: StoreShape) =>
        store.isolationBatches[action.payload.batchId]);
    const image = batch.items[action.payload.imageIndex];

    try {
        yield call([api, api.reportImages], {
            body: {
                imageId: image.imageId,
                reportImage: action.payload.isReported,
                umPerPx: image.umPerPx || batch.metadata.umPerPx
            }
        });

        yield put(setIsolationImageReportedDone(action.payload))
    } catch (e) {
        yield put(setIsolationImageReportedFailed(action.payload))
    }
}

function* setPix2PixImageReportedWatcher(action: Action<ReportImagePayload>) {
    const batch: Pix2PixBatchState = yield select((store: StoreShape) =>
        store.pix2pixBatches[action.payload.batchId]);
    const image = batch.items[action.payload.imageIndex];

    try {
        yield call([api, api.reportImages], {
            body: {
                imageId: image.imageId,
                reportImage: action.payload.isReported,
                umPerPx: image.umPerPx || batch.metadata.umPerPx,
                userMaskId: image.userMaskId
            }
        });

        yield put(setPix2PixImageReportedDone(action.payload))
    } catch (e) {
        yield put(setPix2PixImageReportedFailed(action.payload))
    }
}

function* setSimpleComparisonImageReportedWatcher(action: Action<ReportImagePayload>) {
    const batch: SimpleComparisonBatchState = yield select((store: StoreShape) =>
        store.simpleComparisonBatches.batches[parseInt(action.payload.batchId, 10)]);

    const image = batch.items[action.payload.imageIndex];

    try {
        yield call([api, api.reportImages], {
            body: {
                imageId: image.imageId,
                reportImage: action.payload.isReported,
                umPerPx: image.umPerPx,
            }
        });

        yield put(setSimpleComparisonImageReportedDone(action.payload))
    } catch (e) {
        yield put(setSimpleComparisonImageReportedFailed(action.payload))
    }
}

function* imageDownloadWatcher(action: Action<ImageIdPayload>) {
    yield fork(downloadImage, action.payload.imageId);
}

function* downloadImage(imageId: string) {
    const cachedImage = yield select((store: StoreShape) => store.imageIdCache[imageId]);

    if (cachedImage !== undefined) {
        return;
    }

    const downloadStatus: AsyncRequestState = yield select((store: StoreShape) => store.imageDownloadStatus[imageId]);

    if (downloadStatus !== AsyncRequestState.NONE) {
        return;
    }

    try {
        yield put(imageDownloadStarted({imageId}));
        const response: FetchImageResponse = yield call([api, api.getImage], {imageId});
        yield put(imageDownloadFinished({imageBase64: response.imageBase64, imageId}));
    } catch (response) {
        if (response.status === 404) {
            yield put(imageExpired({imageId}));
        } else {
            yield put(imageDownloadFailed({imageId}));
        }
    }
}

function* clearIsolationSessionCode() {
    yield put(formActions.change("isolationUploaderForm.sessionCode", ""));
}

function* clearAnalysisSessionCode() {
    yield put(formActions.change("analysisUploaderForm.sessionCode", ""));
}

function* clearPix2PixSessionCode() {
    yield put(formActions.change("pix2pixUploaderForm.sessionCode", ""));
}

const checkImageExists = async (id: string) => {
    try {
        await api.imageInfo({
            imageId: id
        });
        return true;
    } catch (e) {
        return false;
    }
};

function* uploadImages(action: Action<ImageUploadRequestPayload>) {
    const uploadStatus: StoreShape["imageUploadStatus"] = yield select((store: StoreShape) => store.imageUploadStatus);

    // Ignore images that are already being uploaded
    const [imagesNotBeingUploaded, imagesBeingUploaded] = partition(action.payload.files, file => { // eslint-disable-line
        return uploadStatus[file.name] === undefined || uploadStatus[file.name].error;
    });

    // Signal the start of upload for selected images
    for (const file of imagesNotBeingUploaded) {
        yield put(imageUploadStarted({file}));
    }

    // Read image content
    const readImageResults: ReadImageResult[] = yield all(imagesNotBeingUploaded.map(i => call(readImage, i)));

    // Find images whose ids might be in the content cache
    const imageContentCache: StoreShape["imageContentCache"] = yield select((store: StoreShape) => store.imageContentCache);

    const [possiblyCachedImages, nonCachedImages] = partition(
        readImageResults,
        item => Object.keys(imageContentCache).includes(contentHash(item.imageBase64))
    );

    // Filter out images that are present in the cache despite not being accessible anymore
    const cacheStatusInfo: Dictionary<boolean> = yield all(fromPairs(possiblyCachedImages.map((i: ReadImageResult) => [
        i.file.name,
        call(checkImageExists, imageContentCache[contentHash(i.imageBase64)])
    ])));

    const cachedImages = possiblyCachedImages.filter((item, i) => cacheStatusInfo[i]);
    const expiredImages = possiblyCachedImages.filter((item, i) => !cacheStatusInfo[i]);

    // Mark images found in cache as uploaded
    for (const item of cachedImages) {
        yield put(imageUploaded({
            file: item.file,
            name: item.file.name,
            imageId: imageContentCache[contentHash(item.imageBase64)],
            imageBase64: item.imageBase64
        }))
    }

    // Images that were not found in the cache or have expired must be uploaded again
    const imagesToUpload = nonCachedImages.concat(expiredImages);

    if (imagesToUpload.length === 0) {
        return;
    }

    // Upload the images in a single API call
    try {
        const uploadResults: ImagesUploadResponse = yield call([api, api.uploadImages], {
            body: {
                imagesBase64: imagesToUpload.map(item => item.imageBase64)
            }
        });

        for (let i = 0; i < uploadResults.imageIds.length; i++) {
            yield put(imageUploaded({
                file: imagesToUpload[i].file,
                name: imagesToUpload[i].file.name,
                imageId: uploadResults.imageIds[i],
                imageBase64: imagesToUpload[i].imageBase64
            }));
        }
    } catch (e) {
        for (const image of imagesToUpload) {
            yield put(imageUploadFailed({
                file: image.file
            }))
        }
    }
}

function* rootSaga() {
    yield fork(init);

    yield all([
        takeEvery(newSession.getType(), clearIsolationSessionCode),
        takeEvery(newAnalysisSession.getType(), clearAnalysisSessionCode),
        takeEvery(newPix2PixSession.getType(), clearPix2PixSessionCode),

        takeEvery(imageDownloadRequested.getType(), imageDownloadWatcher),
        takeEvery(imageUploadRequested.getType(), uploadImages),

        takeEvery(addIsolationBatch.getType(), submitIsolationBatches),
        takeEvery(editIsolationBatch.getType(), submitIsolationBatches),
        takeEvery(isolationRequestDone.getType(), fetchIsolationResultWatcher),

        takeEvery(addAnalysisBatch.getType(), submitAnalysisBatches),
        takeEvery(editAnalysisBatch.getType(), submitAnalysisBatches),
        takeEvery(analysisRequestDone.getType(), fetchAnalysisResultWatcher),

        takeEvery(addPix2PixBatch.getType(), submitPix2pixBatches),
        takeEvery(editPix2PixBatch.getType(), submitPix2pixBatches),
        takeEvery(pix2pixRequestDone.getType(), fetchPix2pixResultWatcher),

        takeEvery(addSimpleComparisonBatch.getType(), submitSimpleComparisonBatches),
        takeEvery(simpleComparisonRequestDone.getType(), fetchSimpleComparisonResultWatcher),

        takeEvery(setIncluded.getType(), isolationSetIncludedWatcher),
        takeEvery(setIsolationImageReported.getType(), setIsolationImageReportedWatcher),
        takeEvery(setPix2PixImageReported.getType(), setPix2PixImageReportedWatcher),
        takeEvery(setSimpleComparisonImageReported.getType(), setSimpleComparisonImageReportedWatcher)
    ]);
}

export default rootSaga;
