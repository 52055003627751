import React from "react";

interface EscDialogProps {
  onClose: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
  onSubmit: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
  onQuit: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
}

const EscDialog = (props: EscDialogProps) => {
  return <>
    <div style={{
      position: "fixed",
      left: 0,
      top: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
      opacity: 0.2
    }}>
    </div>
    <div
      style={{
        zIndex: 1000,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: "200px",
        backgroundColor: "white",
        borderRadius: "0.5rem",
        overflow: "hidden",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
        padding: "1rem"
      }}
    >
      <p>You have unsubmitted changes. If you press quit all data will be lost.</p>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <div>
          <button onClick={props.onQuit}>Quit</button>
        </div>
        <div style={{display: "flex", flexDirection: "row", gap: "0.5rem"}}>
          <button onClick={props.onClose}>Cancel</button>
          <button onClick={props.onSubmit}>Submit</button>
        </div>
      </div>
    </div>
  </>;
};

export default EscDialog;
