import React from "react";

import Truncate from '@konforti/react-truncate';

interface TruncatedTextProps {
    icon?: React.ReactNode;
    text: string;
}

const TextWrapper: React.SFC<TruncatedTextProps> = (props) => <span title={props.text} style={{display: "inline-block"}}>
    <Truncate truncFrom="middle" wrapperOffset={5} ellipsis={"&hellip;"}>
        {props.text}
    </Truncate>
</span>;

const TruncatedText: React.SFC<TruncatedTextProps> = (props) =>
    props.icon === undefined
        ? <TextWrapper {...props} />
        : <div style={{display: "flex", flexFlow: "row"}}>
            <div>
                {props.icon}
            </div>
            <div>
                <TextWrapper {...props} />
            </div>
        </div>;



export default TruncatedText;