import React from "react";
import {Control, LocalForm} from "react-redux-form";

import {mean, noop, sum} from "lodash";

import AsyncButton from "./AsyncButton";
import Icon from "./Icon";
import {meanEllipseDilution, meanIsletCountDilution, meanTableDilution, SummaryTableProps} from "./results/SummaryTableUtils"

import {AsyncRequestState, IsolationBatchMetadata} from "../redux/actions";
import {MaskStatistics} from "../api/models";

interface ShareProps {
    emailSendingState: AsyncRequestState;
    onSendMail: (addresses: string[]) => void;
}

export interface IsolationSummaryProps extends SummaryTableProps<IsolationBatchMetadata, MaskStatistics> {
    onCloseSession: () => void;
    onXLSX: () => void;
    onPDF: () => void;
    onPDFComplete: () => void;
    onZIP: () => void;
    pdfCompleteDownloadState: AsyncRequestState;
    pdfDownloadState: AsyncRequestState;
    zipDownloadState: AsyncRequestState;
}

const IsolationSummary: React.SFC<IsolationSummaryProps & ShareProps> = (props) => {
    return (
        <div className="">
            <IsolationOverallSummary
                onCloseSession={props.onCloseSession}
                onXLSX={props.onXLSX}
                onPDF={props.onPDF}
                onPDFComplete={props.onPDFComplete}
                onZIP={props.onZIP}
                pdfCompleteDownloadState={props.pdfCompleteDownloadState}
                pdfDownloadState={props.pdfDownloadState}
                zipDownloadState={props.zipDownloadState}
                batches={props.batches}
                sessionCode={props.sessionCode}
            />
            <IsolationOverallShare onSendMail={props.onSendMail} emailSendingState={props.emailSendingState}/>
            <button className="close-session-btn" onClick={props.onCloseSession}>Close session</button>
        </div>
    );
};

const IsolationOverallSummary: React.SFC<IsolationSummaryProps> = (props) => {
    const numFiles = sum(Object.values(props.batches).map(b => b.items.length));
    const fileSize = (numFiles * 18).toFixed(0);
    const pdfSize = (numFiles / 3).toFixed(0);

    return (
        <div className="">
            <h2>Overall Summary ({props.sessionCode})</h2>
            {<IsolationSummaryTable sessionCode={props.sessionCode} batches={props.batches}/>}

            <div className="download-data-btns">
                <div>
                    <h2>Download Results</h2>
                </div>
                <AsyncButton asyncActionState={props.pdfDownloadState} className="link" onClick={props.onPDF} title="PDF summary">
                    <Icon name="pdf-o"/><Icon name="file-o"/>PDF Summary
                </AsyncButton>
                <AsyncButton asyncActionState={props.pdfCompleteDownloadState} className="link" onClick={props.onPDFComplete} title="Complete PDF">
                    <Icon name="pdf-o"/><Icon name="file-o"/>Complete PDF ({pdfSize} MB)
                </AsyncButton>
                <AsyncButton asyncActionState={props.zipDownloadState} onClick={props.onZIP} className="link"
                            title="Uploaded images, computed masks, islet contours, islet ellipses, histogram, image-wise XLSX, islet-wise XLSX, PDF report">
                    <Icon name="file-o"/>All files in structured ZIP ({fileSize} MB)
                </AsyncButton>
            </div>
        </div>
    );
};

const IsolationOverallShare: React.SFC<ShareProps> = (props) => {
    const onSubmit = (data: any) => {
        props.onSendMail(data.addresses.replace(/\s+/g, '').split(","));
    };

    return (
        <div className="">
            <h2>Share PDF Report</h2>

            <LocalForm className="inline-fullwidth-form" onSubmit={onSubmit}>
                <AsyncButton type="submit" asyncActionState={props.emailSendingState} onClick={noop}>Send</AsyncButton>
                <label>
                    Enter email addresses:
                    <Control.text model=".addresses" placeholder="james.smith@example.com, maria.garcia@example.edu"/>
                </label>
            </LocalForm>

            <form style={{display: "none"}} className="inline-fullwidth-form">
                <input type="submit" value="Copy"/>
                <label>
                    Results link:
                    <input type="text"
                           value="isletnet.com/islet+net&oq=islet+net&aqs=chrome..69i57j69i60l3j0l2.1870j0j1&sourceid=chrome&ie=UTF-8"/>
                </label>
            </form>
        </div>
    );
};

const IsolationSummaryTable: React.SFC<SummaryTableProps<IsolationBatchMetadata, MaskStatistics>> = (props) => {
    const totalIslets = sum(Object.values(props.batches).filter(b => b.included).map(meanIsletCountDilution)).toFixed(0);
    const totalEllipse = sum(Object.values(props.batches).filter(b => b.included).map(meanEllipseDilution)).toFixed(0);
    const totalTable = sum(Object.values(props.batches).filter(b => b.included).map(meanTableDilution)).toFixed(0);
    const totalVolume = sum(Object.values(props.batches).filter(b => b.included).map(b => b.metadata.pelletVolumeMl)).toFixed(0);

    return (
        <table className="results-table">
            <thead>
            <tr>
                <th>Summary over included batches:</th>
                <th title="TH description"><span className="primary-color bold">{totalIslets}</span> islets</th>
                <th title="TH description 2"><span className="primary-color bold">{totalTable}</span> IE (table)</th>
                <th title="TH description 3"><span className="primary-color bold">{totalEllipse}</span> IE (ellipse)</th>
                <th title="TH description 3">% (purity)</th>
                <th title="TH description 3"><span className="primary-color bold">{totalVolume}</span> mL (pellet)</th>
                <th title="TH description 3">Samples used</th>
            </tr>
            </thead>

            <tbody>
            {
                Object.entries(props.batches)
                    .sort(([a, _A], [b, _B]) => a.localeCompare(b))
                    .map(([batchId, batch], i) => <tr key={i}>
                    <td>{batchId}</td>
                    <td>{meanIsletCountDilution(batch).toFixed(0)}</td>
                    <td>{meanTableDilution(batch).toFixed(0)}</td>
                    <td>{meanEllipseDilution(batch).toFixed(0)}</td>
                    <td>
                        {
                            (100 * mean(batch.items
                                .filter(it => it.included)
                                .map(it => it.statistics.purity))).toFixed(0)
                        }
                    </td>
                    <td>{batch.metadata.pelletVolumeMl.toFixed(1)}</td>
                    <td>{sum(batch.items.map(item => item.included ? 1 : 0))}/{batch.items.length}</td>
                </tr>)
            }
            </tbody>
        </table>
    )
};

export default IsolationSummary;
