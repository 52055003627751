import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import ReactModal from "react-modal";

import App from './App';
import {unregister} from './registerServiceWorker';

ReactModal.setAppElement(document.getElementById("main")!);

ReactDOM.render(
    <App/> ,
    document.getElementById('root')
);
unregister();
