import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";

import download from "downloadjs";

import {Configuration, DefaultApi, Pix2PixSummaryRequest} from "../api";
import {API_URL} from "../env";
import {
    addPix2PixBatch,
    AsyncRequestState,
    editPix2PixBatch,
    Pix2PixBatchMetadata,
    Pix2PixMaskData,
    removePix2PixBatch,
    StoreShape
} from "../redux/actions";

import PromptWrapper from "../components/PromptWrapper";

import LoadingScreen from "../components/LoadingScreen";
import Pix2PixBatchResultList from "../components/results/Pix2PixBatchResultList";
import {SubmittedBatch} from "../components/upload/Pix2PixUploader";
import {
    pix2pixImageCount,
    pix2pixResultData,
    pix2pixStatus,
    pix2pixSubmissionStatus,
    pix2pixZipRequestData
} from "../redux/selectors";

interface Pix2PixResultsProps extends React.Props<Pix2PixResults> {
    batchCount: number;
    imageCount: number;
    zipRequestData?: Pix2PixSummaryRequest;
    onBatchAdded: (batchMetadata: Pix2PixBatchMetadata, images: Array<Pix2PixMaskData>) => void;
    onBatchEdited: (batchMetadata: Pix2PixBatchMetadata, images: Array<Pix2PixMaskData>, editedBatchId: string) => void;
    onRemoveBatch: (batchId: string) => void;
    resultsStatus: AsyncRequestState;
    submissionStatus: AsyncRequestState;
}

interface Pix2PixResultsState {
    zipDownloadState: AsyncRequestState;
    xlsxZipDownloadState: AsyncRequestState;
}

class Pix2PixResults extends React.Component<Pix2PixResultsProps, Pix2PixResultsState> {
    private api = new DefaultApi(new Configuration({basePath: API_URL}));

    constructor(props: Pix2PixResultsProps) {
        super(props);

        this.state = {
            zipDownloadState: AsyncRequestState.NONE,
            xlsxZipDownloadState: AsyncRequestState.NONE
        };
    }


    private onBatchSubmit = (batch: SubmittedBatch, editedBatchId?: string) => {
        if (editedBatchId === undefined) {
            this.props.onBatchAdded(batch.batchMetadata, batch.imagesMetadata);
        } else {
            this.props.onBatchEdited(batch.batchMetadata, batch.imagesMetadata, editedBatchId);
        }
    };

    private getFilename = (response: Response) => {
        const header = response.headers.get("content-disposition");

        if (header === null) {
            return null;
        }

        return header.match(/filename=(.+)/)![1]
    };

    public shouldComponentUpdate(nextProps: Pix2PixResultsProps, nextState: Pix2PixResultsState) {
        for (const key of Object.keys(nextProps)) {
            if (key === "zipRequestData") {
                continue;
            }

            if ((nextProps as any)[key] !== (this.props as any)[key]) {
                return true;
            }
        }

        for (const key of Object.keys(nextState)) {
            if ((nextState as any)[key] !== (this.state as any)[key]) {
                return true;
            }
        }

        return false;
    }

    private downloadZip = async () => {
        this.setState({
            zipDownloadState: AsyncRequestState.PENDING
        });

        try {
            const response = await this.api.pix2pixZipRaw({body: this.props.zipRequestData!});
            const blob = await response.value();

            download(blob, this.getFilename(response.raw) || 'results.zip');
            this.setState({
                zipDownloadState: AsyncRequestState.DONE
            });
        } catch (e) {
            this.setState({
                zipDownloadState: AsyncRequestState.FAILED
            })
        }
    };

    private downloadXlsxZip = async () => {
        this.setState({
            xlsxZipDownloadState: AsyncRequestState.PENDING
        });

        try {
            const response = await this.api.pix2pixXlsxzipRaw({body: this.props.zipRequestData!});
            const blob = await response.value();

            download(blob, this.getFilename(response.raw) || 'results.zip');
            this.setState({
                xlsxZipDownloadState: AsyncRequestState.DONE
            });
        } catch (e) {
            this.setState({
                xlsxZipDownloadState: AsyncRequestState.FAILED
            })
        }
    };

    public render() {
        if (this.props.batchCount === 0) {
            return <Redirect to="/pix2pix"/>;
        }

        const Wrapper: React.SFC<React.Props<any>> = props =>
            <div className="card-wrapper bg-gray">{props.children}</div>;

        const Alert: React.SFC<any> = () => <PromptWrapper
            when={this.props.batchCount > 0}
            message="If you leave this page, the data in this session will be lost. Continue?"
        />;

        const eta = this.props.imageCount * 0.25;

        if (this.props.submissionStatus === AsyncRequestState.PENDING) {
            return <Wrapper>
                <Alert/>
                <LoadingScreen text={<>Sending data to the server<br />(estimated remaining time is {eta} minutes)</>}/>
            </Wrapper>;
        }

        if (this.props.submissionStatus === AsyncRequestState.FAILED) {
            return <Wrapper>
                <Alert/>
                <p>There was an error while sending the data to the server. Please make sure your internet connection is
                    not down, try refreshing the page (F5) and contact us at isletnet@mild.blue if problems persist.</p>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.EXPIRED) {
            return <Wrapper>
                <Alert/>
                <p>The results for this batch are not available anymore. Try refreshing the page (F5).</p>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.PENDING || this.props.resultsStatus === AsyncRequestState.NONE) {
            return <Wrapper>
                <Alert/>
                <LoadingScreen text={<>Waiting for the server to process the data<br />(estimated remaining time is {eta} minutes)</>}/>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.FAILED) {
            return <Wrapper>
                <Alert/>
                <p>Unexpected error happened and some of the requests could not be processed by the server. Please make sure
                    your internet connection is not down, try refreshing the page (F5) and contact us at isletnet@mild.blue
                    if problems persist.</p>
            </Wrapper>;
        }

        return (
            <Wrapper>
                <Alert/>
                <ResultList onBatchSubmit={this.onBatchSubmit} onZip={this.downloadZip} onXlsxZip={this.downloadXlsxZip}
                            onRemoveBatch={this.props.onRemoveBatch} zipDownloadState={this.state.zipDownloadState}
                            xlsxZipDownloadState={this.state.xlsxZipDownloadState} />
            </Wrapper>
        );
    }
}

const ResultList = connect(
    (state: StoreShape) => ({
        batches: pix2pixResultData(state)
    })
)(Pix2PixBatchResultList);

export default connect(
    (state: StoreShape) => ({
        imageCount: pix2pixImageCount(state),
        batchCount: Object.keys(state.pix2pixBatches).length,
        resultsStatus: pix2pixStatus(state),
        submissionStatus: pix2pixSubmissionStatus(state),
        zipRequestData: pix2pixStatus(state) === AsyncRequestState.DONE ? pix2pixZipRequestData(state) : undefined
    }),
    dispatch => ({
        onBatchAdded: (metadata: Pix2PixBatchMetadata, items: Array<Pix2PixMaskData>) => {
            dispatch(addPix2PixBatch({metadata, items}));
        },
        onBatchEdited: (metadata: Pix2PixBatchMetadata, items: Array<Pix2PixMaskData>, editedBatchId: string) => {
            dispatch(editPix2PixBatch({metadata, items, editedBatchId}));
        },
        onRemoveBatch: (batchId: string) => {
            dispatch(removePix2PixBatch({batchId}));
        }
    })
)(Pix2PixResults);
