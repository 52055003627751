import React from "react";

import ClassNames from "classnames"
import {Control, LocalForm} from 'react-redux-form';

import Icon from "../Icon";

import {IsolationImageData} from "../../redux/actions";
import {toFloat} from '../../utils/parsers';
import {isPositiveFloat} from '../../utils/validators';
import TruncatedText from "../TruncatedText";
import ClickableLink from "../ClickableLink";

export interface UploadedImageMetadata {
    umPerPx: string;
}

interface UploadedImageItemProps {
    done: boolean;
    error: boolean;
    fileName: string;
    initialState: UploadedImageMetadata;
    onClick: (file: string) => void;
    onMetadataChanged: (data: Partial<IsolationImageData>) => void;
    onRemove: (file: string) => void;
    selected: boolean;
}

class IsolationUploadItem extends React.Component<UploadedImageItemProps> {
    public static defaultProps = {
        initialState: {
            umPerPx: ""
        },
        onClick: () => {
            return;
        },
        onMetadataChanged: () => {
            return;
        },
        onRemove: () => {
            return;
        }
    };

    private removeHandler = (event: any) => {
        event.stopPropagation();
        this.props.onRemove(this.props.fileName);
    };

    private clickHandler = (event: any) => {
        event.stopPropagation();
        this.props.onClick(this.props.fileName);
    };

    private metadataChangeHandler = (data: UploadedImageMetadata) => {
        this.props.onMetadataChanged({
            umPerPx: data.umPerPx.length ? parseFloat(data.umPerPx) : undefined
        });
    };

    public render() {
        // Set up status text.
        let statusText = "Uploading...";

        if (this.props.done) {
            statusText = "";
        }
        if (this.props.error) {
            statusText = "Upload error";
        }

        return (
            <li onClick={this.clickHandler} className={ClassNames({
                done: this.props.done,
                error: this.props.error,
                selected: this.props.selected
            })}>
                <TruncatedText text={this.props.fileName} />
                <span className="status-text">{statusText}</span>
                <LocalForm onChange={this.metadataChangeHandler}>
                    <Control.text defaultValue={this.props.initialState.umPerPx} model=".umPerPx"
                                  validators={{isPositiveFloat}} parser={toFloat}/>
                </LocalForm>
                <ClickableLink title="Remove image" onClick={this.removeHandler}><Icon name="close"/></ClickableLink>
            </li>
        );
    }
}

export default IsolationUploadItem;