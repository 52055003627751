import produce from "immer";
import {createAction, createReducer} from 'redux-act';
import {Assign, Diff} from "utility-types";

import {
    AnalysisResult,
    ComparisonRequest,
    ComparisonResult,
    IsolationResult, Line,
    MethodUser,
    Pix2PixResult
} from "../api";

// Make a new type with properties from TDest extended with properties from TSrc marked as optional
type PartialExtend<TDest extends {}, TSrc extends {}> = Assign<TDest, Partial<Diff<TSrc, TDest>>>;

interface BatchIdPayload {
    batchId: string;
}

export enum AsyncRequestState {
    NONE = "none",
    PENDING = "pending",
    DONE = "done",
    FAILED = "failed",
    EXPIRED = "expired"
}

export interface BaseBatchMetadata {
    batchId: string;
    camera: string;
    microscope: string;
    minIsletSize: number;
    objective: number;
    operator: string;
    sessionCode: string;
    umPerPx: number;
    initialBatchId?: string;
}

export interface IsolationBatchMetadata extends BaseBatchMetadata {
    dilution: number;
    pelletVolumeMl: number;
}

export interface IsolationImageData {
    imageId: string;
    minIsletSize?: number;
    name: string;
    umPerPx?: number;
    userCutLines?: Array<Line>;
}

export interface IsolationImageState extends IsolationImageData {
    isolationRequestId?: string;
    isolationRequestState: AsyncRequestState;
    included: boolean;
    reported: boolean;
    setReportedState: AsyncRequestState;
}

export interface IsolationBatchState extends NewIsolationBatchPayload {
    included: boolean;
    items: IsolationImageState[];
    lastModified: number;
}

export type AnalysisBatchMetadata = BaseBatchMetadata

export interface AnalysisMaskData {
    isletCountUser?: number;
    maskId: string;
    name: string;
    volumeTableUser?: number;
    volumeUser?: number;
    setReportedState: AsyncRequestState;
}

export interface AnalysisMaskState extends AnalysisMaskData {
    included: boolean;
    requestId?: string;
    requestState: AsyncRequestState;
}

export interface AnalysisBatchState extends NewAnalysisBatchPayload {
    included: boolean;
    items: AnalysisMaskState[];
    lastModified: number;
}

export type Pix2PixBatchMetadata = BaseBatchMetadata

export interface Pix2PixImageState extends Pix2PixMaskData {
    included: boolean;
    reported: boolean;
    setReportedState: AsyncRequestState;
    requestId?: string;
    requestState: AsyncRequestState;
}

export interface Pix2PixBatchState extends NewPix2PixBatchPayload {
    included: boolean;
    items: Pix2PixImageState[];
    lastModified: number;
}

export interface Pix2PixMaskData {
    imageId: string;
    name: string;
    umPerPx?: number;
    userMaskId: string;
    userMaskName: string;
}

export interface SimpleComparisonImageData extends ComparisonRequest {
    name: string;
}

export interface SimpleComparisonImageState extends SimpleComparisonImageData {
    requestId?: string;
    requestState: AsyncRequestState;
    reported: boolean;
    setReportedState: AsyncRequestState;
}

export interface ImageIdPayload {
    imageId: string;
}

interface ImageDownloadedPayload extends ImageIdPayload {
    imageBase64: string;
}

export interface NewIsolationBatchPayload {
    metadata: IsolationBatchMetadata;
    items: IsolationImageData[];
}

export interface EditIsolationBatchPayload extends NewIsolationBatchPayload {
    editedBatchId: string;
    items: Array<PartialExtend<IsolationImageData, IsolationImageState>>;
    forceChange?: boolean;
}

interface NewAnalysisBatchPayload {
    metadata: AnalysisBatchMetadata;
    items: AnalysisMaskData[];
}

interface EditAnalysisBatchPayload extends NewAnalysisBatchPayload {
    editedBatchId: string;
    items: Array<PartialExtend<AnalysisMaskData, AnalysisMaskState>>;
}

interface NewPix2PixBatchPayload {
    metadata: Pix2PixBatchMetadata;
    items: Pix2PixMaskData[];
}

interface EditPix2PixBatchPayload extends NewPix2PixBatchPayload {
    editedBatchId: string;
    items: Array<PartialExtend<Pix2PixMaskData, Pix2PixImageState>>;
}

export interface NewSimpleComparisonBatchPayload {
    items: Array<SimpleComparisonImageData>
    method: MethodUser
}

export interface SimpleComparisonBatchState extends NewSimpleComparisonBatchPayload {
    items: Array<SimpleComparisonImageState>
}

export interface ImageProcessingRequestPayload extends BatchIdPayload {
    imageName: string;
}

export interface ImageRequestIdPayload extends ImageProcessingRequestPayload {
    requestId: string;
}

export interface IncludeImagePayload extends BatchIdPayload {
    imageIndex: number;
    included: boolean;
}

interface ImageUploadedPayload {
    file: File;
    name: string;
    imageId: string;
    imageBase64: string;
}

export interface ImageUploadRequestPayload {
    files: File[];
}

interface ImageUploadStatusPayload {
    file: File;
}

interface LegendVisibilityState {
    isVisible: boolean;
}

export interface ReportImagePayload {
    batchId: string;
    imageIndex: number;
    isReported: boolean;
}

export interface NotificationMessagePayload {
    title: string;
    text: string;
}

export interface ResultReceivedPayload<ResultType> {
    requestId: string;
    result: ResultType;
}

export interface ResultFetchingRequestedPayload {
    requestId: string;
}

export interface ResultFetchingFailedPayload {
    requestId: string;
}

export interface RequestExpiredPayload {
    requestId: string;
}

export const addBatch = createAction<NewIsolationBatchPayload>("Add a new batch to the session");
export const editBatch = createAction<EditIsolationBatchPayload>("Replace a batch in the session with a new batch");
export const newSession = createAction("Drop all batches and start a new session");
export const setIncluded = createAction<IncludeImagePayload>("Set the included flag for an image");
export const excludeBatch = createAction<BatchIdPayload>("Exclude a batch from the session");
export const includeBatch = createAction<BatchIdPayload>("Re-include a batch in the session");
export const removeBatch = createAction<BatchIdPayload>("Remove a batch from the session");
export const isolationRequestDone = createAction<ImageRequestIdPayload>("Request ID received for an isolation request");
export const isolationRequestFailed = createAction<ImageProcessingRequestPayload>("An isolation request failed");
export const isolationRequestExpired = createAction<RequestExpiredPayload>("Isolation request expired on the server");

export const addAnalysisBatch = createAction<NewAnalysisBatchPayload>("Add a new analysis batch");
export const editAnalysisBatch = createAction<EditAnalysisBatchPayload>("Replace an analysis batch in the session with a new batch");
export const newAnalysisSession = createAction("Drop all analysis batches and start a new session");
export const removeAnalysisBatch = createAction<BatchIdPayload>("Remove an analysis batch from the session");
export const setAnalysisIncluded = createAction<IncludeImagePayload>("Set the included flag for a mask");
export const analysisRequestDone = createAction<ImageRequestIdPayload>("Request ID received for an analysis request");
export const analysisRequestFailed = createAction<ImageProcessingRequestPayload>("An analysis request failed");
export const analysisRequestExpired = createAction<RequestExpiredPayload>("Analysis request expired on the server");

export const addPix2PixBatch = createAction<NewPix2PixBatchPayload>("Add a new pixel to pixel comparison batch");
export const editPix2PixBatch = createAction<EditPix2PixBatchPayload>("Replace a pix2pix batch in the session with a new batch");
export const newPix2PixSession = createAction("Drop all batches and start a new Pix2Pix session");
export const removePix2PixBatch = createAction<BatchIdPayload>("Remove a pix2pix batch from the session");
export const pix2pixRequestDone = createAction<ImageRequestIdPayload>("Request ID received for a pix2pix request");
export const pix2pixRequestFailed = createAction<ImageProcessingRequestPayload>("A pix2pix request failed");
export const pix2PixRequestExpired = createAction<RequestExpiredPayload>("Pix2Pix request expired on the server");

export const addSimpleComparisonBatch = createAction<NewSimpleComparisonBatchPayload>("Add a new simple comparison batch");
export const newSimpleComparisonSession = createAction("Drop all batches and start a new simple comparison session");
export const simpleComparisonRequestDone = createAction<ImageRequestIdPayload>("Request ID received for a simple comparison request");
export const simpleComparisonRequestFailed = createAction<ImageProcessingRequestPayload>("Simple comparison request failed");
export const simpleComparisonRequestExpired = createAction<RequestExpiredPayload>("Simple comparison request expired on the server");

export const imageDownloadRequested = createAction<ImageIdPayload>("Requested an image download");
export const imageDownloadStarted = createAction<ImageIdPayload>("Started an image download");
export const imageDownloadFinished = createAction<ImageDownloadedPayload>("Image was downloaded");
export const imageDownloadFailed = createAction<ImageIdPayload>("Download of an image failed");
export const imageExpired = createAction<ImageIdPayload>("An image expired");

export const imageUploadRequested = createAction<ImageUploadRequestPayload>("Upload of images was requested");
export const imageUploaded = createAction<ImageUploadedPayload>("Store image data under its newly assigned ID");
export const imageUploadStarted = createAction<ImageUploadStatusPayload>("Upload of an image started");
export const imageUploadFailed = createAction<ImageUploadStatusPayload>("There was an error while waitingForUpload an image");

export const setIsolationImageReported = createAction<ReportImagePayload>("(Un-)mark an image as reported");
export const setIsolationImageReportedDone = createAction<ReportImagePayload>("Reporting an image succeeded");
export const setIsolationImageReportedFailed = createAction<ReportImagePayload>("Reporting an image failed");

export const setPix2PixImageReported = createAction<ReportImagePayload>("(Un-)mark an image as reported");
export const setPix2PixImageReportedDone = createAction<ReportImagePayload>("Reporting an image succeeded");
export const setPix2PixImageReportedFailed = createAction<ReportImagePayload>("Reporting an image failed");

export const setSimpleComparisonImageReported = createAction<ReportImagePayload>("(Un-)mark an image as reported");
export const setSimpleComparisonImageReportedDone = createAction<ReportImagePayload>("Reporting an image succeeded");
export const setSimpleComparisonImageReportedFailed = createAction<ReportImagePayload>("Reporting an image failed");

export const displayErrorMessage = createAction<NotificationMessagePayload>("Error message added");
export const displayNoticeMessage = createAction<NotificationMessagePayload>("Notice message added");
export const hideLastErrorMessage = createAction<{}>("Error message hidden");

export const setLegendVisible = createAction<LegendVisibilityState>("Legend visibility set");

export const isolationResultRequested = createAction<ResultFetchingRequestedPayload>(
    "Results of an isolation request were requested"
);

export const isolationResultReceived = createAction<ResultReceivedPayload<IsolationResult>>(
    "Results of an isolation request were received"
);

export const isolationResultFailed = createAction<ResultFetchingFailedPayload>(
    "Fetching the results of an isolation request failed"
);

export const analysisResultRequested = createAction<ResultFetchingRequestedPayload>(
    "Results of an analysis request were requested"
);

export const analysisResultReceived = createAction<ResultReceivedPayload<AnalysisResult>>(
    "Results of an analysis request were received"
);

export const analysisResultFailed = createAction<ResultFetchingFailedPayload>(
    "Fetching the results of an analysis request failed"
);

export const pix2pixResultRequested = createAction<ResultFetchingRequestedPayload>(
    "Results of a pix2pix request were requested"
);

export const pix2pixResultReceived = createAction<ResultReceivedPayload<Pix2PixResult>>(
    "Results of a pix2pix request were received"
);

export const pix2pixResultFailed = createAction<ResultFetchingFailedPayload>(
    "Fetching the results of a pix2pix request failed"
);

export const simpleComparisonResultRequested = createAction<ResultFetchingRequestedPayload>(
    "Results of a simple comparison request were requested"
);

export const simpleComparisonResultReceived = createAction<ResultReceivedPayload<ComparisonResult>>(
    "Results of a simple comparison request were received"
);

export const simpleComparisonResultFailed = createAction<ResultFetchingFailedPayload>(
    "Fetching the results of a simple comparison request failed"
);

interface IsolationBatchReducerState {
    [batchId: string]: IsolationBatchState;
}

interface Pix2PixBatchReducerState {
    [batchId: string]: Pix2PixBatchState;
}

interface AnalysisBatchReducerState {
    [batchId: string]: AnalysisBatchState;
}

export interface SimpleComparisonBatchReducerState {
    batches: SimpleComparisonBatchState[]
}

const isolationBatchDataReducer = createReducer<IsolationBatchReducerState>({}, {})
    .on(addBatch, (state, payload) => produce(state, draft => {
        draft[payload.metadata.batchId] = {
            ...payload,
            included: true,
            items: payload.items.map(item => ({
                ...item,
                included: true,
                isolationRequestState: AsyncRequestState.NONE,
                reported: false,
                setReportedState: AsyncRequestState.NONE
            })).sort((a, b) => a.name.localeCompare(b.name)),
            lastModified: Date.now()
        };
    }))
    .on(editBatch, (state, payload) => produce(state, draft => {
        const previousMetadata = draft[payload.editedBatchId].metadata;
        draft[payload.editedBatchId].lastModified = Date.now();
        draft[payload.editedBatchId].metadata = payload.metadata;
        draft[payload.editedBatchId].items = payload.items.map(item => {
            const previousItem = draft[payload.editedBatchId].items.find(i => i.name === item.name);

            let itemChanged =
                (item.minIsletSize === undefined && previousMetadata.minIsletSize !== payload.metadata.minIsletSize)
                || (item.umPerPx === undefined && previousMetadata.umPerPx !== payload.metadata.umPerPx);

            if (previousItem !== undefined) {
                if (previousItem.imageId !== item.imageId) {
                    itemChanged = true;
                }

                if (previousItem.minIsletSize !== item.minIsletSize) {
                    itemChanged = true;
                }

                if (previousItem.umPerPx !== item.umPerPx) {
                    itemChanged = true;
                }
            } else {
                itemChanged = true;
            }

            if (payload.forceChange) {
                itemChanged = true;
            }

            return ({
                ...item,
                included: previousItem !== undefined ? previousItem.included : true,
                isolationRequestId: !itemChanged ? item.isolationRequestId : undefined,
                isolationRequestState: item.isolationRequestState !== undefined ? item.isolationRequestState : AsyncRequestState.NONE,
                reported: false,
                setReportedState: AsyncRequestState.NONE
            });
        }).sort((a, b) => a.name.localeCompare(b.name));

        if (payload.editedBatchId !== payload.metadata.batchId) {
            draft[payload.metadata.batchId] = draft[payload.editedBatchId];
            delete draft[payload.editedBatchId];
        }
    }))
    .on(isolationRequestDone, (state, payload) => produce(state, draft => {
        const item = draft[payload.batchId].items.find(i => i.name === payload.imageName)!;
        item.isolationRequestId = payload.requestId;
        item.isolationRequestState = AsyncRequestState.DONE;
    }))
    .on(isolationRequestFailed, (state, payload) => produce(state, draft => {
        const item = draft[payload.batchId].items.find(i => i.name === payload.imageName)!;
        item.isolationRequestState = AsyncRequestState.FAILED;
    }))
    .on(newSession, (state, payload) => ({}))
    .on(setIncluded, (state: IsolationBatchReducerState, payload: IncludeImagePayload) => produce(state, draft => {
        draft[payload.batchId].items[payload.imageIndex].included = payload.included;
    }))
    .on(excludeBatch, (state, payload) => produce(state, draft => {
        draft[payload.batchId].included = false;
    }))
    .on(includeBatch, (state, payload) => produce(state, draft => {
        draft[payload.batchId].included = true;
    }))
    .on(removeBatch, (state, payload) => produce(state, draft => {
        delete draft[payload.batchId];
    }))
    .on(setIsolationImageReported, (state: IsolationBatchReducerState, payload: ReportImagePayload) => produce(state, draft => {
        draft[payload.batchId].items[payload.imageIndex].setReportedState = AsyncRequestState.PENDING;
    }))
    .on(setIsolationImageReportedDone, (state, payload) => produce(state, draft => {
        draft[payload.batchId].items[payload.imageIndex].reported = payload.isReported;
        draft[payload.batchId].items[payload.imageIndex].setReportedState = AsyncRequestState.DONE;
    }))
    .on(setIsolationImageReportedFailed, (state, payload) => produce(state, draft => {
        draft[payload.batchId].items[payload.imageIndex].setReportedState = AsyncRequestState.FAILED;
    }));

const analysisBatchDataReducer = createReducer<AnalysisBatchReducerState>({}, {})
    .on(newAnalysisSession, () => ({}))
    .on(addAnalysisBatch, (state, payload) => produce(state, draft => {
        draft[payload.metadata.batchId] = {
            ...payload,
            included: true,
            items: payload.items.map(item => ({
                ...item,
                included: true,
                requestState: AsyncRequestState.NONE
            })).sort((a, b) => a.name.localeCompare(b.name)),
            lastModified: Date.now()
        };
    }))
    .on(editAnalysisBatch, (state, payload) => produce(state, draft => {
        const previousMetadata = draft[payload.editedBatchId].metadata;
        const batchChanged = previousMetadata.minIsletSize !== payload.metadata.minIsletSize
            || previousMetadata.umPerPx !== payload.metadata.umPerPx;

        draft[payload.editedBatchId].lastModified = Date.now();
        draft[payload.editedBatchId].metadata = payload.metadata;
        draft[payload.editedBatchId].items = payload.items.map(item => {
            const previousItem = draft[payload.editedBatchId].items.find(i => i.name === item.name);
            let itemChanged = batchChanged;

            if (previousItem !== undefined) {
                if (previousItem.maskId !== item.maskId) {
                    itemChanged = true;
                }

                if (previousItem.isletCountUser !== item.isletCountUser) {
                    itemChanged = true;
                }

                if (previousItem.volumeUser !== item.volumeUser) {
                    itemChanged = true;
                }

                if (previousItem.volumeTableUser !== item.volumeTableUser) {
                    itemChanged = true;
                }
            } else {
                itemChanged = true;
            }

            return ({
                ...item,
                included: previousItem !== undefined ? previousItem.included : true,
                requestId: !itemChanged ? item.requestId : undefined,
                requestState: item.requestState !== undefined ? item.requestState : AsyncRequestState.NONE
            });
        }).sort((a, b) => a.name.localeCompare(b.name));

        if (payload.editedBatchId !== payload.metadata.batchId) {
            draft[payload.metadata.batchId] = draft[payload.editedBatchId];
            delete draft[payload.editedBatchId];
        }
    }))
    .on(setAnalysisIncluded, (state, payload) => {
        return produce(state, draft => {
            draft[payload.batchId].items[payload.imageIndex].included = payload.included;
        });
    })
    .on(analysisRequestDone, (state, payload) => produce(state, draft => {
        const item = draft[payload.batchId].items.find(i => i.name === payload.imageName)!;
        item.requestId = payload.requestId;
        item.requestState = AsyncRequestState.DONE;
    }))
    .on(analysisRequestFailed, (state, payload) => produce(state, draft => {
        const item = draft[payload.batchId].items.find(i => i.name === payload.imageName)!;
        item.requestState = AsyncRequestState.FAILED;
    }))
    .on(removeAnalysisBatch, (state, payload) => produce(state, draft => {
        delete draft[payload.batchId];
    }));

const pix2pixBatchDataReducer = createReducer<Pix2PixBatchReducerState>({}, {})
    .on(newPix2PixSession, () => ({}))
    .on(addPix2PixBatch, (state, payload) => produce(state, draft => {
        draft[payload.metadata.batchId] = {
            ...payload,
            included: true,
            items: payload.items.map(item => ({
                ...item,
                included: true,
                reported: false,
                requestState: AsyncRequestState.NONE,
                setReportedState: AsyncRequestState.NONE
            })).sort((a, b) => a.name.localeCompare(b.name)),
            lastModified: Date.now()
        };
    }))
    .on(editPix2PixBatch, (state, payload) => produce(state, draft => {
        const previousMetadata = draft[payload.editedBatchId].metadata;
        draft[payload.editedBatchId].lastModified = Date.now();
        draft[payload.editedBatchId].metadata = payload.metadata;
        draft[payload.editedBatchId].items = payload.items.map(item => {
            const previousItem = draft[payload.editedBatchId].items.find(i => i.userMaskName === item.userMaskName);

            let itemChanged = previousMetadata.minIsletSize !== payload.metadata.minIsletSize
                || (item.umPerPx === undefined && previousMetadata.umPerPx !== payload.metadata.umPerPx);

            if (previousItem !== undefined) {
                if (previousItem.imageId !== item.imageId) {
                    itemChanged = true;
                }

                if (previousItem.userMaskId !== item.userMaskId) {
                    itemChanged = true;
                }

                if (previousItem.umPerPx !== item.umPerPx) {
                    itemChanged = true;
                }
            } else {
                itemChanged = true;
            }

            return ({
                ...item,
                included: previousItem !== undefined ? previousItem.included : true,
                reported: false,
                requestId: !itemChanged ? item.requestId : undefined,
                requestState: item.requestState !== undefined ? item.requestState : AsyncRequestState.NONE,
                setReportedState: AsyncRequestState.NONE
            });
        }).sort((a, b) => a.name.localeCompare(b.name));

        if (payload.editedBatchId !== payload.metadata.batchId) {
            draft[payload.metadata.batchId] = draft[payload.editedBatchId];
            delete draft[payload.editedBatchId];
        }
    }))
    .on(removePix2PixBatch, (state, payload) => produce(state, draft => {
        delete draft[payload.batchId];
    }))
    .on(pix2pixRequestDone, (state, payload) => produce(state, draft => {
        const item = draft[payload.batchId].items.find(i => i.userMaskName === payload.imageName)!;
        item.requestId = payload.requestId;
        item.requestState = AsyncRequestState.DONE;
    }))
    .on(pix2pixRequestFailed, (state, payload) => produce(state, draft => {
        const item = draft[payload.batchId].items.find(i => i.userMaskName === payload.imageName)!;
        item.requestState = AsyncRequestState.FAILED;
    }))
    .on(setPix2PixImageReported, (state: Pix2PixBatchReducerState, payload: ReportImagePayload) => produce(state, draft => {
        if (!Object.keys(draft).includes(payload.batchId)) {
            return;
        }

        draft[payload.batchId].items[payload.imageIndex].setReportedState = AsyncRequestState.PENDING;
    }))
    .on(setPix2PixImageReportedDone, (state, payload) => produce(state, draft => {
        if (!Object.keys(draft).includes(payload.batchId)) {
            return;
        }

        draft[payload.batchId].items[payload.imageIndex].reported = payload.isReported;
        draft[payload.batchId].items[payload.imageIndex].setReportedState = AsyncRequestState.DONE;
    }))
    .on(setPix2PixImageReportedFailed, (state, payload) => produce(state, draft => {
        if (!Object.keys(draft).includes(payload.batchId)) {
            return;
        }

        draft[payload.batchId].items[payload.imageIndex].setReportedState = AsyncRequestState.FAILED;
    }));

const simpleComparisonBatchDataReducer = createReducer<SimpleComparisonBatchReducerState>({}, { batches: []})
    .on(newSimpleComparisonSession, () => ({batches: []}))
    .on(addSimpleComparisonBatch, (state, payload) => produce(state, draft => {
        draft.batches.push({...payload, items: payload.items.map(item => ({...item, requestState: AsyncRequestState.NONE, reported: false, setReportedState: AsyncRequestState.NONE}))});
    }))
    .on(simpleComparisonRequestDone, (state, payload) => produce(state, draft => {
        const item = draft.batches[parseInt(payload.batchId, 10)].items.find(i => i.imageId === payload.imageName)!;
        item.requestId = payload.requestId;
        item.requestState = AsyncRequestState.DONE;
    }))
    .on(simpleComparisonRequestFailed, (state, payload) => produce(state, draft => {
        const item = draft.batches[parseInt(payload.batchId, 10)].items.find(i => i.imageId === payload.imageName)!;
        item.requestState = AsyncRequestState.FAILED;
    }))
    .on(setSimpleComparisonImageReported, (state, payload) => produce(state, draft => {
        if (!Object.keys(draft.batches).includes(payload.batchId)) {
            return;
        }

        draft.batches[parseInt(payload.batchId, 10)].items[payload.imageIndex].setReportedState = AsyncRequestState.PENDING;
    }))
    .on(setSimpleComparisonImageReportedDone, (state, payload) => produce(state, draft => {
        if (!Object.keys(draft.batches).includes(payload.batchId)) {
            return;
        }

        draft.batches[parseInt(payload.batchId, 10)].items[payload.imageIndex].reported = payload.isReported;
        draft.batches[parseInt(payload.batchId, 10)].items[payload.imageIndex].setReportedState = AsyncRequestState.DONE;
    }))
    .on(setSimpleComparisonImageReportedFailed, (state, payload) => produce(state, draft => {
        if (!Object.keys(draft.batches).includes(payload.batchId)) {
            return;
        }

        draft.batches[parseInt(payload.batchId, 10)].items[payload.imageIndex].setReportedState = AsyncRequestState.FAILED;
    }));
;

/**
 *  Maps remote image IDs to their content
 */
interface ImageIdCacheReducerState {
    [imageId: string]: string;
}

const imageIdCacheReducer = createReducer<ImageIdCacheReducerState>({}, {})
    .on(imageUploaded, (state, payload) => produce(state, draft => {
        draft[payload.imageId] = payload.imageBase64;
    }))
    .on(imageDownloadFinished, (state, payload) => produce(state, draft => {
        draft[payload.imageId] = payload.imageBase64;
    }));

/**
 * Maps a hash of image content to remote IDs
 */
export interface ImageContentCacheReducerState {
    [imageHash: string]: string;
}

const imageContentCacheReducer = createReducer<ImageContentCacheReducerState>({}, {})
    .on(imageUploaded, (state, payload) => produce(state, draft => {
        // draft[contentHash(payload.imageBase64)] = payload.imageId; // TODO make sure ID caching actually works (w.r.t. pruner)
    }));

interface ImageUploadStatusReducerState {
    [fileName: string]: {
        done: boolean;
        error: boolean;
        id: string|null;
        name: string|null
    };
}

const imageUploadStatusReducer = createReducer<ImageUploadStatusReducerState>({}, {})
    .on(imageUploadStarted, (state, payload) => produce(state, draft => {
        draft[payload.file.name] = {
            done: false,
            error: false,
            id: null,
            name: null
        };
    }))
    .on(imageUploadFailed, (state, payload) => produce(state, draft => {
        draft[payload.file.name].done = true;
        draft[payload.file.name].error = true;
    }))
    .on(imageUploaded, (state, payload) => produce(state, draft => {
        const statusEntry = draft[payload.file.name];
        statusEntry.done = true;
        statusEntry.id = payload.imageId;
        statusEntry.name = payload.name;
    }));

interface ImageDownloadStatusReducerState {
    [imageId: string]: AsyncRequestState
}

const imageDownloadStatusReducer = createReducer<ImageDownloadStatusReducerState>({}, {})
    .on(imageDownloadRequested, (state, payload) => produce(state, draft => {
        if (draft[payload.imageId] === undefined) {
            draft[payload.imageId] = AsyncRequestState.NONE;
        }
    }))
    .on(imageDownloadStarted, (state, payload) => produce(state, draft => {
        draft[payload.imageId] = AsyncRequestState.PENDING;
    }))
    .on(imageDownloadFinished, (state, payload) => produce(state, draft => {
        draft[payload.imageId] = AsyncRequestState.DONE;
    }))
    .on(imageUploaded, (state, payload) => produce(state, draft => {
        draft[payload.imageId] = AsyncRequestState.DONE;
    }))
    .on(imageDownloadFailed, (state, payload) => produce(state, draft => {
        draft[payload.imageId] = AsyncRequestState.FAILED;
    }))
    .on(imageExpired, (state, payload) => produce(state, draft => {
        draft[payload.imageId] = AsyncRequestState.EXPIRED;
    }));

type ErrorMessageReducerState = Array<{
    title: string;
    text: string;
    type: "error" | "notice";
}>;

const errorMessageReducer = createReducer<ErrorMessageReducerState>({}, [])
    .on(displayErrorMessage, (state, payload) => produce(state, draft => {
        draft.push({...payload, type: "error"});
    }))
    .on(displayNoticeMessage, (state, payload) => produce(state, draft => {
        draft.push({...payload, type: "notice"});
    }))
    .on(hideLastErrorMessage, (state) => produce(state, draft => {
        draft.pop();
    }));

interface IsolationResultsReducerState {
    [requestId: string]: {
        result?: IsolationResult;
        state: AsyncRequestState;
    };
}

const isolationResultsReducer = createReducer<IsolationResultsReducerState>({}, {})
    .on(isolationResultRequested, (state, payload) => produce(state, draft => {
        draft[payload.requestId] = {
            state: AsyncRequestState.PENDING
        };
    }))
    .on(isolationResultReceived, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.DONE;
        draft[payload.requestId].result = payload.result;
    }))
    .on(isolationResultFailed, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.FAILED;
    }))
    .on(isolationRequestExpired, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.EXPIRED;
    }));

interface AnalysisResultsReducerState {
    [requestId: string]: {
        result?: AnalysisResult;
        state: AsyncRequestState;
    };
}

const analysisResultsReducer = createReducer<AnalysisResultsReducerState>({}, {})
    .on(newAnalysisSession, () => ({}))
    .on(analysisResultRequested, (state, payload) => produce(state, draft => {
        draft[payload.requestId] = {
            state: AsyncRequestState.PENDING
        };
    }))
    .on(analysisResultReceived, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.DONE;
        draft[payload.requestId].result = payload.result;
    }))
    .on(analysisResultFailed, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.FAILED;
    }))
    .on(analysisRequestExpired, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.EXPIRED;
    }));

interface Pix2PixResultsReducerState {
    [requestId: string]: {
        result?: Pix2PixResult;
        state: AsyncRequestState;
    };
}

const pix2pixResultsReducer = createReducer<Pix2PixResultsReducerState>({}, {})
    .on(newPix2PixSession, () => ({}))
    .on(pix2pixResultRequested, (state, payload) => produce(state, draft => {
        draft[payload.requestId] = {
            state: AsyncRequestState.PENDING
        };
    }))
    .on(pix2pixResultReceived, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.DONE;
        draft[payload.requestId].result = payload.result;
    }))
    .on(pix2pixResultFailed, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.FAILED;
    }))
    .on(pix2PixRequestExpired, (state, payload) => produce(state, draft => {
        draft[payload.requestId].state = AsyncRequestState.EXPIRED;
    }));

interface SimpleComparisonResultsReducerState {
    [requestId: string]: {
        result?: ComparisonResult;
        state: AsyncRequestState
    }
}

const simpleComparisonResultsReducer = createReducer<SimpleComparisonResultsReducerState>({}, {})
    .on(newSimpleComparisonSession, () => ({}))
    .on(simpleComparisonResultRequested, (state, payload) => produce(state, draft => {
        draft[payload.requestId] = {
            state: AsyncRequestState.PENDING
        }
    }))
    .on(simpleComparisonResultReceived, (state, payload) => produce(state, draft => {
        draft[payload.requestId] = {state: AsyncRequestState.DONE, result: payload.result};
    }))
    .on(simpleComparisonResultFailed, (state, payload) => produce(state, draft => {
        draft[payload.requestId] = {state: AsyncRequestState.FAILED}
    }))
    .on(simpleComparisonRequestExpired, (state, payload) => produce(state, draft => {
        draft[payload.requestId] = {state: AsyncRequestState.EXPIRED}
    }));

const legendVisibilityReducer = createReducer<LegendVisibilityState>({}, {isVisible: false})
    .on(setLegendVisible, (state, payload) => produce(state, draft => {
        draft.isVisible = payload.isVisible;
    }));

export interface StoreShape {
    analysisBatches: AnalysisBatchReducerState;
    analysisResults: AnalysisResultsReducerState;
    errorMessages: ErrorMessageReducerState;
    imageContentCache: ImageContentCacheReducerState;
    imageDownloadStatus: ImageDownloadStatusReducerState;
    imageIdCache: ImageIdCacheReducerState;
    imageUploadStatus: ImageUploadStatusReducerState;
    isolationBatches: IsolationBatchReducerState;
    isolationResults: IsolationResultsReducerState;
    legendVisible: LegendVisibilityState;
    pix2pixBatches: Pix2PixBatchReducerState;
    pix2pixResults: Pix2PixResultsReducerState;
    simpleComparisonBatches: SimpleComparisonBatchReducerState;
    simpleComparisonResults: SimpleComparisonResultsReducerState
}

export default {
    analysisBatches: analysisBatchDataReducer,
    analysisResults: analysisResultsReducer,
    errorMessages: errorMessageReducer,
    imageContentCache: imageContentCacheReducer,
    imageDownloadStatus: imageDownloadStatusReducer,
    imageIdCache: imageIdCacheReducer,
    imageUploadStatus: imageUploadStatusReducer,
    isolationBatches: isolationBatchDataReducer,
    isolationResults: isolationResultsReducer,
    legendVisible: legendVisibilityReducer,
    pix2pixBatches: pix2pixBatchDataReducer,
    pix2pixResults: pix2pixResultsReducer,
    simpleComparisonBatches: simpleComparisonBatchDataReducer,
    simpleComparisonResults: simpleComparisonResultsReducer
};