import React from "react";

import ClassNames from "classnames"
import {Control, LocalForm} from "react-redux-form";

import {Pix2PixMetadataItem} from "./Pix2PixUploader";

import Icon from "../Icon";

import {toFloat} from '../../utils/parsers';
import {isPositiveFloat} from "../../utils/validators";
import TruncatedText from "../TruncatedText";
import ClickableLink from "../ClickableLink";

export interface Pix2PixUploadMetadata {
    umPerPx: string;
}

interface Pix2PixUploadItemProps extends React.Props<Pix2PixUploadItem> {
    done: boolean;
    error: boolean;
    fileName: string;
    imageMissing: boolean;
    initialState: Pix2PixUploadMetadata;
    isGroundTruth: boolean;
    onClick: (file: string) => void;
    onMetadataChanged: (data: Pix2PixMetadataItem) => void;
    onRemove: (file: string) => void;
    selected: boolean;
}

class Pix2PixUploadItem extends React.Component<Pix2PixUploadItemProps> {
    public static defaultProps = {
        initialState: {
            umPerPx: ""
        },
        onClick: () => {
            return;
        },
        onMetadataChanged: () => {
            return;
        },
        onRemove: () => {
            return;
        }
    };

    private removeHandler = (event: any) => {
        event.stopPropagation();
        this.props.onRemove(this.props.fileName);
    };

    private clickHandler = (event: any) => {
        event.stopPropagation();
        this.props.onClick(this.props.fileName);
    };

    private metadataChangeHandler = (data: Pix2PixUploadMetadata) => {
        this.props.onMetadataChanged({
            isGroundTruth: this.props.isGroundTruth,
            name: this.props.fileName,
            umPerPx: data.umPerPx.length ? parseFloat(data.umPerPx) : undefined
        });
    };

    public componentDidMount() {
        this.metadataChangeHandler({umPerPx: ""}); // HACK - this sets the defaults for images that were just added
    }

    public render() {
        // Set up status text.
        let statusText = "Uploading...";

        if (this.props.done) {
            statusText = "";
        }
        if (this.props.error) {
            statusText = "Upload error";
        }

        return (
            <li onClick={this.clickHandler} className={ClassNames({
                done: this.props.done,
                error: this.props.error,
                "image-missing": this.props.isGroundTruth && this.props.imageMissing,
                segmentation: this.props.isGroundTruth,
                selected: this.props.selected
            })}>
                <TruncatedText text={this.props.fileName} />
                <span className="status-text">{statusText}</span>
                {!this.props.isGroundTruth && <LocalForm onChange={this.metadataChangeHandler}>
                    <Control.text defaultValue={this.props.initialState.umPerPx} model=".umPerPx"
                                  validators={{isPositiveFloat}} parser={toFloat}/>
                </LocalForm>}
                <ClickableLink title="Remove image" onClick={this.removeHandler}><Icon name="close"/></ClickableLink>
            </li>
        );
    }
}

export default Pix2PixUploadItem;
