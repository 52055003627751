import {MethodUser} from "../api/models";

const initialBatchState = {
    "batchId": "",
    "camera": "",
    "dilution": "",
    "donorCode": "",
    "microscope": "",
    "minIsletSize": "",
    "objective": "",
    "operator": "",
    "pelletVolumeMl": "",
    "sessionCode": "",
    "umPerPx": ""
};

export const initialSimpleComparisonState = {
    method: MethodUser.RicordiShortArea
};

export default {
    analysisUploaderForm: initialBatchState,
    isolationUploaderForm: initialBatchState,
    pix2pixUploaderForm: initialBatchState,
    simpleComparisonUploaderForm: initialSimpleComparisonState,
};