import React from "react";
import {connect} from "react-redux";

import download from "downloadjs";
import produce from "immer";
import {flatten, isEqual, zip} from "lodash";

import {AnalysisResult, Configuration, DefaultApi} from "../api";
import AnalysisSummaryComponent, {AnalysisSummaryProps as SummaryComponentProps} from "../components/AnalysisSummary";
import LoadingScreen from "../components/LoadingScreen";
import {mergeAnalysisBatchesWithResults} from "../components/results/helpers";

import {API_URL} from "../env";
import {AnalysisBatchState, AsyncRequestState} from "../redux/actions";

interface AnalysisSummaryProps extends React.Props<AnalysisSummary> {
    batches: {[batchId: string]: AnalysisBatchState};
    onCloseSession: () => void;
}

interface AnalysisSummaryState {
    results: {[requestId: string]: AnalysisResult};
    loaded: boolean;
    zipDownloadStatus: AsyncRequestState;
}

class AnalysisSummary extends React.Component<AnalysisSummaryProps, AnalysisSummaryState> { // TODO copypasted from isolation Summary
    private api = new DefaultApi(new Configuration({basePath: API_URL}));

    constructor(props: AnalysisSummaryProps) {
        super(props);
        this.state = {
            loaded: false,
            results: {},
            zipDownloadStatus: AsyncRequestState.NONE
        }
    }

    private getBatchData() {
        return {
            batches: Object.values(this.props.batches).map(batch => ({
                batchMetadata: {
                    batchId: batch.metadata.batchId,
                    camera: batch.metadata.camera,
                    microscope: batch.metadata.microscope,
                    objective: batch.metadata.objective,
                    operator: batch.metadata.operator,
                    sessionCode: batch.metadata.sessionCode,
                },
                requests: batch.items.map(item => ({
                    included: item.included,
                    isletCountUser: item.isletCountUser,
                    maskFilename: item.name,
                    requestId: item.requestId!,
                    volumeTableUser: item.volumeTableUser,
                    volumeUser: item.volumeUser
                }))
            }))
        };
    }

    private load() {
        this.setState({
            loaded: false
        });

        const requestIds = flatten(Object.values(this.props.batches)
            .map(batch => batch.items.map(item => item.requestId!))
        );

        Promise.all(requestIds.map(id => this.api.analysisResult({requestId: id}))).then(responses => {
            this.setState(prevState => produce(prevState, draft => {
                draft.loaded = true;

                zip(requestIds, responses).forEach(([requestId, response]) => {
                    draft.results[requestId!] = response!;
                })
            }));
        });
    }

    private getFilename = (response: Response) => {
        const header = response.headers.get("content-disposition");

        if (header === null) {
            return null;
        }

        return header.match(/filename=(.+)/)![1]
    };

    private downloadZIP = async () => {
        this.setState({
            zipDownloadStatus: AsyncRequestState.PENDING
        });

        try {
            const response = await this.api.analysisZipRaw({body: this.getBatchData()});
            const blob = await response.value();

            download(blob, this.getFilename(response.raw) || 'results.zip');
            this.setState({
                zipDownloadStatus: AsyncRequestState.DONE
            });
        } catch (e) {
            this.setState({
                zipDownloadStatus: AsyncRequestState.FAILED
            })
        }
    };

    public componentDidMount() {
        this.load();
    }

    public componentDidUpdate(prevProps: AnalysisSummaryProps) {
        if (!isEqual(prevProps.batches, this.props.batches)) {
            this.load();
        }
    }

    public render() {
        const firstBatch = this.props.batches[Object.keys(this.props.batches)[0]];

        if (firstBatch === undefined) {
            return <div>No batches supplied</div>
        }

        const batches: SummaryComponentProps["batches"] = {};

        if (this.state.loaded) {
            const batchesWithResults = mergeAnalysisBatchesWithResults(this.state.results, this.props.batches);
            Object.entries(batchesWithResults).forEach(([batchId, batch]) => {
                batches[batchId] = {
                    included: batch.included,
                    items: batch.items.map(item => ({
                        included: item.included,
                        statistics: item.result.userMaskStatistics
                    })),
                    metadata: batch.metadata
                }
            });
        }

        return this.state.loaded
            ? <AnalysisSummaryComponent batches={batches} onZIP={this.downloadZIP}
                                        zipDownloadState={this.state.zipDownloadStatus}
                                        sessionCode={firstBatch.metadata.sessionCode}
                                        onCloseSession={this.props.onCloseSession} />
            : <LoadingScreen/>;
    }
}


export default connect(
    (state: any) => ({
        batches: state.analysisBatches
    }),
    () => ({})
)(AnalysisSummary);
