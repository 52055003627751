import React from "react";
import {connect} from "react-redux";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";

import {
    addPix2PixBatch,
    newPix2PixSession,
    Pix2PixBatchMetadata,
    Pix2PixMaskData,
} from "../redux/actions";

import PromptWrapper from "../components/PromptWrapper";
import {UploaderContentState} from "../components/Uploader";

import {SubmittedBatch} from "../components/upload/Pix2PixUploader";
import Pix2PixUploader from "../containers/upload/Pix2PixUploader";

interface Pix2PixProps extends RouteComponentProps<any> {
    onStartup: () => void;
    onSuccess: (items: Pix2PixMaskData[], metadata: Pix2PixBatchMetadata) => void;
}

interface Pix2PixState {
    uploading: boolean;
    uploaderState: UploaderContentState;
}

class Pix2Pix extends React.Component<Pix2PixProps, Pix2PixState> {
    constructor(props: Pix2PixProps) {
        super(props);

        this.state = {
            uploaderState: {
                modified: false
            },
            uploading: false
        };
    }

    public componentDidMount() {
        this.props.onStartup();
    }

    private onSubmit = (batch: SubmittedBatch) => {
        this.props.onSuccess(batch.imagesMetadata, batch.batchMetadata);
        this.setState({uploading: true});
    };

    private onUploaderStateChanged = (uploaderState: UploaderContentState) => this.setState({uploaderState});

    public render() {
        if (this.state.uploading) {
            return <Redirect to="/pix2pix/results"/>;
        }

        return (
            <div className="card-wrapper">
                <PromptWrapper
                    message="If you leave this page, the data in this session will be lost. Continue?"
                    when={this.state.uploaderState.modified}
                />
                <Pix2PixUploader onSubmit={this.onSubmit} disabledFields={[]} erasedFields={[]}
                                 onStateChanged={this.onUploaderStateChanged} />
            </div>
        );
    }
}

export default connect(
    () => ({}),
    (dispatch) => ({
        onStartup: () => {
            dispatch(newPix2PixSession());
        },
        onSuccess: (items: Pix2PixMaskData[], metadata: Pix2PixBatchMetadata) => {
            dispatch(addPix2PixBatch({items, metadata}));
        }
    })
)(withRouter(Pix2Pix));
