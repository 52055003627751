/* eslint-disable */
// tslint:disable
/**
 * Isletnet API
 *  # Islets  Rest API for [IsletNet](http://isletnet.com/) - an accurate neural network based tool for automated segmentation and analysis  of pancreatic (Langerhans) islets. For more information, see the IsletNet [User guide](http://isletnet.com/guide) or try the tool at  [www.isletnet.com](http://isletnet.com/).  Both IsletNet and this API serve for two main usecases:  ## Analysis   The main usecase.   User submits a request:   - images of the tissue AND/OR own segmentations (can be mixed) - user input data (information about batch of images)   The API returns the response containing: - automated segmentation of islets for each submitted image of the tissue  - histogram of islet size distribution for each image - results of the analysis (both detailed results per image and overall summary)  ## Validation  The usecase to assess the accuracy of one\'s own segmentation by submitting both the segmentation and the  original image. The API returns the comparison of his segmentation to the automated one, returning both  visual and statistical summary.  User submits a request:  - image&own_segmentation (mask) pairs  The API returns the response containing: - results of validation against the automated segmentation - visual comparison 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageInfoResponse
 */
export interface ImageInfoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ImageInfoResponse
     */
    grayscale: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ImageInfoResponse
     */
    resolution: Array<number>;
}

export function ImageInfoResponseFromJSON(json: any): ImageInfoResponse {
    return ImageInfoResponseFromJSONTyped(json, false);
}

export function ImageInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grayscale': json['grayscale'],
        'resolution': json['resolution'],
    };
}

export function ImageInfoResponseToJSON(value?: ImageInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grayscale': value.grayscale,
        'resolution': value.resolution,
    };
}


