import React from 'react';

import {concat, flatten, noop} from 'lodash';

import AnalysisBatchSummary from "./AnalysisBatchSummary";
import {defaultTabState, TabState} from "./ResultsContent";
import {AnalysisItemResult} from "./types";

import LoadingScreen from "../LoadingScreen";
import ResultsContent from "../../containers/results/ResultsContent";

interface AnalysisBatchResultsProps {
    batchId: string;
    imagesLoading: boolean;
    items: AnalysisItemResult[];
    onSelect: (itemIndex: number, batchId: string) => void;
    onSetIncluded: (itemIndex: number, batchId: string, value: boolean) => void;
    onStartLoading: (imageIds: string[]) => void;
    onTabStateChanged: (state: TabState) => void;
    selectedItem: number;
    tabState: TabState;
}

class AnalysisBatchResults extends React.Component<AnalysisBatchResultsProps> {
    public static defaultProps = {
        onSelect: noop,
        onTabStateChanged: noop,
        tabState: defaultTabState
    };

    private onSelect = (item: number) => {
        this.props.onSelect(item, this.props.batchId);
    };

    private onNext = () => this.props.onSelect(this.props.selectedItem + 1, this.props.batchId);
    private onPrevious = () => this.props.onSelect(this.props.selectedItem - 1, this.props.batchId);

    public static getLeftMenuItems(items: AnalysisItemResult[], item: number) {
        return [
            {
                hotkey: "1",
                id: items[item].maskId,
                title: "Annotated segmentation"
            },
          ];
    }

    public static getRightMenuItems(items: AnalysisItemResult[], item: number) {
        return [
            {
                hotkey: "2",
                id: items[item].result.userHistogramId,
                isZoomable: false,
                title: "Histogram"
            },
        ];
    }

    public static getDisplayedImages(items: AnalysisItemResult[]) {
        return flatten(items.map( (value, index) =>
            concat(AnalysisBatchResults.getLeftMenuItems(items, index), AnalysisBatchResults.getRightMenuItems(items, index))
                .map(i => i.id)))
    }

    public componentDidMount() {
        this.props.onStartLoading(AnalysisBatchResults.getDisplayedImages(this.props.items))
    }

    public componentDidUpdate() {
        this.props.onStartLoading(AnalysisBatchResults.getDisplayedImages(this.props.items))
    }

    private onSetIncluded = (value: boolean) => this.props.onSetIncluded(
        this.props.selectedItem,
        this.props.batchId,
        value
    );

    public render() {
        const items = this.props.items.map(item => ({
            included: item.included,
            name: item.name,
            statistics: item.result.userMaskStatistics
        }));

        return (
            <div>
                {
                    !this.props.imagesLoading
                        ? <ResultsContent tabState={this.props.tabState} onTabStateChanged={this.props.onTabStateChanged}
                                    leftMenuItems={AnalysisBatchResults.getLeftMenuItems(this.props.items, this.props.selectedItem)}
                                    rightMenuItems={AnalysisBatchResults.getRightMenuItems(this.props.items, this.props.selectedItem)}
                                    onNext={this.onNext} onPrevious={this.onPrevious} fileName={this.props.items[this.props.selectedItem].name}
                                    included={items[this.props.selectedItem].included} onSetIncluded={this.onSetIncluded} />
                        : <LoadingScreen text="Loading images"/>
                }
                <AnalysisBatchSummary batchId={this.props.batchId} items={items}
                                      selectedItem={this.props.selectedItem} onSelect={this.onSelect}/>
            </div>
        );
    }
}

export default AnalysisBatchResults;
