import React from "react";
import {actions, Control, Form} from 'react-redux-form';

import classNames from "classnames";
import {noop} from "lodash";

import ClickableLink from "../ClickableLink";
import ModalWindow from "../ModalWindow";
import PixelSizeChooser from "../PixelSizeChooser";

import {IsolationBatchMetadata} from "../../redux/actions";
import {toFloat, toNumber} from "../../utils/parsers";
import {isNonNegative, isPositiveFloat, required} from "../../utils/validators";
import {UploaderContentState} from "../Uploader";

interface UploaderFormSectionProps {
    batchIds: string[];
    disabledFields: string[];
    erasedFields: string[];
    formModel: string;
    hiddenFields: string[];
    initialValues?: {batchId: string|number};
    onStateChanged: (state: UploaderContentState) => void; // TODO currently unused
    onSubmit: (batch: IsolationBatchMetadata) => void;
}

interface UploaderFormSectionState {
    sizeChooserOpen: boolean;
}

class UploaderFormSection extends React.Component<UploaderFormSectionProps, UploaderFormSectionState> {
    constructor(props: UploaderFormSectionProps) {
        super(props);
        this.state = {
            sizeChooserOpen: false
        }
    }

    private formDispatch: any;

    public static defaultProps = {
        batchIds: [],
        disableDonorCode: false,
        hiddenFields: [],
        onStateChanged: noop,
        onSubmit: () => {return;}
    };

    private attachFormDispatch = (formDispatch: any) => {
        this.formDispatch = formDispatch;
    };

    private openSizeChooser = () => {
        this.setState({sizeChooserOpen: true});
    };

    private closeSizeChooser = () => {
        this.setState({sizeChooserOpen: false});
    };

    private onSizeChooserSubmit = (pixelSize: number) => {
        this.formDispatch(actions.change(`${this.props.formModel}.umPerPx`, pixelSize.toFixed(3)));
        this.closeSizeChooser();
    };

    private isUniqueBatchId = (value: string) => {
        if (this.props.initialValues !== undefined && this.props.initialValues.batchId === value) {
            return true;
        }

        return !this.props.batchIds.includes(value);
    };

    private mapProps = {
        className: (props: any) => {
            const {valid, touched} = props.fieldValue;
            return classNames({error: !valid, valid, touched})
        }
    };

    private onSubmit = (data: any) => {
        const payload = {
            batchId: data.batchId as string,
            camera: data.camera as string,
            dilution: parseInt(data.dilution, 10),
            microscope: data.microscope as string,
            minIsletSize: parseInt(data.minIsletSize, 10),
            objective: parseFloat(data.objective),
            operator: data.operator as string,
            pelletVolumeMl: parseFloat(data.pelletVolumeMl),
            sessionCode: data.sessionCode as string,
            umPerPx: parseFloat(data.umPerPx),
            initialBatchId: ""
        };

        if (this.props.initialValues && this.props.initialValues.batchId) {
            payload.initialBatchId = this.props.initialValues.batchId.toString()
        }

        this.props.onSubmit(payload);
    };

    public componentDidMount() {
        this.resetForm();
    }

    public componentDidUpdate() {
        this.resetForm();
    }

    private resetForm() {
        Object.entries(this.props.initialValues || {}).forEach(([fieldName, value]) => {
            if (["umPerPx", "minIsletSize", "dilution", "pelletVolumeMl", "objective"].includes(fieldName) && isNaN(value as number)) {
                return;
            }

            if (value === null || String(value).length === 0) {
                return;
            }

            this.formDispatch(actions.change(`${this.props.formModel}.${fieldName}`, String(value)));
        });

        this.props.erasedFields.forEach(fieldName => {
            this.formDispatch(actions.change(`${this.props.formModel}.${fieldName}`, ""));
        })
    }

    public render() {
        return (
            <div className="uploader-form-section row">
                <ModalWindow isOpen={this.state.sizeChooserOpen} onRequestClose={this.closeSizeChooser}>{requestClose =>
                    <PixelSizeChooser onSubmit={this.onSizeChooserSubmit}/>
                }</ModalWindow>
                <h2>Batch Properties</h2>

                <Form model={this.props.formModel} onSubmit={this.onSubmit} getDispatch={this.attachFormDispatch}>

                    <div className="col-4">
                        <h3><span>1</span> Image specification</h3>
                        <div className="label-detail-wrap">
                          <label
                              title="The pixel size used during the image acquisition. Note that the corresponding islet images must be acquired at the same settings (microscope, camera, magnification).">
                              Pixel size [μm/px]:
                              <Control.text parser={toFloat} validators={{required, isPositiveFloat}} model=".umPerPx"
                                            placeholder={"1"} mapProps={this.mapProps}/>
                          </label>
                          <span className="label-detail">
                              (<ClickableLink onClick={this.openSizeChooser}
                                              title="You can select the pixel size from your uploaded stage micrometer.">Upload stage micrometer</ClickableLink>)
                          </span>
                        </div>

                        <label
                            title="Islets smaller than this threshold will be excluded from contour and ellipse visualization.">
                            Minimum islet size [μm]:
                            <Control.text parser={toNumber} validators={{required, isNonNegative}}
                                          model=".minIsletSize"
                                          placeholder="1" mapProps={this.mapProps}/>
                        </label>

                        {!this.props.hiddenFields.includes("dilution") && <label
                            title="Used to calculate the total islet count in the original flask.">
                            Sample dilution:
                            <Control.text parser={toNumber} validators={{required, isNonNegative}}
                                          model=".dilution"
                                          placeholder="1" mapProps={this.mapProps}/>
                        </label>}

                        {!this.props.hiddenFields.includes("pelletVolumeMl") && <label
                            title="Pellet in the original flask after centrifugation.">
                            Pellet volume [ml]:
                            <Control.text parser={toFloat} validators={{required, isPositiveFloat}}
                                          model=".pelletVolumeMl"
                                          defaultValue="" 
                                          placeholder="1.0" mapProps={this.mapProps}/>
                        </label>}

                    </div>

                    <div className="col-4 acquisition-col">

                        <h3><span>2</span>Image acquisition</h3>

                        <label className="not-required">
                            Microscope:
                            <Control.text model=".microscope" placeholder="CKX41" mapProps={this.mapProps}/>
                        </label>

                        <label className="not-required">
                            Camera:
                            <Control.text model=".camera" placeholder="Infinity1" mapProps={this.mapProps}/>
                        </label>


                        <label className="not-required">
                            Objective:
                            <Control.text model=".objective" parser={toFloat} validators={{isPositiveFloat}}
                                          placeholder="1.25" mapProps={this.mapProps}/>
                        </label>

                    </div>

                    <div className="col-4">
                        <h3><span>3</span>Session specification</h3>

                        <label
                            title="Operator's initials is printed in the final report pdf, which is inserted into documentation of the Clinical islet isolation."
                            className="not-required">
                            {"Operator's initials"}:
                            <Control.text model=".operator" placeholder="dh" mapProps={this.mapProps}/>
                        </label>

                        <label
                            title="Session code typically refers to a single isolation procedure from a single donor.">
                            Session code:
                            <Control.text model=".sessionCode" validators={{required}} placeholder="iz180712d0"
                                          disabled={this.props.disabledFields.includes("sessionCode")}
                                          mapProps={this.mapProps}/>
                        </label>

                        <label
                            title="Batch ID identifies the flask from which several replicate samples (uploaded images) were taken. Results obtained from several images included within the batch are be avereged. Results from several batches (eg. Cobe1-high purity, ..., Cobe2-low purity) are summed up to produce the total islet count per session (donor).">
                            Batch ID:
                            <Control.text model=".batchId" placeholder="Cobe1 High Purity"
                                          validators={{required, idUnique: this.isUniqueBatchId}}
                                          mapProps={this.mapProps} defaultValue=""/>
                        </label>

                        <input type="submit" value="Submit batch"/>

                    </div>
                </Form>

                <div className="required-form-label">
                    <span>*</span> Required fields
                </div>

            </div>
        );
    }
}

export default UploaderFormSection;
