import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";

import LoadingScreen from "../components/LoadingScreen";
import {AsyncRequestState, imageDownloadRequested} from "../redux/actions";

interface ImageFetcherProps {
    downloadStatus: AsyncRequestState;
    imageData?: string;
    imageId: string;
    imageRenderer: (imageBase64: string) => ReactNode,
    onDownloadRequested: () => void;
}

class ImageFetcher extends React.Component<ImageFetcherProps> {
    public componentDidMount() {
        this.props.onDownloadRequested();
    }

    public componentDidUpdate() {
        this.props.onDownloadRequested();
    }

    public render() {
        if (this.props.downloadStatus === AsyncRequestState.NONE) {
            return <LoadingScreen text="Preparing to load image" />;
        }

        if (this.props.downloadStatus === AsyncRequestState.EXPIRED) {
            return <div>The image is not available anymore</div>;
        }

        if (this.props.downloadStatus === AsyncRequestState.PENDING) {
            return <LoadingScreen text="Image loading" />;
        }

        if (this.props.downloadStatus === AsyncRequestState.FAILED ) {
            return <div>There was an error</div>;
        }

        return this.props.imageRenderer(this.props.imageData!);
    }
}

export default connect(
    (state: any, props: Partial<ImageFetcherProps>) => ({
        downloadStatus: state.imageDownloadStatus[props.imageId!] || AsyncRequestState.NONE,
        imageData: state.imageIdCache[props.imageId!]
    }),
    (dispatch: Dispatch, props: Partial<ImageFetcherProps>) => ({
        onDownloadRequested: () => {
            dispatch(imageDownloadRequested({imageId: props.imageId!}));
        }
    })
)(ImageFetcher);