import React from "react";

import {isEqual, noop, partition, uniq, zip, zipObject} from "lodash"

import Pix2PixUploadItem, {Pix2PixUploadMetadata} from "./Pix2PixUploadItem";
import {UploadListRenderer} from "./UploaderImagesSection";

import {FormRenderer, ImagesSectionRenderer, UploaderContentState, UploaderSubmitHandler} from "../Uploader";

import {Configuration, DefaultApi} from "../../api";
import UploaderFormSection from "../../containers/Pix2PixUploaderFormSection";
import Uploader from "../../containers/Uploader";
import UploaderImagesSection from "../../containers/UploaderImagesSection";
import {API_URL, MAX_UPLOAD_RESOLUTION} from "../../env";
import {Pix2PixBatchMetadata, Pix2PixMaskData} from "../../redux/actions";
import {GT_REGEXP} from "./GTRegExp";

interface Pix2PixUploaderProps extends React.Props<Pix2PixUploader> {
    disabledFields: string[];
    erasedFields: string[];
    existingFilenames: string[];
    initialValues?: SubmittedBatch;
    onError: (title: string, text: string) => void;
    onStateChanged: (state: UploaderContentState) => void;
    onSubmit: (batch: SubmittedBatch) => void;
}

export interface Pix2PixMetadataItem {
    imageId?: string;
    isGroundTruth: boolean;
    name: string;
    umPerPx?: number;
}

export interface SubmittedBatch {
    batchMetadata: Pix2PixBatchMetadata;
    imagesMetadata: Array<Pix2PixMaskData>;
}

class Pix2PixUploader extends React.Component<Pix2PixUploaderProps> {
    private api = new DefaultApi(new Configuration({basePath: API_URL}));

    public static defaultProps = {
        onStateChanged: noop
    };

    private makeFormRenderer = (props: Pix2PixUploaderProps): FormRenderer<Pix2PixBatchMetadata> => {
        return (onSubmit, onChange) => <UploaderFormSection
            initialValues={this.props.initialValues !== undefined ? this.props.initialValues.batchMetadata : undefined}
            onStateChanged={onChange}
            onSubmit={onSubmit}
            disabledFields={props.disabledFields}
            erasedFields={props.erasedFields}
            hiddenFields={["dilution", "pelletVolumeMl"]}
        />;
    };

    private static stripExtension(fileName: string) {
        if (!fileName.includes(".")) {
            return fileName;
        }

        return fileName.substring(0, fileName.lastIndexOf("."));
    }

    private static fileNameStem(fileName: string) {
        return this.stripExtension(fileName.replace(GT_REGEXP, ""));
    }

    private static isGroundTruthMask(maskName: string, imageName: string): boolean {
        if (this.fileNameStem(maskName) !== this.fileNameStem(imageName)) {
            return false;
        }

        return !GT_REGEXP.test(imageName) && GT_REGEXP.test(maskName);
    }

    /**
     * Compare two file names. User masks (ground truth) should go right after the images.
     */
    private static compareFileNames(nameA: string, nameB: string) {
        const stemA = this.fileNameStem(nameA);
        const stemB = this.fileNameStem(nameB);

        if (stemA === stemB) {
            // File A is a mask for file B
            if (this.isGroundTruthMask(nameA, nameB)) {
                return 1;
            }

            // File B is a mask for file A
            if (this.isGroundTruthMask(nameB, nameA)) {
                return -1;
            }

            return nameA.localeCompare(nameB);
        }

        return stemA.localeCompare(stemB);
    }

    private findInitialMetadata(name: string): Pix2PixUploadMetadata | undefined {
        if (this.props.initialValues === undefined) {
            return undefined;
        }

        const meta = this.props.initialValues.imagesMetadata.find(i => i.name === name);

        if (meta === undefined) {
            return undefined;
        }

        return {
            umPerPx: meta!.umPerPx !== undefined ? String(meta!.umPerPx) : ""
        };
    }

    private uploadListRenderer: UploadListRenderer = items =>
        items.sort((itemA, itemB) => Pix2PixUploader.compareFileNames(itemA.name, itemB.name)).map((item, i, array) => {
            const isGT = GT_REGEXP.test(item.name);

            const image = i > 0
                ? array.slice(0, i).find(it => Pix2PixUploader.isGroundTruthMask(item.name, it.name))
                : undefined;

            return [
                <Pix2PixUploadItem fileName={item.name} key={item.name} done={item.uploadStatus.done}
                                   error={item.uploadStatus.error} initialState={this.findInitialMetadata(item.name)}
                                   imageMissing={isGT && image === undefined} selected={item.isSelected}
                                   isGroundTruth={isGT} />,
                item.name
            ] as [React.ReactElement<any>, string];
        });

    private imagesSectionRenderer: ImagesSectionRenderer<Pix2PixMetadataItem> = (onChange) => {
        const prefilledImages: Array<Pix2PixMetadataItem & {imageId: string}> = [];

        if (this.props.initialValues !== undefined) {
            this.props.initialValues.imagesMetadata.forEach(item => {
                if (prefilledImages.find(i => i.imageId === item.imageId) === undefined) {
                    prefilledImages.push({
                        imageId: item.imageId,
                        isGroundTruth: false,
                        name: item.name,
                        umPerPx: item.umPerPx
                    })
                }

                prefilledImages.push({
                    imageId: item.userMaskId,
                    isGroundTruth: true,
                    name: item.userMaskName,
                    umPerPx: undefined
                });
            })
        }

        return <UploaderImagesSection
            buttonText="Add images + segmentations (image_GT)"
            existingFilenames={this.props.existingFilenames}
            hint={<>
                <h4>Upload hint</h4>
                <p>Original images are uploaded together with one or more standard  islets' segmentations created by trusted
                   method of the user. The original image will be segmented by the algorithms used in Clinical islet isolation and the resulting
                   images will be compared to those uploaded by user. Acceptable formats include png, jpg,
                   tiff. Requirements for the standard: B&W image (black - non-islet tissue, white - islets), structured file name 
                   comprised of the original image name and extension _GT, which can be numbered (_GT01, _GT02,..). </p>
            </>}
            imageAttributeNames={["Pixel size"]}
            imageCountRenderer={(droppedMetadata: Array<Pix2PixMetadataItem>, prefilledMetadata: Array<Pix2PixMetadataItem>) => {
                const [masks, images] = partition(droppedMetadata.concat(prefilledMetadata), meta => meta.isGroundTruth);
                return `${images.length} uploaded images and ${masks.length} GTs.`
            }}
            onImagesChange={onChange}
            prefilledImages={prefilledImages || undefined}
            uploadListRenderer={this.uploadListRenderer}
            maxImageSizeNote={<>
                <p>Maximum image size: {MAX_UPLOAD_RESOLUTION}&times;{MAX_UPLOAD_RESOLUTION}px.</p>
            </>}
        />;
    };

    private onSubmit: UploaderSubmitHandler<Pix2PixMetadataItem, Pix2PixBatchMetadata> =
        async (uploadIds, imagesMetadata, batchMetadata) => {
            if (imagesMetadata.length <= 1) {
                this.props.onError("Validation error", "Please, upload at least one image and one mask");
                return false;
            }

            imagesMetadata = imagesMetadata.sort((itemA, itemB) =>
                Pix2PixUploader.compareFileNames(itemA.name, itemB.name));

            let subjectImage: Pix2PixMetadataItem = imagesMetadata[0];

            if (subjectImage.isGroundTruth) {
                this.props.onError("Validation error", `There is no image for mask "${subjectImage.name}"`);
                return false;
            }

            const result: Pix2PixMaskData[] = [];
            let failed = false;

            imagesMetadata.slice(1).forEach((item, i) => {
                if (!item.isGroundTruth) {
                    if (!imagesMetadata[i].isGroundTruth) { // The index is shifted by one because of the slice
                        this.props.onError(
                            "Validation error",
                            `There is no mask for image "${imagesMetadata[i].name}"`
                        );
                        failed = true;
                    }

                    subjectImage = item;
                    return;
                }

                if (!Pix2PixUploader.isGroundTruthMask(item.name, subjectImage.name)) {
                    this.props.onError("Validation error", `There is no image for mask "${item.name}"`);
                    failed = true;
                    return;
                }

                result.push({
                    imageId: subjectImage.imageId || uploadIds[subjectImage.name],
                    name: subjectImage.name,
                    umPerPx: subjectImage.umPerPx,
                    userMaskId: item.imageId || uploadIds[item.name],
                    userMaskName: item.name
                });
            });

            if (failed) {
                return false;
            }

            const lastItem = imagesMetadata[imagesMetadata.length - 1];

            if (!lastItem.isGroundTruth) {
                this.props.onError("Validation error", `There is no mask for image "${lastItem.name}"`);
                return false;
            }

            try {
                const masksResponses = await Promise.all(result.map(image => this.api.imageInfo({imageId: image.userMaskId})));
                const usedImages = uniq(result.map(image => image.imageId));
                const imagesResponses = zipObject(
                    usedImages,
                    await Promise.all(usedImages.map(imageId => this.api.imageInfo({imageId})))
                );

                const nonGrayscaleMasks = zip(result, masksResponses)
                    .filter(([_, response]) => !response!.grayscale)
                    .map(([image, _]) => image!.userMaskName);

                const highResolutionResults = zip(result, masksResponses)
                    .filter(([_, response]) => response!.resolution.some(dim => dim > MAX_UPLOAD_RESOLUTION))
                    .map(([image, _]) => image!.userMaskName);

                const masksWithResolutionMismatch = zip(result, masksResponses)
                    .filter(([image, response]) => !isEqual(
                        imagesResponses[image!.imageId].resolution, // image resolution
                        response!.resolution // user mask resolution
                    ))
                    .map(([image, _]) => image!.userMaskName);

                if (nonGrayscaleMasks.length > 0) {
                    this.props.onError(
                        "Validation error",
                        "The following images are not grayscale: " + nonGrayscaleMasks.join(", ")
                    );

                    return false;
                } else if (masksWithResolutionMismatch.length > 0) {
                    this.props.onError(
                        "Validation error",
                        "The resolution of following masks does not match the resolution of their image: "
                            + masksWithResolutionMismatch.join(", ")
                    );

                    return false;
                } else if (highResolutionResults.length > 0) {
                    this.props.onError(
                        "Validation error",
                        `The resolution of following images is too large (larger than ${MAX_UPLOAD_RESOLUTION}px): `
                            + highResolutionResults.join(", ")
                    );

                    return false;
                } else {
                    this.props.onSubmit({batchMetadata, imagesMetadata: result});
                    return true;
                }
            } catch (e) {
                this.props.onError(
                    "Application error",
                    "There was an error while submitting data to the server"
                );
                return false;
            }
        };

    public render() {
        return <Uploader
            onStateChanged={this.props.onStateChanged}
            onSubmit={this.onSubmit}
            formRenderer={this.makeFormRenderer(this.props)}
            imagesSectionRenderer={this.imagesSectionRenderer}
        />;
    }
}

export default Pix2PixUploader;
