import {APP_VERSION, MODEL_VERSION} from "../env";
import React from "react";
import ntb from "../images/isletnet_app_ntb.png";
import networkImg from "../images/neural-network.png";
import appProcess from "../images/application-process.png";
import figuresImg from "../images/figures.png";
import logo from '../images/ikem_logo.png';
import validationImg from '../images/vyrez1.png';
import validationImg2 from '../images/vyrez1_p2p.png';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import {Link as ScrollLinkComponent} from 'react-scroll';

import example1 from '../images/islet-examples/1.jpg';
import example2 from '../images/islet-examples/2.jpg';
import example3 from '../images/islet-examples/3.jpg';
import example4 from '../images/islet-examples/4.jpg';
import example5 from '../images/islet-examples/5.jpg';
import example6 from '../images/islet-examples/6.jpg';
import example7 from '../images/islet-examples/7.jpg';
import example8 from '../images/islet-examples/8.jpg';
import example9 from '../images/islet-examples/9.jpg';
import example10 from '../images/islet-examples/10.jpg';
import example11 from '../images/islet-examples/11.png';
import example12 from '../images/islet-examples/12.png';
import example13 from '../images/islet-examples/13.jpg';
import example14 from '../images/islet-examples/14.jpg';
import example15 from '../images/islet-examples/15.jpg';
import example16 from '../images/islet-examples/16.jpg';
import example17 from '../images/islet-examples/17.jpg';
import example18 from '../images/islet-examples/18.jpg';
import example19 from '../images/islet-examples/19.jpg';
import example20 from '../images/islet-examples/20.jpg';
import Icon from "../components/Icon";

const ScrollLink = (props) => <ScrollLinkComponent href={'#' + props.to} smooth {...props} />;

const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
    <span {...props}>{children}</span>
);

const About = (props) => {
    return (
        <div id="top" className="landing">

            <div className="row full">
                <div className="col-6">
                    <h1 className="hero-text-title">IsletNet</h1>
                    <ul className="list">
                        <li>Automated web service dedicated to the assessment of microscopic images<br/>of dithizone-stained isolated pancreatic islets</li>
                        <li>Open collaborative project to facilitate inter-laboratory standardization</li>
                        <li>Inbuilt tools allow users to validate <i>IsletNet</i> algorithms</li>
                    </ul>
                    <p className="hero-text">
                        Users are encouraged to share their images with the developers using a checkbox on the result
                        screen. Shared images are used for periodic training to continuously improve the service. Other
                        than that, no user data is stored.
                        <br/><br/>

                        Please, send your comments, questions or information requests to <a href="mailto:info@isletnet.com">info@isletnet.com</a>.
                        <br/><br/>

                        A starter set of images is available <a target="_blank" href="https://owncloud.ikem.cz/index.php/s/MMURbfW1eeRi5M0" rel="noopener noreferrer">here</a>

                        <br/><br/><br/>

                        <Link to="/isolation"><button>Upload images</button></Link>
                    </p>
                </div>
                <div className="col-6">
                    <p style={{ "textAlign": "center" }}>
                        <img className="hero-text-img" alt="Screenshot" src={ntb} />
                    </p>
                </div>
            </div>

            <div className="full-section-wrapper network">
                <div className="svg-separator-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="50" fill="#f2f2f2" viewBox="0 0 100 102" preserveAspectRatio="none">
                        <path d="M0 0 L50 100 L100 0 Z"/>
                    </svg>
                </div>
                <div className="section row">
                    <div className="col-6">
                        <p style={{ marginTop: "80px" }}>
                            <img alt="Application process" src={appProcess} width={450}/>
                        </p>
                    </div>
                    <div className="col-6">
                        <h2>Clinical Islet Isolation</h2>
                        <p>This application is designed to assist in the process of isolation of human pancreatic islets.
                            <br/><br/>
                            Multiple samples are taken from each purity fraction to compensate for sampling error.
                            The corresponding microscopic images are uploaded to the application in <i>batches</i>.
                            Results obtained from the replicate samples within a <i>batch</i> are averaged.
                            <br/><br/>
                            As the isolation proceeds, multiple <i>batches</i> are added to the session. The final
                            result of the <i>session</i> can be viewed in and downloaded from <i>Overall Summary</i>.
                            Content and parameters of <i>batches</i> can be edited throughout the <i>session</i>.
                            <br/><br/>
                            For the sake of objectivity, all processes in <i>IsletNet</i> are fully automated and
                            require no assistance from the user. However, the users are expected to zoom in and review
                            the quality of islet contours in order to exclude any artifacts caused by incorrect
                            segmentation from the final result. The excluded images are then used for re-training the
                            <i>IsletNet</i> neural network.
                        </p>
                    </div>
                </div>
            </div>

            <div className="svg-separator-bottom">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="50" fill="#ffffff" viewBox="0 0 100 102" preserveAspectRatio="none">
                    <path d="M0 0 L50 100 L100 0 Z" />
                </svg>
            </div>

            <div className="full-section-wrapper">

                <div className="section row">
                    <div className="col-6">
                        <h2>Validation Tools</h2>
                        <p>These tools allow users to validate <i>IsletNet</i> algorithms.</p>

                        <h4 className="small-title" style={{ "marginTop": "0" }}>Pixel-to-Pixel Comparison</h4>
                        <p>This application is designed to validate the automatic detection of islets against a standard provided by user. It is assumed that user has some images segmented by a trusted method. The comparison requires to upload pairs of the <i>original images</i> and <i>standard segmentations</i>, i.e. layer containing segmented islets. <i>IsletNet</i> will automatically detect islets on the original image and will generate numerical and graphic comparison to the standard.
                            <br/><br/>
                            Illustration: IsletNet contour of an islet in the original image (left), and the corresponding validation map (right). The map visualizes the pixels assigned to this islet by the IsletNet (red+white) and by the trusted method of the user (red+green).
                        </p>

                        <h4 className="small-title" style={{ "marginTop": "0" }}>My Segmentations</h4>
                        <p>This application is designed to validate the algorithm for calculation of islet indices (i.e. count, axes, size, volume) from the contours of the automatically detected islets. User can upload <i>standard segmentations</i> to compare known values of islet indices to the values given by <i>IsletNet.</i></p>
                        <p>A starter set of images is available <a target="_blank" href="https://owncloud.ikem.cz/index.php/s/MMURbfW1eeRi5M0" rel="noopener noreferrer">here</a></p>
                    </div>
                    <div className="col-6">
                        <div className="row legend-wrap-home">
                            <div className="col-6">
                                <img alt="Neural network" src={validationImg} style={{"maxHeight" : "284px"}} />
                            </div>
                            <div className="col-6">
                                <img alt="Neural network" src={validationImg2} style={{"maxHeight" : "284px"}} />
                            </div>

                            <div className="image-legend home row">
                                <div className="col-6">
                                    <div className="legend-description">
                                        <span className="legend-circle color-red" /> True positive pixels
                                    </div>
                                    <div className="legend-description">
                                        <span className="legend-circle color-green" /> False negative pixels
                                    </div>
                                    <div className="legend-description">
                                        <span className="legend-circle color-white" /> False positive pixels
                                    </div>
                                    <div className="legend-description">
                                        <span className="legend-circle color-blue" /> True negative pixels
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="legend-description">
                                        <span className="legend-circle color-red" /> + <span className="legend-circle color-green" /> Islets by standard
                                    </div>
                                    <div className="legend-description">
                                        <span className="legend-circle color-red" /> + <span className="legend-circle color-white" /> Islets by IsletNet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div id="neural-network" className="full-section-wrapper network">
                <div className="svg-separator-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="50" fill="#f2f2f2" viewBox="0 0 100 102" preserveAspectRatio="none">
                        <path d="M0 0 L50 100 L100 0 Z"/>
                    </svg>
                </div>
                <div className="section row no-bottom-padding">
                    <div className="col-6">
                        <p style={{ "textAlign": "center", "marginTop": "70px" }}>
                            <img alt="Neural network" src={networkImg} width={500}/>
                        </p>
                    </div>
                    <div className="col-6 padding-right">
                        <h2>Deep Neural Networks</h2>
                        <p><i>IsletNet</i> is based on deep learning technology.
                            <br/><br/>
                            Deep learning (also known as deep structured learning or hierarchical learning) is part of a
                            broader family of machine learning methods based on learning data representations, as
                            opposed to task-specific algorithms.
                            <br/><br/>
                            The <i>IsletNet</i> network is dedicated to detection of islets and exocrine tissue in microscopic images.
                            <br/><br/>
                            Accepted image formats include jpg, png, tif, bmp.
                            <i>IsletNet</i> performance was tested for individual images up to resolution 6800x6100 px,
                            and size 118 MB.
                            Batch results for four images between 2048x1536-3840x2748 px, and 1-25 MB can be expected
                            within 1-2 minutes. Visualization on user&#39;s monitor depends on the image size and internet
                            connection.
                            Images containing spread out islets not touching one another are preferable. Colour scheme
                            similar to the images used for training (examples below) is required.
                        </p>
                        <p>Examples of color variety are shown below.</p>
                    </div>
                </div>
                <div className="section row no-bottom-padding no-top-padding">
                  <div className="col-6">
                    Images used to train the network were kindly provided by:
                    <ul className="list">
                        <li><strong>David Habart</strong>, Diabetes center, Institute for Clinical and Experimental
                            Medicine Prague, Czech Republic</li>
                        <li><strong>Andrew Friberg</strong>, Department of Immunology, Genetics and Pathology,
                            Uppsala University, Sweden</li>
                        <li><strong>Hanne Scholz</strong>, Institute for Surgical Research, Oslo University
                            Hospital, Norway</li>
                        <li><strong>Valéry Gmyr</strong>, Translational Research in Diabetes, University of Lille,
                            INSERM, France</li>
                    </ul>
                  </div>
                  <div className="col-6 padding-right">
                  <ul className="list">
                      <li><strong>Josh Wilhelm</strong>, Schulze Diabetes Institute, University of Minnesota,
                          Minneapolis, United States</li>
                      <li><strong>Domenico Bosco</strong>, Department of Surgery, University of Geneva and Geneva
                      University Hospitals, Switzerland</li>
                      <li><strong>Jason Doppenberg</strong>, Department of Internal Medicine, Leiden University
                      Medical Center, The Netherlands</li>
                      <li><strong>Karolina Golab</strong>, Transplant Institute, Biological Sciences, University
                          of Chicago, United States</li>
                  </ul>
                  </div>
                </div>

                <div className="section row no-bottom-padding no-top-padding">
                        <Slider slidesToShow={4} slidesToScroll={1} initialSlide={0} autoplay={true} autoplaySpeed={4000}
                                infinite={false} dots={true} className="example-slider" slide="img"
                                customPaging={() => <button><Icon name="circle" /></button>}
                                prevArrow={<SlickButtonFix><button><Icon name="angle-left"/></button></SlickButtonFix>}
                                nextArrow={<SlickButtonFix><button><Icon name="angle-right"/></button></SlickButtonFix>}>
                            <img src={example1} alt="" />
                            <img src={example2} alt="" />
                            <img src={example3} alt="" />
                            <img src={example4} alt="" />
                            <img src={example5} alt="" />
                            <img src={example6} alt="" />
                            <img src={example7} alt="" />
                            <img src={example8} alt="" />
                            <img src={example9} alt="" />
                            <img src={example10} alt="" />
                            <img src={example11} alt="" />
                            <img src={example12} alt="" />
                            <img src={example13} alt="" />
                            <img src={example14} alt="" />
                            <img src={example15} alt="" />
                            <img src={example16} alt="" />
                            <img src={example17} alt="" />
                            <img src={example18} alt="" />
                            <img src={example19} alt="" />
                            <img src={example20} alt="" />
                        </Slider>
                </div>
            </div>

            <div className="svg-separator-bottom">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="50" fill="#ffffff" viewBox="0 0 100 102" preserveAspectRatio="none">
                    <path d="M0 0 L50 100 L100 0 Z" />
                </svg>
            </div>

            <div id="definitions" className="full-section-wrapper">

                <div className="section row">
                <div className="col-6 padding-right">
                    <h1>Definitions and Formulas</h1>
                    <p>
                        Pixels in original images of dithizone-stained isolated pancreatic islets
                        (<span className="primary-color">Fig 1a</span>) are automatically partitioned between
                        islets, non-islet tissue, and background classes producing segmented images. The segmented
                        images (<span className="primary-color">Fig 1b</span>) are used to estimate the
                        islet count, purity, and volume.
                    </p>

                    <img className="figure" alt="Figures" src={figuresImg} />

                    <p>
                        Dimensions measured in pixels are converted to micrometers using <i>Pixel size [µm/px]</i>.
                        It can be set for entire batch of images or for individual images within batch. A tool is
                        provided for determination of pixel size from uploaded image of stage micrometer.
                    </p>

                    <p>
                        Images of islets arranged with no close contact between them are preferable. An attempt
                        is made to automatically separate touching islets using a pixel size-dependent algorithm.
                    </p>

                    <h3 className="small-title">Islet count</h3>
                    <p>
                        <i>Minimum islet size [µm]</i> is set by user to exclude from counting all islets with
                        diameter &lt;10 pixels (e.g.: if pixel size is 5 µm/px, minimum islet size should be ≥50 µm).
                        The algorithm for separation between touching islets also requires correct <i>Pixel size</i>.
                        Segmentation contours (<span className="primary-color">Fig 1c</span>) for islets below the
                        threshold are not visualized.
                    </p>

                    <h3 className="small-title">Purity [%]</h3>
                    <p>
                        <i>Purity from area</i> is estimated using the area of islets [Aislets] and the area of
                        exocrine tissue [Aexo] with the formula P = Aislets/(Aexo+Aislets, (Ref. 1).
                    </p>
                    <p>
                        <i>Purity from volume</i> is determined from the volumes of islets and exocrine tissue using
                        <i>spinacle</i> 3D model (under development, unpublished). Sphere model cannot be used by
                        <i>IsletNet</i> because large pieces of exocrine tissue would lead to spurious results.
                    </p>

                    <h3 className="small-title">Islet size index</h3>
                    <p>
                        Islet size index is an estimate of the islet size predominating in preparation (Ref. 7). It is
                        calculated as the ratio between the total islet volume as determined from the original
                        conversion table (3) and the total islet count.
                    </p>

                    <h3 className="small-title">Islet categories</h3>
                    <p>
                        Individual islets are classified as <i>free</i> or <i>embedded/mantled</i> using an improved
                        formula from (Ref. 8). Embedded/mantled islet has &gt;50% of its circumference surrounded by
                        exocrine tissue of a comparable or larger size. This function is inactive when the exocrine
                        tissue comprises more than half of the image area.
                    </p>

                </div>
                <div className="col-6 figures-col-right">

                    <h3 className="small-title">Islet volume</h3>
                    <p>
                        <i>From conversion table</i>
                    </p>
                    <p>
                        <i>Islet size [µm]</i> is estimated for individual islets from <i>islet area</i> using the
                        formula d=2√(S/π, Ref. 1,2). Individual islets are assorted into one of the size categories
                        in the conversion table as originally defined by C. Ricordi (Tab 1, Ref. 3). Each size category
                        [µm] is associated with a volume expressed in units of islet equivalents [IE] (1 IE = 1.767145 nL).
                    </p>

                    <table className="islet-categories" style={{"minWidth":"100%"}}>
                        <thead>
                            <tr>
                                <th>Islet diameter range (&#956;)</th>
                                <th>Mean volume (&#956;<sup>3</sup>)</th>
                                <th>Conversion into islets of 150&#956; diameter (Ref. 3) [IE]</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>50-100</td>
                                <td>294,525</td>
                                <td>n / 6.00</td>
                            </tr>
                            <tr>
                                <td>100-150</td>
                                <td>1,145,373</td>
                                <td>n / 1.50</td>
                            </tr>
                            <tr>
                                <td>150-200</td>
                                <td>2,977,968</td>
                                <td>n &#215; 1.7</td>
                            </tr>
                            <tr>
                                <td>200-250</td>
                                <td>6,185,010</td>
                                <td>n &#215; 3.5</td>
                            </tr>
                            <tr>
                                <td>250-300</td>
                                <td>11,159,198</td>
                                <td>n &#215; 6.3</td>
                            </tr>
                            <tr>
                                <td>300-350</td>
                                <td>18,293,231</td>
                                <td>n &#215; 10.4</td>
                            </tr>
                            <tr>
                                <td>350-400</td>
                                <td>27,979,808</td>
                                <td>n &#215; 15.8</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>
                        For comparison, islet volumes are also estimated using other conversion tables (2,3,4,5).
                    </p>

                    <table className="islet-categories-other" style={{"minWidth":"100%"}}>
                        <thead>
                            <tr>
                                <th>Islet diameter range (&#956;)</th>
                                <th>Buchwald (Ref. 4) [IE]</th>
                                <th>Kissler (Ref. 2) [IE]</th>
                                <th>Ricordi, long (Ref. 5) [IE]</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>50-100</td>
                                <td>0.13</td>
                                <td>0.167</td>
                                <td>n / 6.00</td>
                            </tr>
                            <tr>
                                <td>100-150</td>
                                <td>0.58</td>
                                <td>0.667</td>
                                <td>n / 1.50</td>
                            </tr>
                            <tr>
                                <td>150-200</td>
                                <td>1.55</td>
                                <td>1.685</td>
                                <td>n &#215; 1.7</td>
                            </tr>
                            <tr>
                                <td>200-250</td>
                                <td>3.27</td>
                                <td>3.5</td>
                                <td>n &#215; 3.5</td>
                            </tr>
                            <tr>
                                <td>250-300</td>
                                <td>5.96</td>
                                <td>6.315</td>
                                <td>n &#215; 6.3</td>
                            </tr>
                            <tr>
                                <td>300-350</td>
                                <td>9.83</td>
                                <td>10.352</td>
                                <td>n &#215; 10.4</td>
                            </tr>
                            <tr>
                                <td>350-400</td>
                                <td>17.6</td>
                                <td>15.833</td>
                                <td>n &#215; 15.8</td>
                            </tr>
                            <tr>
                                <td>400-450</td>
                                <td>-</td>
                                <td>22.750</td>
                                <td>n &#215; 23</td>
                            </tr>
                            <tr>
                                <td>450-500</td>
                                <td>-</td>
                                <td>-</td>
                                <td>n &#215; 32</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>
                        <i>From 3D islet model</i>
                    </p>
                    <p>
                        <i>Prolate spheroid</i> model (Ref. 1,6). Volume of individual islets is calculated as
                        V=(4/3)π a/2 (b/2)<sup>2</sup>, where a [µm] and b [µm] are the sizes of the major and minor
                        axes of the ellipse (Fig. 1e) fitted into the islet contours while having the same area as the
                        islet (Fig. 1d). The volume is calculated from µm in nL and then converted to islet equivalents
                        (1 IE = 1.767145 nL).
                    </p>
                    <p>
                        <i>Spinacle</i> model. Islet volume is calculated from an experimental formula (under
                        development, unpublished).
                    </p>
                    <p>
                        <i>Sphere</i> model. Islet volume is calculated as V=(4/3)π r<sup>3</sup>, where the radius (r)
                        is the same as the islet size estimated from islet area using the formula d=2√(S/π). The volume
                        is calculated from µm in nL and then converted to islet equivalents (1 IE = 1.767145 nL).
                    </p>

                    <h3 className="small-title">References:</h3>

                        <ol className="references-list row">
                          <div className="col-6">
                            <li>Niclauss N, et al: Transplantation, 2008: 1603–9;</li>
                            <li>Kissler H J, et al: Clinical transplantation, 2010: 236–242;</li>
                            <li>Ricordi C, et al: Acta Diabetol Lat. 27, 1990: 185–195;</li>
                            <li>Buchwald P, et al: Cell Transplantation, 2009: 1223–35;</li>
                          </div>
                          <div className="col-6">
                            <li>Ricordi C: Pancreas, 1991: 242–44;</li>
                            <li>Girman P, et al: Cell transplantation 2003: 129–133;</li>
                            <li>Lehmann R, et al: Diabetes, 2007: 594–603;</li>
                            <li>Wile, K J, et al: Transplantation Proceedings, 1997: 1974.</li>
                          </div>
                        </ol>

                </div>
                </div>
                <p style={{ "position": "relative", "bottom": "50px", "textAlign": "center" }}>Useful material related to IsletNet can be downloaded from <a target="_blank" href="https://owncloud.ikem.cz/index.php/s/MMURbfW1eeRi5M0" rel="noopener noreferrer">here</a>.</p>

            </div>

            <div id="feedback" className="full-section-wrapper footer">

                <div className="svg-separator-bottom">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="50" fill="#f2f2f2" viewBox="0 0 100 102" preserveAspectRatio="none">
                            <path d="M0 0 L50 100 L100 0 Z" />
                        </svg>
                    </div>

                <footer id="contact" className="section row">

                    <div className="col-6">
                        <h2>Navigation</h2>
                        <ul className="footer-nav-list">
                            <li>
                                <ScrollLink to="top">Back to the Top</ScrollLink>
                            </li>
                            <li>
                                <ScrollLink to="neural-network">Deep Neural Networks</ScrollLink>
                            </li>
                            <li>
                                <ScrollLink to="definitions">Definitions and Formulas</ScrollLink>
                            </li>
                            <li>
                                <Link to="/isolation">Clinical Islet Isolation</Link>
                            </li>
                            <li>
                                <Link to="/pix2pix">Pixel-to-Pixel Comparison</Link>
                            </li>
                            <li>
                                <Link to="/analysis">My Segmentations</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h2>Primary contact</h2>
                        <ul className="footer-nav-list">
                            <li>
                                <strong>David Habart</strong>
                            </li>
                            <li>
                                Diabetes Center
                            </li>
                            <li>
                                Institute for Clinical and Experimental Medicine
                            </li>
                            <li>
                                Videnska 1958, Prague 4, 140 00
                            </li>
                            <li>
                                Czech Republic
                            </li>
                            <li>
                                david.habart@ikem.cz
                            </li>
                        </ul>
                    </div>

                    {/* <div className="col-4">
                        <h2>Technical solution</h2>
                        <ul className="footer-nav-list">
                            <li>
                                <strong>Iterait a.s.</strong>
                            </li>
                            <li>
                                <a href="http://iterait.com" ><img height={15} src={cx_logo} alt="Iterait a.s."/></a>
                            </li>
                            <li>
                                U papírny 614/9, 170 00 Prague
                            </li>
                            <li>
                                Czech Republic
                            </li>
                            <li>
                                office@iterait.com
                            </li>
                        </ul>
                    </div> */}

                </footer>

                <div className="row footer-extension">
                    <div className="col-4">
                        <Link className="logo-wrap" to="/">
                            <img src={logo} className="logo" alt="logo"/>
                            <span>Current version: {APP_VERSION}<br/>
                            Last network training: {MODEL_VERSION}</span>
                        </Link>
                    </div>
                    <div className="col-4 copyright">© 2023 IsletNet - All rights reserved</div>
                    <div className="col-4 authors">
                        Developed by <a target="_blank" href="https://mild.blue/" rel="noopener noreferrer">Mild Blue s.r.o. </a>
                        (originally by <a target="_blank" href="http://iterait.com" rel="noopener noreferrer">Iterait a.s.</a>)<br/>
                        Designed by <a target="_blank" href="https://www.jakubkaspar.com/" rel="noopener noreferrer">Jakub Kašpar</a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default About;
