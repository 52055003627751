import React from "react";

import {noop} from "lodash";

import AnalysisBatchResults from "../../containers/results/AnalysisBatchResultContainer";
import {BatchResultListProps, ResultComponentProps} from "./BatchResultList";
import ImageDetailsIcon from "./ImageDetailsIcon";
import ResultsNavigation from "./ResultsNavigation";
import {AnalysisBatchResult, AnalysisItemResult} from "./types";

import AnalysisMaskDetails from "../../containers/results/AnalysisMaskDetails";
import AnalysisUploader from "../../containers/upload/AnalysisUploader";
import {SubmittedBatch} from "../upload/AnalysisUploader";

import BatchResultList from "../../components/results/BatchResultList";
import TruncatedText from "../TruncatedText";

class ResultContentRenderer extends React.Component<ResultComponentProps<AnalysisBatchResult>> {
    private renderItem = (batch: AnalysisBatchResult) => (item: AnalysisItemResult, i: number) => {
        return <AnalysisMaskDetails key={i} maskStatistics={item.result.userMaskStatistics} imageIndex={i}
                                    batchMetadata={batch.metadata}
                                    included={false} onExclude={noop} onInclude={noop} /> // TODO these props are not actually necessary
    };

    private renderItemHeader = (item: AnalysisItemResult, i: number) => <React.Fragment>
        <TruncatedText text={item.name} icon={<ImageDetailsIcon key={i} included={item.included} />} />
    </React.Fragment> ;

    public render() {
        return <><div className="col-3">
            <ResultsNavigation<AnalysisItemResult> onSelect={this.props.onSelect}
                                                   batchId={this.props.batch.metadata.batchId}
                                                   items={this.props.batch.items} selectedItem={this.props.selectedItem}
                                                   itemRenderer={this.renderItem(this.props.batch)}
                                                   itemHeaderRenderer={this.renderItemHeader} />
        </div>
            <div className="col-9">
                <AnalysisBatchResults batchId={this.props.batch.metadata.batchId} items={this.props.batch.items}
                                      selectedItem={this.props.selectedItem} tabState={this.props.tabState}
                                      onSelect={this.props.onSelect} onTabStateChanged={this.props.onTabStateChanged}/>
            </div>
        </>
    }
}

const doNothing = () => {return};

const batchEditationTransformer = (batch: AnalysisBatchResult): SubmittedBatch => ({
    batchMetadata: batch.metadata,
    imagesMetadata: batch.items
});

interface AnalysisBatchResultListProps {
    batches: {
        [batchId: string]: AnalysisBatchResult;
    };
    onBatchSubmit: (batch: SubmittedBatch) => void;
    onRemoveBatch: (batchId: string) => void;
    onSummary: BatchResultListProps<AnalysisBatchResult, SubmittedBatch>["onSummary"];
}

const AnalysisBatchResultList: React.SFC<AnalysisBatchResultListProps> = (props) =>
    <BatchResultList<AnalysisBatchResult, SubmittedBatch>
        batchEditationTransformer={batchEditationTransformer}
        batches={props.batches}
        onBatchSubmit={props.onBatchSubmit}
        onExcludeBatch={doNothing}
        onIncludeBatch={doNothing}
        onRemoveBatch={props.onRemoveBatch}
        onSummary={props.onSummary}
        resultComponent={ResultContentRenderer}
        uploaderComponent={AnalysisUploader}
    />;


export default AnalysisBatchResultList;
