import React from "react";

import Collapsible from 'react-collapsible';

import ClickableLink from "../ClickableLink";
import Icon from "../Icon";
import TruncatedText from "../TruncatedText";

interface ItemShape {
    name: string;
}

interface ResultsNavigationProps<ItemType extends ItemShape> extends React.Props<ResultsNavigation<ItemType>> {
    batchId: string;
    itemHeaderRenderer: (item: ItemType, i: number) => React.ReactElement<any>;
    itemRenderer: (item: ItemType, i: number) => React.ReactNode;
    items: ItemType[];
    onSelect: (index: number, batchId: string) => void;
    selectedItem: number
    failedItemNames?: string[]
}

interface ResultsNavigationState {
    topPartOpen: boolean;
}

class ResultsNavigation<ItemType extends ItemShape> extends React.Component<
    ResultsNavigationProps<ItemType>,
    ResultsNavigationState
> {
    constructor(props: ResultsNavigationProps<ItemType>) {
        super(props);

        this.state = {
            topPartOpen: false
        };
    }

    public static defaultProps = {
        itemHeaderRenderer: (item: ItemShape) => item.name,
        onSelect: () => {return},
        selectedItem: 0
    };

    private next = () => {
        this.props.onSelect(this.props.selectedItem + 1, this.props.batchId);
    };

    private previous = () => {
        this.props.onSelect(this.props.selectedItem - 1, this.props.batchId);
    };

    private onSelect = (i?: string|number) => {
        if (i === undefined) {
            return;
        }

        this.props.onSelect(i as number, this.props.batchId);
        this.setState({
            topPartOpen: false
        });
    };

    private toggleTopPart = () => {
        this.setState(prevState => ({topPartOpen: !prevState.topPartOpen}));
    };

    public render() {
        const selectedItem = this.props.items[this.props.selectedItem];

        return (
            <div className="results-navigation-collapsible">
                <nav>
                    <ClickableLink onClick={this.toggleTopPart}>Image navigation</ClickableLink>
                    <div className="results-navigation-arrows">
                        <ClickableLink title="Previous image" onClick={this.previous}>
                            <Icon name="angle-up"/>
                        </ClickableLink>
                        <ClickableLink title="Next image" onClick={this.next}>
                            <Icon name="angle-down"/>
                        </ClickableLink>
                    </div>
                </nav>

                {
                    this.state.topPartOpen && this.props.items.slice(0, this.props.selectedItem).map((item, i) =>
                        <Collapsible key={i} handleTriggerClick={this.onSelect}
                                     accordionPosition={i}
                                     open={false} transitionTime={100}
                                     trigger={this.props.itemHeaderRenderer(item, i)}>
                            {this.props.itemRenderer(item, i)}
                        </Collapsible>
                    )
                }

                <Collapsible open={true} trigger={this.props.itemHeaderRenderer(selectedItem, this.props.selectedItem)}>
                    {this.props.itemRenderer(selectedItem, this.props.selectedItem)}
                </Collapsible>

                {
                    this.props.items.slice(this.props.selectedItem + 1).map((item, i) => {
                        i += this.props.selectedItem + 1;
                        return <Collapsible key={i} handleTriggerClick={this.onSelect}
                                            accordionPosition={i}
                                            open={false} transitionTime={100}
                                            trigger={this.props.itemHeaderRenderer(item, i)}>
                            {this.props.itemRenderer(item, i)}
                        </Collapsible>;
                    })
                }

                {
                    (this.props.failedItemNames || []).map(name => <Collapsible key={name} trigger={<TruncatedText text={name} icon={<Icon name="exclamation-circle"/>} />}>
                        The image could not be processed by the server. Please, check that it is a valid image file.
                    </Collapsible>)
                }

            </div>
        );
    }
}

export default ResultsNavigation;
