/* eslint-disable */
// tslint:disable
/**
 * Isletnet API
 *  # Islets  Rest API for [IsletNet](http://isletnet.com/) - an accurate neural network based tool for automated segmentation and analysis  of pancreatic (Langerhans) islets. For more information, see the IsletNet [User guide](http://isletnet.com/guide) or try the tool at  [www.isletnet.com](http://isletnet.com/).  Both IsletNet and this API serve for two main usecases:  ## Analysis   The main usecase.   User submits a request:   - images of the tissue AND/OR own segmentations (can be mixed) - user input data (information about batch of images)   The API returns the response containing: - automated segmentation of islets for each submitted image of the tissue  - histogram of islet size distribution for each image - results of the analysis (both detailed results per image and overall summary)  ## Validation  The usecase to assess the accuracy of one\'s own segmentation by submitting both the segmentation and the  original image. The API returns the comparison of his segmentation to the automated one, returning both  visual and statistical summary.  User submits a request:  - image&own_segmentation (mask) pairs  The API returns the response containing: - results of validation against the automated segmentation - visual comparison 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnalysisRequest,
    AnalysisRequestFromJSON,
    AnalysisRequestToJSON,
    AnalysisResult,
    AnalysisResultFromJSON,
    AnalysisResultToJSON,
    AnalysisSummaryRequest,
    AnalysisSummaryRequestFromJSON,
    AnalysisSummaryRequestToJSON,
    ComparisonRequest,
    ComparisonRequestFromJSON,
    ComparisonRequestToJSON,
    ComparisonResult,
    ComparisonResultFromJSON,
    ComparisonResultToJSON,
    ComparisonSummaryRequest,
    ComparisonSummaryRequestFromJSON,
    ComparisonSummaryRequestToJSON,
    EmailRequest,
    EmailRequestFromJSON,
    EmailRequestToJSON,
    FetchImageResponse,
    FetchImageResponseFromJSON,
    FetchImageResponseToJSON,
    FetchImagesRequest,
    FetchImagesRequestFromJSON,
    FetchImagesRequestToJSON,
    FetchImagesResponse,
    FetchImagesResponseFromJSON,
    FetchImagesResponseToJSON,
    ImageInfoResponse,
    ImageInfoResponseFromJSON,
    ImageInfoResponseToJSON,
    ImagesUploadRequest,
    ImagesUploadRequestFromJSON,
    ImagesUploadRequestToJSON,
    ImagesUploadResponse,
    ImagesUploadResponseFromJSON,
    ImagesUploadResponseToJSON,
    IsolationRequest,
    IsolationRequestFromJSON,
    IsolationRequestToJSON,
    IsolationResult,
    IsolationResultFromJSON,
    IsolationResultToJSON,
    IsolationSummaryRequest,
    IsolationSummaryRequestFromJSON,
    IsolationSummaryRequestToJSON,
    Pix2PixContoursResult,
    Pix2PixContoursResultFromJSON,
    Pix2PixContoursResultToJSON,
    Pix2PixRequest,
    Pix2PixRequestFromJSON,
    Pix2PixRequestToJSON,
    Pix2PixResult,
    Pix2PixResultFromJSON,
    Pix2PixResultToJSON,
    Pix2PixSummaryRequest,
    Pix2PixSummaryRequestFromJSON,
    Pix2PixSummaryRequestToJSON,
    ReportImageRequest,
    ReportImageRequestFromJSON,
    ReportImageRequestToJSON,
    SubmissionResponse,
    SubmissionResponseFromJSON,
    SubmissionResponseToJSON,
} from '../models';

export interface AnalysisResultRequest {
    requestId: string;
}

export interface AnalysisXlsxImagesRequest {
    body: AnalysisSummaryRequest;
}

export interface AnalysisXlsxIsletsRequest {
    body: AnalysisSummaryRequest;
}

export interface AnalysisZipRequest {
    body: AnalysisSummaryRequest;
}

export interface ComparisonCompleteZipRequest {
    body: ComparisonSummaryRequest;
}

export interface ComparisonPdfRequest {
    body: ComparisonSummaryRequest;
}

export interface ComparisonResultRequest {
    requestId: string;
}

export interface ComparisonXlsxRequest {
    body: ComparisonSummaryRequest;
}

export interface ComparisonZipRequest {
    body: ComparisonSummaryRequest;
}

export interface DeleteImageRequest {
    imageId: string;
}

export interface EmailResultsRequest {
    body: EmailRequest;
}

export interface GetImageRequest {
    imageId: string;
}

export interface GetImagesRequest {
    body: FetchImagesRequest;
}

export interface ImageInfoRequest {
    imageId: string;
}

export interface IsletswipeSendSessionRequest {
    body: IsolationSummaryRequest;
}

export interface IsolationPdfCompleteRequest {
    body: IsolationSummaryRequest;
}

export interface IsolationPdfSummaryRequest {
    body: IsolationSummaryRequest;
}

export interface IsolationResultRequest {
    requestId: string;
}

export interface IsolationXlsxImagesRequest {
    body: IsolationSummaryRequest;
}

export interface IsolationXlsxIsletsRequest {
    body: IsolationSummaryRequest;
}

export interface IsolationZipRequest {
    body: IsolationSummaryRequest;
}

export interface Pix2pixContoursRequest {
    requestId: string;
}

export interface Pix2pixResultRequest {
    requestId: string;
}

export interface Pix2pixXlsxValidationRequest {
    body: Pix2PixSummaryRequest;
}

export interface Pix2pixXlsxzipRequest {
    body: Pix2PixSummaryRequest;
}

export interface Pix2pixZipRequest {
    body: Pix2PixSummaryRequest;
}

export interface ReportImagesRequest {
    body: ReportImageRequest;
}

export interface SubmitAnalysisRequest {
    body: AnalysisRequest;
}

export interface SubmitComparisonRequest {
    body: ComparisonRequest;
}

export interface SubmitIsolationRequest {
    body: IsolationRequest;
}

export interface SubmitPix2pixRequest {
    body: Pix2PixRequest;
}

export interface UploadImagesRequest {
    body: ImagesUploadRequest;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Analysis result
     */
    async analysisResultRaw(requestParameters: AnalysisResultRequest): Promise<runtime.ApiResponse<AnalysisResult>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling analysisResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analysis/{request_id}/result`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisResultFromJSON(jsonValue));
    }

    /**
     * Analysis result
     */
    async analysisResult(requestParameters: AnalysisResultRequest): Promise<AnalysisResult> {
        const response = await this.analysisResultRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the image-wise analysis XLSX
     */
    async analysisXlsxImagesRaw(requestParameters: AnalysisXlsxImagesRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling analysisXlsxImages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/analysis/xlsx/images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalysisSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch the image-wise analysis XLSX
     */
    async analysisXlsxImages(requestParameters: AnalysisXlsxImagesRequest): Promise<Blob> {
        const response = await this.analysisXlsxImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the image-wise analysis XLSX with focus on individual islets
     */
    async analysisXlsxIsletsRaw(requestParameters: AnalysisXlsxIsletsRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling analysisXlsxIslets.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/analysis/xlsx/islets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalysisSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch the image-wise analysis XLSX with focus on individual islets
     */
    async analysisXlsxIslets(requestParameters: AnalysisXlsxIsletsRequest): Promise<Blob> {
        const response = await this.analysisXlsxIsletsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch analysis ZIP archive
     */
    async analysisZipRaw(requestParameters: AnalysisZipRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling analysisZip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/analysis/zip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalysisSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch analysis ZIP archive
     */
    async analysisZip(requestParameters: AnalysisZipRequest): Promise<Blob> {
        const response = await this.analysisZipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch comparison complete ZIP archive
     */
    async comparisonCompleteZipRaw(requestParameters: ComparisonCompleteZipRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling comparisonCompleteZip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/comparison/complete-zip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ComparisonSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch comparison complete ZIP archive
     */
    async comparisonCompleteZip(requestParameters: ComparisonCompleteZipRequest): Promise<Blob> {
        const response = await this.comparisonCompleteZipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch comparison PDF report
     */
    async comparisonPdfRaw(requestParameters: ComparisonPdfRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling comparisonPdf.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/comparison/pdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ComparisonSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch comparison PDF report
     */
    async comparisonPdf(requestParameters: ComparisonPdfRequest): Promise<Blob> {
        const response = await this.comparisonPdfRaw(requestParameters);
        return await response.value();
    }

    /**
     * Comparison result
     */
    async comparisonResultRaw(requestParameters: ComparisonResultRequest): Promise<runtime.ApiResponse<ComparisonResult>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling comparisonResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/comparison/{request_id}/result`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ComparisonResultFromJSON(jsonValue));
    }

    /**
     * Comparison result
     */
    async comparisonResult(requestParameters: ComparisonResultRequest): Promise<ComparisonResult> {
        const response = await this.comparisonResultRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the comparison XLSX
     */
    async comparisonXlsxRaw(requestParameters: ComparisonXlsxRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling comparisonXlsx.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/comparison/xlsx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ComparisonSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch the comparison XLSX
     */
    async comparisonXlsx(requestParameters: ComparisonXlsxRequest): Promise<Blob> {
        const response = await this.comparisonXlsxRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch comparison ZIP archive
     */
    async comparisonZipRaw(requestParameters: ComparisonZipRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling comparisonZip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/comparison/zip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ComparisonSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch comparison ZIP archive
     */
    async comparisonZip(requestParameters: ComparisonZipRequest): Promise<Blob> {
        const response = await this.comparisonZipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an uploaded image specified by its id
     */
    async deleteImageRaw(requestParameters: DeleteImageRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling deleteImage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{image_id}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Delete an uploaded image specified by its id
     */
    async deleteImage(requestParameters: DeleteImageRequest): Promise<object> {
        const response = await this.deleteImageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async emailResultsRaw(requestParameters: EmailResultsRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling emailResults.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/send-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async emailResults(requestParameters: EmailResultsRequest): Promise<object> {
        const response = await this.emailResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch a single (base64) image specified by its id
     */
    async getImageRaw(requestParameters: GetImageRequest): Promise<runtime.ApiResponse<FetchImageResponse>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getImage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{image_id}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchImageResponseFromJSON(jsonValue));
    }

    /**
     * Fetch a single (base64) image specified by its id
     */
    async getImage(requestParameters: GetImageRequest): Promise<FetchImageResponse> {
        const response = await this.getImageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch a list of (base64) images specified by a list of their ids
     */
    async getImagesRaw(requestParameters: GetImagesRequest): Promise<runtime.ApiResponse<FetchImagesResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getImages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/images/fetch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchImagesRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchImagesResponseFromJSON(jsonValue));
    }

    /**
     * Fetch a list of (base64) images specified by a list of their ids
     */
    async getImages(requestParameters: GetImagesRequest): Promise<FetchImagesResponse> {
        const response = await this.getImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Asses and return info about the given image
     */
    async imageInfoRaw(requestParameters: ImageInfoRequest): Promise<runtime.ApiResponse<ImageInfoResponse>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling imageInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{image_id}/info`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageInfoResponseFromJSON(jsonValue));
    }

    /**
     * Asses and return info about the given image
     */
    async imageInfo(requestParameters: ImageInfoRequest): Promise<ImageInfoResponse> {
        const response = await this.imageInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send whole isolation session to isletswipe
     */
    async isletswipeSendSessionRaw(requestParameters: IsletswipeSendSessionRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling isletswipeSendSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/isletswipe-send-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsolationSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Send whole isolation session to isletswipe
     */
    async isletswipeSendSession(requestParameters: IsletswipeSendSessionRequest): Promise<object> {
        const response = await this.isletswipeSendSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch isolation PDF report
     */
    async isolationPdfCompleteRaw(requestParameters: IsolationPdfCompleteRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling isolationPdfComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/pdf/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsolationSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch isolation PDF report
     */
    async isolationPdfComplete(requestParameters: IsolationPdfCompleteRequest): Promise<Blob> {
        const response = await this.isolationPdfCompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch isolation PDF report
     */
    async isolationPdfSummaryRaw(requestParameters: IsolationPdfSummaryRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling isolationPdfSummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/pdf/summary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsolationSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch isolation PDF report
     */
    async isolationPdfSummary(requestParameters: IsolationPdfSummaryRequest): Promise<Blob> {
        const response = await this.isolationPdfSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Isolation result
     */
    async isolationResultRaw(requestParameters: IsolationResultRequest): Promise<runtime.ApiResponse<IsolationResult>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling isolationResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/isolation/{request_id}/result`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IsolationResultFromJSON(jsonValue));
    }

    /**
     * Isolation result
     */
    async isolationResult(requestParameters: IsolationResultRequest): Promise<IsolationResult> {
        const response = await this.isolationResultRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the image-wise isolation XLSX
     */
    async isolationXlsxImagesRaw(requestParameters: IsolationXlsxImagesRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling isolationXlsxImages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/xlsx/images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsolationSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch the image-wise isolation XLSX
     */
    async isolationXlsxImages(requestParameters: IsolationXlsxImagesRequest): Promise<Blob> {
        const response = await this.isolationXlsxImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the image-wise isolation XLSX with focus on individual islets
     */
    async isolationXlsxIsletsRaw(requestParameters: IsolationXlsxIsletsRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling isolationXlsxIslets.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/xlsx/islets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsolationSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch the image-wise isolation XLSX with focus on individual islets
     */
    async isolationXlsxIslets(requestParameters: IsolationXlsxIsletsRequest): Promise<Blob> {
        const response = await this.isolationXlsxIsletsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch isolation ZIP archive
     */
    async isolationZipRaw(requestParameters: IsolationZipRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling isolationZip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/zip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsolationSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch isolation ZIP archive
     */
    async isolationZip(requestParameters: IsolationZipRequest): Promise<Blob> {
        const response = await this.isolationZipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Pixel to pixel contours coordinates
     */
    async pix2pixContoursRaw(requestParameters: Pix2pixContoursRequest): Promise<runtime.ApiResponse<Pix2PixContoursResult>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling pix2pixContours.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pixel-to-pixel/{request_id}/contours`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => Pix2PixContoursResultFromJSON(jsonValue));
    }

    /**
     * Pixel to pixel contours coordinates
     */
    async pix2pixContours(requestParameters: Pix2pixContoursRequest): Promise<Pix2PixContoursResult> {
        const response = await this.pix2pixContoursRaw(requestParameters);
        return await response.value();
    }

    /**
     * Pixel to pixel result.
     */
    async pix2pixResultRaw(requestParameters: Pix2pixResultRequest): Promise<runtime.ApiResponse<Pix2PixResult>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling pix2pixResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pixel-to-pixel/{request_id}/result`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => Pix2PixResultFromJSON(jsonValue));
    }

    /**
     * Pixel to pixel result.
     */
    async pix2pixResult(requestParameters: Pix2pixResultRequest): Promise<Pix2PixResult> {
        const response = await this.pix2pixResultRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the image-wise pixel-to-pixel validation XLSX
     */
    async pix2pixXlsxValidationRaw(requestParameters: Pix2pixXlsxValidationRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling pix2pixXlsxValidation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pixel-to-pixel/xlsx/validation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Pix2PixSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch the image-wise pixel-to-pixel validation XLSX
     */
    async pix2pixXlsxValidation(requestParameters: Pix2pixXlsxValidationRequest): Promise<Blob> {
        const response = await this.pix2pixXlsxValidationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch pixel-to-pixel XLSX-ZIP archive
     */
    async pix2pixXlsxzipRaw(requestParameters: Pix2pixXlsxzipRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling pix2pixXlsxzip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pixel-to-pixel/xlsx-zip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Pix2PixSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch pixel-to-pixel XLSX-ZIP archive
     */
    async pix2pixXlsxzip(requestParameters: Pix2pixXlsxzipRequest): Promise<Blob> {
        const response = await this.pix2pixXlsxzipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch pixel-to-pixel ZIP archive
     */
    async pix2pixZipRaw(requestParameters: Pix2pixZipRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling pix2pixZip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pixel-to-pixel/zip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Pix2PixSummaryRequestToJSON(requestParameters.body),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch pixel-to-pixel ZIP archive
     */
    async pix2pixZip(requestParameters: Pix2pixZipRequest): Promise<Blob> {
        const response = await this.pix2pixZipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Report image or remove image from reported images
     */
    async reportImagesRaw(requestParameters: ReportImagesRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling reportImages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/images/report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportImageRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Report image or remove image from reported images
     */
    async reportImages(requestParameters: ReportImagesRequest): Promise<object> {
        const response = await this.reportImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch the statistics XLSX
     */
    async statisticsXlsxRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/statistics/access_statistics.xlsx`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch the statistics XLSX
     */
    async statisticsXlsx(): Promise<Blob> {
        const response = await this.statisticsXlsxRaw();
        return await response.value();
    }

    /**
     * Check status of backend
     */
    async statusRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Check status of backend
     */
    async status(): Promise<object> {
        const response = await this.statusRaw();
        return await response.value();
    }

    /**
     */
    async submitAnalysisRaw(requestParameters: SubmitAnalysisRequest): Promise<runtime.ApiResponse<SubmissionResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling submitAnalysis.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/analysis/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalysisRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async submitAnalysis(requestParameters: SubmitAnalysisRequest): Promise<SubmissionResponse> {
        const response = await this.submitAnalysisRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload an image for Simple Comparison Analysis
     */
    async submitComparisonRaw(requestParameters: SubmitComparisonRequest): Promise<runtime.ApiResponse<SubmissionResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling submitComparison.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/comparison/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ComparisonRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionResponseFromJSON(jsonValue));
    }

    /**
     * Upload an image for Simple Comparison Analysis
     */
    async submitComparison(requestParameters: SubmitComparisonRequest): Promise<SubmissionResponse> {
        const response = await this.submitComparisonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload an image for Clinical Isolation Analysis
     */
    async submitIsolationRaw(requestParameters: SubmitIsolationRequest): Promise<runtime.ApiResponse<SubmissionResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling submitIsolation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/isolation/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsolationRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionResponseFromJSON(jsonValue));
    }

    /**
     * Upload an image for Clinical Isolation Analysis
     */
    async submitIsolation(requestParameters: SubmitIsolationRequest): Promise<SubmissionResponse> {
        const response = await this.submitIsolationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload an image for Pixel to pixel
     */
    async submitPix2pixRaw(requestParameters: SubmitPix2pixRequest): Promise<runtime.ApiResponse<SubmissionResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling submitPix2pix.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pixel-to-pixel/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Pix2PixRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionResponseFromJSON(jsonValue));
    }

    /**
     * Upload an image for Pixel to pixel
     */
    async submitPix2pix(requestParameters: SubmitPix2pixRequest): Promise<SubmissionResponse> {
        const response = await this.submitPix2pixRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload a list of (base64) images and return their ids
     */
    async uploadImagesRaw(requestParameters: UploadImagesRequest): Promise<runtime.ApiResponse<ImagesUploadResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling uploadImages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/images/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImagesUploadRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImagesUploadResponseFromJSON(jsonValue));
    }

    /**
     * Upload a list of (base64) images and return their ids
     */
    async uploadImages(requestParameters: UploadImagesRequest): Promise<ImagesUploadResponse> {
        const response = await this.uploadImagesRaw(requestParameters);
        return await response.value();
    }

}
