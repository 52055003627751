import {mean} from "lodash";

import {MaskStatistics} from "../../api";
import {BaseBatchMetadata} from "../../redux/actions";

export const meanIsletCount = (batch: BatchShape<any>) =>
    mean(batch.items.filter(i => i.included).map(i => i.statistics.isletCount));
export const meanIsletCountDilution = (batch: BatchShape<any>) =>
    batch.metadata.dilution * meanIsletCount(batch);
export const meanEllipse = (batch: BatchShape<any>) =>
    mean(batch.items.filter(i => i.included).map(i => i.statistics.ieEllipse));
export const meanEllipseDilution = (batch: BatchShape<any>) =>
    batch.metadata.dilution * meanEllipse(batch);
export const meanTable = (batch: BatchShape<any>) =>
    mean(batch.items.filter(i => i.included).map(i => i.statistics.ieTable));
export const meanTableDilution = (batch: BatchShape<any>) =>
    batch.metadata.dilution * meanTable(batch);

interface BatchShape<
    BatchMetadataType extends BaseBatchMetadata,
    StatisticsType extends MaskStatistics = MaskStatistics
> {
    included: boolean;
    items: Array<{
        included: boolean;
        statistics: StatisticsType;
    }>;
    metadata: BatchMetadataType;
}

export interface SummaryTableProps<
    BatchMetadataType extends BaseBatchMetadata,
    StatisticsType extends MaskStatistics = MaskStatistics
> {
    sessionCode: string;
    batches: {[batchId: string]: BatchShape<BatchMetadataType, StatisticsType>}
}
