import React from "react";

import ClassNames from "classnames"
import {Control, LocalForm} from 'react-redux-form';

import Icon from "../Icon";

import {AnalysisMaskData} from "../../redux/actions";
import {toFloat, toNumber} from '../../utils/parsers';
import {isPositive, isPositiveFloat} from '../../utils/validators';
import TruncatedText from "../TruncatedText";
import ClickableLink from "../ClickableLink";

export interface UploadedImageMetadata {
    isletCountUser: string;
    volumeTableUser: string;
    volumeUser: string;
}

interface UploadedImageItemProps {
    done: boolean;
    error: boolean;
    fileName: string;
    initialState: UploadedImageMetadata,
    onClick: (fileName: string) => void;
    onMetadataChanged: (data: Partial<AnalysisMaskData>) => void;
    onRemove: (fileName: string) => void;
    selected: boolean;
}

class AnalysisUploadItem extends React.Component<UploadedImageItemProps> {
    public static defaultProps = {
        initialState: {
            isletCountUser: "",
            volumeTableUser: "",
            volumeUser: ""
        },
        onClick: () => {
            return;
        },
        onMetadataChanged: () => {
            return;
        },
        onRemove: () => {
            return;
        }
    };

    private removeHandler = (event: any) => {
        event.stopPropagation();
        this.props.onRemove(this.props.fileName);
    };

    private clickHandler = (event: any) => {
        event.stopPropagation();
        this.props.onClick(this.props.fileName);
    };

    private metadataChangeHandler = (data: UploadedImageMetadata) => {
        this.props.onMetadataChanged({
            isletCountUser: data.isletCountUser.length ? parseInt(data.isletCountUser, 10) : undefined,
            volumeTableUser: data.volumeTableUser.length ? parseFloat(data.volumeTableUser) : undefined,
            volumeUser: data.volumeUser.length ? parseFloat(data.volumeUser) : undefined
        });
    };

    public render() {
        // Set up status text.
        let statusText = "Uploading...";

        if (this.props.done) {
            statusText = "";
        }
        if (this.props.error) {
            statusText = "Upload error";
        }

        return (
            <li onClick={this.clickHandler} className={ClassNames({
                done: this.props.done,
                error: this.props.error,
                selected: this.props.selected
            })}>
                <TruncatedText text={this.props.fileName} />
                <span className="status-text">{statusText}</span>
                <LocalForm onChange={this.metadataChangeHandler}>
                    <Control.text defaultValue={this.props.initialState.isletCountUser} model=".isletCountUser"
                                  validators={{isPositive}} parser={toNumber}/>
                    <Control.text defaultValue={this.props.initialState.volumeTableUser} model=".volumeTableUser"
                                  validators={{isPositiveFloat}} parser={toFloat}/>
                    <Control.text defaultValue={this.props.initialState.volumeUser} model=".volumeUser"
                                  validators={{isPositiveFloat}} parser={toFloat}/>

                </LocalForm>
                <ClickableLink title="Remove image" onClick={this.removeHandler}><Icon name="close"/></ClickableLink>
            </li>
        );
    }
}

export default AnalysisUploadItem;
