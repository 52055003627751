import React from 'react';

import {Dictionary, isEqual, noop} from 'lodash';
import LoadingScreen from './LoadingScreen';

export type FormRenderer<BatchMetadataType> = (
    onSubmit: (batchMeta: BatchMetadataType) => void,
    onStateChanged: (state: UploaderContentState) => void
) => React.ReactNode;

export type ImagesSectionRenderer<ItemMetadataType> = (
    onImagesChange: (images: Array<File>, imagesMeta: Array<Partial<ItemMetadataType>>) => void
) => React.ReactNode;

export type UploaderSubmitHandler<ItemMetadataType, BatchMetadataType> = (
    uploadIds: {[filename: string]: string},
    imagesMetadata: Array<ItemMetadataType>,
    batchMeta: BatchMetadataType
) => Promise<boolean>;

export interface UploaderContentState {
    modified: boolean;
}

interface UploaderProps<BatchMetadataType, ItemMetadataType> {
    formRenderer: FormRenderer<BatchMetadataType>;
    imagesSectionRenderer: ImagesSectionRenderer<ItemMetadataType>;
    onError: (title: string, text: string) => void;
    onStateChanged: (state: UploaderContentState) => void;
    onSubmit: UploaderSubmitHandler<ItemMetadataType, BatchMetadataType>;
    onUploadRequested: (files: File[]) => void;
    uploadStatus: {
        [filename: string]: {
            done: boolean,
            error: boolean,
            id: string|null
        }
    };
}

interface UploaderState<BatchMetadataType, ItemMetadataType> {
    batchMetadata?: BatchMetadataType;
    formState: UploaderContentState;
    images: Array<File>;
    imagesMeta: Array<ItemMetadataType>;
    imagesState: UploaderContentState;
    waitingForUpload: boolean;
}

class Uploader<BatchMetadataType, ItemMetadataType extends {name: string}> extends React.Component<
    UploaderProps<BatchMetadataType, ItemMetadataType>,
    UploaderState<BatchMetadataType, ItemMetadataType>
> {
    public static defaultProps = {
        onError: noop
    };

    constructor(props: UploaderProps<BatchMetadataType, ItemMetadataType>) {
        super(props);

        this.state = {
            formState: {
                modified: false
            },
            images: [],
            imagesMeta: [],
            imagesState: {
                modified: false
            },
            waitingForUpload: false
        }
    }

    private submit = (batchMeta: BatchMetadataType) => {
        if (this.someUploadFailed()) {
            this.props.onError("Upload error", "Uploading of some of the files failed. Please make sure your internet " +
             "connection is not down, try removing and adding files again and refreshing the page (F5) and contact us " +
             "at isletnet@mild.blue if problems persist.");
            this.setState({
                batchMetadata: batchMeta,
                waitingForUpload: false
            });
            return;
        }

        if (this.allUploadsDone()) {
            const uploadIds: Dictionary<string> = {};

            this.state.images.forEach(file => {
                uploadIds[file.name] = this.props.uploadStatus[file.name].id as string;
            });

            this.props.onSubmit(uploadIds, this.state.imagesMeta, batchMeta).then(success => {
                if (!success) {
                    this.setState({
                        batchMetadata: batchMeta,
                        waitingForUpload: false
                    });
                }
            });
        } else {
            this.setState({
                batchMetadata: batchMeta,
                waitingForUpload: true
            });
        }
    };

    public componentWillUpdate(
        prevProps: UploaderProps<BatchMetadataType, ItemMetadataType>,
        prevState: UploaderState<BatchMetadataType, ItemMetadataType>
    ) {
        const formStateEqual = isEqual(this.state.formState, prevState.formState);
        const imagesStateEqual = isEqual(this.state.imagesState, prevState.imagesState);

        if (!formStateEqual || !imagesStateEqual) {
            this.props.onStateChanged({
                modified: this.state.imagesState.modified || this.state.imagesState.modified
            });
        }
    }

    private formStateChanged = (formState: UploaderContentState) => this.setState({formState});

    private onImagesChange = async (images: Array<File>, imagesMeta: Array<ItemMetadataType>) => {
        this.setState({images, imagesMeta});

        this.props.onStateChanged({
            modified: images.length > 0
        });

        this.props.onUploadRequested(images);
    };

    private allUploadsDone() {
        return this.state.images.every(file => this.props.uploadStatus[file.name].done);
    }

    private someUploadFailed() {
        return this.state.images.some(file => this.props.uploadStatus[file.name].error);
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.waitingForUpload && (this.someUploadFailed() || this.allUploadsDone())) {
            this.submit(this.state.batchMetadata!);
        }
    }

    public render() {
        if (this.state.waitingForUpload) {
            return <LoadingScreen text={<>Uploading images to the server<br />(estimated remaining time is {this.state.images.length * 0.25} minutes)</>} />;
        }

        return (
            <div>
                {this.props.imagesSectionRenderer(this.onImagesChange)}
                {this.props.formRenderer(this.submit, this.formStateChanged)}
            </div>
        );
    }
}

export default Uploader;

export interface UploadStatusItem {
    done: boolean;
    error: boolean;
}

export interface UploadStatus extends Dictionary<UploadStatusItem> {
}

// Build an array where each item specifies the upload status
// of the corresponding file.
// If the upload status is unknown for a file,
// it defaults to {done: false, error: false}.
//
// Example:
// fileUploadStats([{name: "f1"}, {name: "f2"}, {name: "f3"}],
//                 {f2: {done: false, error: true},
//                  f1: {done: true,  error: false}})
//   === [{done: true,  error: false},
//        {done: false, error: true},
//        {done: false, error: false}]
export const matchUploadStatus = (files: File[], uploadStatus: UploadStatus): UploadStatusItem[] => {
    return files.map((file, i) => {
        if (!uploadStatus || !(file.name in uploadStatus)) {
            return {done: false, error: false}
        }
        return uploadStatus[file.name]
    })
};
