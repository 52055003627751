import React from 'react';
import PropTypes from 'prop-types';
import {LocalForm, Control} from 'react-redux-form';
import {Stage, Image as KonvaImage, Layer, Circle, Line} from 'react-konva';
import {toNumber} from '../utils/parsers';
import {isPositive, required} from '../utils/validators';
import AutoSizer from "react-virtualized-auto-sizer";

import imgPlaceholder from '../images/image-placeholder-ruler.jpg';
import Dropzone from "react-dropzone";
import {noop} from "lodash";
import ClickableLink from "./ClickableLink";
import produce from "immer";

const readImage = (file: File) => new Promise<HTMLImageElement>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.src = reader.result as string;
    };
    reader.readAsDataURL(file);
});

interface Props {
    onSubmit: (pixelSize: number) => void;
}

interface Point {
    x: number;
    y: number;
}

interface State {
    actualSize: number;
    image?: HTMLImageElement;
    pixelSize: number;
    pointA?: Point;
    pointB?: Point;
    showHint: boolean;
}

class PixelSizeChooser extends React.Component<Props, State> {
    private static propTypes = {
        onSubmit: PropTypes.func
    };

    public static defaultProps = {
        onSubmit: noop
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            actualSize: 0,
            pixelSize: 0,
            showHint: true
        };
    }

    private calculateDistance() {
        const pointA = this.state.pointA;
        const pointB = this.state.pointB;

        if (pointA === undefined || pointB === undefined) {
            return 0;
        }

        return Math.sqrt(Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2));
    }

    private calculatePixelSize(size?: number) {
        const result = (size || this.state.actualSize) / this.calculateDistance();
        return !isNaN(result) ? result : 0;
    }

    private onChange = (data: any) => {
        this.setState({actualSize: data.size, pixelSize: this.calculatePixelSize(data.size)})
    };

    private onSubmit = (data: any) => {
        this.props.onSubmit(this.calculatePixelSize(data.size));
    };

    private onDrag = (point: "pointA"|"pointB") => (event: any) => {
        this.setState(prevState => produce(prevState, (draft: State) => {
            draft[point] = {
                x: event.target.x(),
                y: event.target.y()
            };

            draft.pixelSize = this.calculatePixelSize();
        }));
    };

    private onDragA = this.onDrag("pointA");
    private onDragB = this.onDrag("pointB");

    private onSelectFile = async (files: File[]) => {
        if (files.length <= 0) {
            return;
        }

        const file = files[0];
        const image = await readImage(file);
        this.setState({
            image,
            pointA: {
                x: image.width / 3,
                y: image.height / 2
            },
            pointB: {
                x: image.width * 2 / 3,
                y: image.height / 2
            }
        });
    };

    private hideHint = () => {
        this.setState({
            showHint: false
        });
    };

    public render() {
        return <div className="choose-pixel-size-modal">
            <div className="step">
                <div className="step1">
                    <h2>Pixel size determination</h2>
                    <br/>
                    This tool will help you to determine the Pixel size using an image of your Stage micrometer. The stage micrometer image must be taken under the same conditions under which the islet images you wish to analyze were acquired. Make sure that the microscope, objective, camera, zoom, and image dimensions are the same.
                    <Dropzone accept="image/png,image/jpeg" style={{}} onDrop={this.onSelectFile}>
                        <button>Upload micrometer</button>
                    </Dropzone>
                    <br/><br/>
                    <div className="choose-pixel-size-modal-text">
                        <span className="step-number">1</span>
                        Move ends of the red line to distant points of the ruler. The number of pixels between the ends will be counted.
                    </div>
                    <LocalForm className="" onChange={this.onChange} onSubmit={this.onSubmit}
                               initialState={{size: ""}}>
                         <div className="choose-pixel-size-modal-text">
                             <span className="step-number">2</span>
                             Read (in micrometers) the distance you have selected between the ends of the red line, and type the number in the box.
                             <div className="px-size-wrap">
                                 <Control.text parser={toNumber} validators={{required, isPositive}} model=".size"/>
                                 <span className="label-detail">
                                         Computed pixel size: {this.state.pixelSize.toFixed(3)} μm
                                     </span>
                             </div>
                         </div>
                         <div className="choose-pixel-size-modal-text">
                             <span className="step-number">3</span>
                             Press Submit to calculate the pixel size, which will be copied automatically to the Batch-wide properties.
                         </div>
                        <br/><br/>
                        <input type="submit" value="Submit"/>
                    </LocalForm>
                </div>
            </div>

            <div className="step">
                <div className="choose-pixel-size-canvas-wrap">
                    {this.state.image && this.state.showHint && <div className="hint-box micrometer">
                        <h4>Micrometer hint</h4>
                        <p>Measure the span by dragging each end point of the measuring line</p>
                        <ClickableLink className="close-btn" onClick={this.hideHint}/>
                    </div>}
                    {this.state.image === undefined
                        ? <img src={imgPlaceholder} className="image-placeholder" alt="Your uploaded ruler will be shown here"/>
                        : <AutoSizer defaultHeight={100} defaultWidth={100}>{({width, height}) => {
                            if (!height) {
                                height = 800;
                            }

                            const color = "#e2001a";
                            const widthRatio = this.state.image !== undefined ? width / this.state.image.width : 1;
                            const heightRatio = this.state.image !== undefined ? height / this.state.image.height : 1;
                            const scale = Math.min(widthRatio, heightRatio, 1);
                            const inverseScale = 1 / scale;

                            return <Stage width={width} height={height} scaleX={scale} scaleY={scale}>
                                    <Layer>
                                        {
                                            this.state.image !== undefined && <KonvaImage image={this.state.image}/>
                                        }
                                    </Layer>
                                    {this.state.pointA !== undefined && this.state.pointB !== undefined && <Layer>
                                        <Line
                                            points={[
                                                this.state.pointA.x,
                                                this.state.pointA.y,
                                                this.state.pointB.x,
                                                this.state.pointB.y
                                            ]}
                                            stroke={color}
                                            strokeWidth={inverseScale * 6}
                                        />

                                        <Circle draggable={true} opacity={0} onDragMove={this.onDragA} radius={inverseScale * 12} fill={color}
                                                x={this.state.pointA.x} y={this.state.pointA.y}/>
                                        <Circle draggable={true} opacity={0} onDragMove={this.onDragB} radius={inverseScale * 12} fill={color}
                                                x={this.state.pointB.x} y={this.state.pointB.y}/>
                                    </Layer>}
                            </Stage>;
                    }}</AutoSizer>}
                    <span className="label-detail">
                        Measured span[px]: {Math.round(this.calculateDistance())}
                    </span>
                </div>
            </div>
        </div>;
    }
}

export default PixelSizeChooser;
