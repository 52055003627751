import {connect} from "react-redux";

import {flatten} from "lodash";

import AnalysisBatchResultContainer from "../../components/results/AnalysisBatchResultContainer";
import {AsyncRequestState, imageDownloadRequested, setAnalysisIncluded, StoreShape} from "../../redux/actions";
import {analysisResultData} from "../../redux/selectors";

export default connect(
    (state: StoreShape) => {
        const results = analysisResultData(state);
        return ({
            imagesLoading: flatten(Object.values(results).map(b => AnalysisBatchResultContainer.getDisplayedImages(b.items)))
                .map(id => state.imageDownloadStatus[id]).some(status =>
                    status === undefined ||
                    status === AsyncRequestState.PENDING ||
                    status === AsyncRequestState.NONE
                )
        });
    },
    dispatch => ({
        onSetIncluded: (imageIndex: number, batchId: string, included: boolean) => {
            dispatch(setAnalysisIncluded({
                batchId,
                imageIndex,
                included
            }))
        },
        onStartLoading: (imageIds: string[]) => {
            imageIds.forEach(imageId => dispatch(imageDownloadRequested({imageId})))
        }
    })
)(AnalysisBatchResultContainer);
