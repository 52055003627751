import {connect} from "react-redux";
import UploaderFormSectionComponent from "../components/upload/UploaderFormSection";
import {pix2pixBatchIds} from "../redux/selectors";

export default connect(
    (state, props) => ({
        batchIds: pix2pixBatchIds(state),
        formModel: "pix2pixUploaderForm",
    }),
    (dispatch, props) => ({})
)(UploaderFormSectionComponent);
