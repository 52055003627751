import React from "react";

import {MapInteraction} from "react-map-interaction";
import sizeMe, {SizeMeProps} from "react-sizeme";

export interface Size {
    height: number;
    width: number;
}

export interface Translation {
    x: number;
    y: number;
}

export interface ZoomInfo {
    minScale: number;
    scale: number;
    translation: Translation;
}

export const DEFAULT_ZOOM_INFO = {
    minScale: 0,
    scale: 0,
    translation: {
        x: 0,
        y: 0
    }
};

interface ZoomableStackProps {
    contentSize: Size;
    children: (translation: Translation, scale: number, viewportSize: Size) => React.ReactNode;
    maxScale: number;
    onChange: (zoomInfo: ZoomInfo) => void;
    zoom?: ZoomInfo;
}

interface ZoomableStackState {
    viewportSize: Size;
}

interface MapProps extends ZoomableStackProps, SizeMeProps {
    minScale: number;
    maxScale: number;
    onSize: any;  // SizeMeProps does not advertise this for some reason
    viewportSize: Size;
}

class MapInner extends React.Component<MapProps> {
    private onChange = (newZoom: ZoomInfo) => {
        this.props.onChange({...newZoom, minScale: this.props.minScale});
    };

    public componentDidMount(): void {
        this.onChange(this.props.zoom || DEFAULT_ZOOM_INFO);
    }

    public render(): React.ReactNode {
        const zoom = this.props.zoom || DEFAULT_ZOOM_INFO;

        return <MapInteraction
            minScale={this.props.minScale}
            maxScale={this.props.maxScale}
            scale={this.props.zoom ? this.props.zoom.scale : this.props.minScale}
            onChange={this.onChange}
            translation={zoom.translation}
            translationBounds={{
                xMax: 0,
                xMin: Math.min(0, -(this.props.contentSize.width * zoom.scale - this.props.viewportSize.width)),
                yMax: 0,
                yMin: Math.min(0, -(this.props.contentSize.height * zoom.scale - this.props.viewportSize.height))
            }}
        >{({translation, scale}: {translation: Translation, scale: number}) => {
            if (this.props.contentSize === undefined || this.props.viewportSize === undefined || this.props.viewportSize.height === 0) {
                return null;
            }

            const adjustedViewportSize = {
                height: Math.floor(this.props.viewportSize.height) - 10,
                width: Math.floor(this.props.viewportSize.width) - 10
            };

            return <div className="layered">
                <div className="layered-content" style={adjustedViewportSize}>
                    {this.props.children(translation, scale, adjustedViewportSize)}
                </div>
            </div>;
        }}</MapInteraction>;
    }
}

const Map = sizeMe({monitorWidth: true, monitorHeight: true, refreshRate: 100})(MapInner);

class ZoomableStack extends React.Component<ZoomableStackProps, ZoomableStackState> {
    constructor(props: ZoomableStackProps) {
        super(props);
        this.state = {
            viewportSize: {
                height: 0,
                width: 0
            }
        };
    }

    private minScale(size: {height: number, width: number}) {
        if (this.props.contentSize.width < size.width && this.props.contentSize.height < size.height) {
            return 1;
        }

        return Math.min(size.height / this.props.contentSize.height, size.width / this.props.contentSize.width);
    }

    private handleSizeChange = (viewportSize: Size) => {
        this.setState({viewportSize});
    };

    public render() {
        return <Map
            onSize={this.handleSizeChange}
            onChange={this.props.onChange}
            minScale={this.minScale(this.state.viewportSize)}
            maxScale={this.props.maxScale}
            viewportSize={this.state.viewportSize}
            children={this.props.children}
            zoom={this.props.zoom}
            {...this.props}
        />;
    }
}

export default ZoomableStack;
