import React from 'react';

import {concat, flatten, noop} from 'lodash';

import IsolationBatchSummary from './IsolationBatchSummary';
import {defaultTabState, TabState} from "./ResultsContent";
import {IsolationItemResult} from "./types";

import LoadingScreen from "../LoadingScreen";
import ResultsContent from "../../containers/results/ResultsContent";
import {EmbeddedFragmentedImageLegend, IsletsExoLegend} from "./legends";

interface IsolationBatchResultsProps {
    batchId: string;
    imagesLoading: boolean;
    items: IsolationItemResult[];
    onSelect: (itemIndex: number, batchId: string) => void;
    onSetIncluded: (itemIndex: number, batchId: string, value: boolean) => void;
    onStartLoading: (imageIds: string[]) => void;
    onTabStateChanged: (state: TabState) => void;
    selectedItem: number;
    tabState: TabState;
}

export class IsolationBatchResults extends React.Component<IsolationBatchResultsProps> {
    public static defaultProps = {
        onSelect: noop,
        onTabStateChanged: noop,
        tabState: defaultTabState
    };

    private onSelect = (item: number) => {
        this.props.onSelect(item, this.props.batchId);
    };

    private onNext = () => this.props.onSelect(this.props.selectedItem + 1, this.props.batchId);
    private onPrevious = () => this.props.onSelect(this.props.selectedItem - 1, this.props.batchId);

    public static getLeftMenuItems(items: IsolationItemResult[], item: number) {
        const selectedItem = items[item];
        const result = selectedItem.result;

        return [
            {
                hotkey: "1",
                id: result.contoursId,
                title: "Contours"
            },
            {
                hotkey: "2",
                id: selectedItem.imageId,
                title: "Original"
            },
            {
                hotkey: "3",
                id: result.ellipsesId,
                title: "Ellipses"
            },
            {
                hotkey: "4",
                id: result.contoursLabeledId,
                title: "IsletIDs"
            },
            {
                hotkey: "5",
                id: result.imageContoursExoId,
                title: "Exocrine tissue"
            },
        ];
    }

    public static getRightMenuItems(items: IsolationItemResult[], item: number) {
        const result = items[item].result;

        return [
            {
                hotkey: "6",
                id: result.maskCombinedId,
                legend: <IsletsExoLegend/>,
                title: "Islets + Exo"
            },
            {
                hotkey: "7",
                id: result.maskIsletsId,
                title: "Islets"
            },
            {
                hotkey: "8",
                id: result.maskExoColorId,
                isExperimental: true,
                legend: <EmbeddedFragmentedImageLegend/>,
                title: "Emb+Frgm"
            },
            {
                hotkey: "9",
                id: result.histogramId,
                isZoomable: false,
                title: "Histogram"
            },
        ];
    }

    public static getDisplayedImages(items: IsolationItemResult[]) {
        return flatten(items.map( (value, index) =>
            concat(IsolationBatchResults.getLeftMenuItems(items, index), IsolationBatchResults.getRightMenuItems(items, index))
                .map(i => i.id)))
    }

    public componentDidMount() {
        this.props.onStartLoading(IsolationBatchResults.getDisplayedImages(this.props.items))
    }

    public componentDidUpdate() {
        this.props.onStartLoading(IsolationBatchResults.getDisplayedImages(this.props.items))
    }

    private onSetIncluded = (value: boolean) => this.props.onSetIncluded(
        this.props.selectedItem,
        this.props.batchId,
        value
    );

    public render() {
        const items = this.props.items.map(item => ({
            included: item.included,
            name: item.name,
            statistics: item.result.maskStatistics,
            userCutLines: (item.userCutLines && !!item.userCutLines.length) || false
        }));

        return (
            <div>
                {
                    !this.props.imagesLoading
                        ? <ResultsContent tabState={this.props.tabState} onTabStateChanged={this.props.onTabStateChanged}
                                    leftMenuItems={IsolationBatchResults.getLeftMenuItems(this.props.items, this.props.selectedItem)}
                                    rightMenuItems={IsolationBatchResults.getRightMenuItems(this.props.items, this.props.selectedItem)}
                                    onNext={this.onNext}
                                    onPrevious={this.onPrevious}
                                    included={items[this.props.selectedItem].included}
                                    onSetIncluded={this.onSetIncluded}
                                    fileName={this.props.items[this.props.selectedItem].name}/>
                        : <LoadingScreen text="Loading images"/>
                }
                <IsolationBatchSummary batchId={this.props.batchId} items={items}
                                       selectedItem={this.props.selectedItem} onSelect={this.onSelect}/>
            </div>
        );
    }
}

export default IsolationBatchResults;
