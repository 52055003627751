import React from "react"
import {Control, LocalForm} from "react-redux-form";
import classNames from "classnames";
import {MethodUser} from "../../api/models";
import {required} from "../../utils/validators";

const mapProps = {
    className: (props: any) => {
        const {valid, touched} = props.fieldValue;
        return classNames({error: !valid, valid, touched})
    }
};

interface Props {
    onSubmit: () => void
    onChange: (values: SimpleComparisonFormValues) => void
}

export interface SimpleComparisonFormValues {
    method: MethodUser
}

const SimpleComparisonForm = (props: Props) => {
    return <div className="row uploader-form-section">
        <h2>Select your method for islet volume calculation</h2>
        <LocalForm model="simpleComparisonUploaderForm" onChange={(data) => props.onChange(data)} onSubmit={props.onSubmit} className="simple-comparison-uploader-form">
            <div>
                <h3>Conversion tables</h3>
                <label title="" className="not-required">
                    <Control.radio model=".method" mapProps={mapProps} value={MethodUser.RicordiShortArea} validators={{required}} />
                    Ricordi <span className="muted">( 50 - 400 μm )</span>
                </label>
                <label title="" className="not-required">
                    <Control.radio model=".method" mapProps={mapProps} value={MethodUser.BuchwaldArea} validators={{required}} />
                    Buchwald <span className="muted">( 50 - 400 μm )</span>
                </label>
                <label title="" className="not-required">
                    <Control.radio model=".method" mapProps={mapProps} value={MethodUser.KisslerArea} validators={{required}} />
                    Kissler <span className="muted">( 50 - 400 μm )</span>
                </label>
                <label title="" className="not-required">
                    <Control.radio model=".method" mapProps={mapProps} value={MethodUser.RicordiLongArea} validators={{required}} />
                    Ricordi - long <span className="muted">( 50 - 500 μm )</span>
                </label>
            </div>
            <div>
                <h3>3D model</h3>
                <label title="" className="not-required">
                    <Control.radio model=".method" mapProps={mapProps} value={MethodUser.Ellipse} validators={{required}} />
                    Prolate spheroid <span className="muted">( unlimited )</span>
                </label>
                <label title="" className="not-required">
                    <Control.radio model=".method" mapProps={mapProps} value={MethodUser.AreaSphere} validators={{required}} />
                    Sphere from area <span className="muted">( unlimited )</span>
                </label>
            </div>
            <div></div>
            <div className="submit-column">
                <input type="submit" value="Submit batch"/>
            </div>
        </LocalForm>
    </div>
};

export default SimpleComparisonForm