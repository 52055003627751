import React from 'react';
import {Pix2PixResult} from "../../api";
import {mean, zeroToDash} from "./helpers"

interface Pix2PixBatchHeaderProps {
    includedResults: Pix2PixResult[];
}

const Pix2PixBatchHeader: React.SFC<Pix2PixBatchHeaderProps> = (props) => {
    return <tr>
        <th>Batch summary (mean):</th>

        <th title="IsletNet/Standard">
            <span className="primary-color bold">
                {zeroToDash(mean(props.includedResults.map((item) => item.relativeIsletCount)).toFixed(2))}
            </span> Rel.&nbsp;islet<br/>count
        </th>

        <th title="IsletNet/Standard">
            <span className="primary-color bold">
                {zeroToDash(mean(props.includedResults.map((item) => item.relativeIsletVolumeTable)).toFixed(2))}
            </span> Rel.&nbsp;islet<br/>volume (table)
        </th>

        <th title="IsletNet/Standard">
            <span className="primary-color bold">
                {zeroToDash(mean(props.includedResults.map((item) => item.relativeIsletVolumeEllipse)).toFixed(2))}
            </span> Rel.&nbsp;islet<br/>volume (ellipse)
        </th>

        <th title="IsletNet/Standard">
            <span className="primary-color bold">
                {zeroToDash(mean(props.includedResults.map((item) => item.relativeIsletArea)).toFixed(2))}
            </span> Rel.&nbsp;islet<br/>area
        </th>

        <th title="IsletNet/Standard">
            <span className="primary-color bold">
                {zeroToDash(mean(props.includedResults.map((item) => item.relativePurity)).toFixed(2))}
            </span> Rel.<br/>purity
        </th>

</tr>
};

export default Pix2PixBatchHeader;
