import {connect} from "react-redux";
import ResultsContent from "../../components/results/ResultsContent";
import {setLegendVisible, StoreShape} from "../../redux/actions";

export default connect(
    (state: StoreShape) => ({
        isLegendVisible: state.legendVisible.isVisible
    }),
    (dispatch => ({
        onSetLegendVisible: (isVisible: boolean) => dispatch(setLegendVisible({isVisible}))
    }))
)(ResultsContent);