import {connect} from "react-redux";

import ImageDetailsComponent, {ImageDetailsProps} from "../../components/results/ImageDetails";
import {setIncluded, setIsolationImageReported} from "../../redux/actions";


const IsolationImageDetails = connect(
    (state: any, props: ImageDetailsProps) => ({
        included: state.isolationBatches[props.batchMetadata.batchId].items[props.imageIndex].included,
        isReported: state.isolationBatches[props.batchMetadata.batchId].items[props.imageIndex].reported,
        minIsletSize: state.isolationBatches[props.batchMetadata.batchId].minIsletSize || props.batchMetadata.minIsletSize,
        setReportedState: state.isolationBatches[props.batchMetadata.batchId].items[props.imageIndex].setReportedState
    }),
    (dispatch, props) => ({
        onExclude: () => dispatch(setIncluded({
            batchId: props.batchMetadata.batchId,
            imageIndex: props.imageIndex,
            included: false
        })),
        onInclude: () => dispatch(setIncluded({
            batchId: props.batchMetadata.batchId,
            imageIndex: props.imageIndex,
            included: true
        })),
        onSetReported: (batchId: string, imageIndex: number, isReported: boolean) => dispatch(setIsolationImageReported({
            batchId,
            imageIndex,
            isReported
        }))
    })
)(ImageDetailsComponent);

export default IsolationImageDetails;
