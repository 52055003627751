import {connect} from "react-redux";

import {flatten} from "lodash";

import IsolationBatchResultContainer from "../../components/results/IsolationBatchResultContainer";
import {AsyncRequestState, imageDownloadRequested, setIncluded, StoreShape} from "../../redux/actions";
import {isolationResultData} from "../../redux/selectors";

export default connect(
    (state: StoreShape) => {
        const results = isolationResultData(state);
        return {
            imagesLoading: flatten(Object.values(results).map(b => IsolationBatchResultContainer.getDisplayedImages(b.items)))
                .map(id => state.imageDownloadStatus[id]).some(status =>
                    status === undefined ||
                    status === AsyncRequestState.PENDING ||
                    status === AsyncRequestState.NONE
                )
        };
    },
    dispatch => ({
        onSetIncluded: (imageIndex: number, batchId: string, included: boolean) => {
            dispatch(setIncluded({
                batchId,
                imageIndex,
                included
            }))
        },
        onStartLoading: (imageIds: string[]) => {
            imageIds.forEach(imageId => dispatch(imageDownloadRequested({imageId})))
        }
    })
)(IsolationBatchResultContainer);
