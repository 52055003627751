import React from 'react';
import classNames from "classnames";

interface ClickableLinkProps {
    onClick: (event: any) => void;
    title?: string;
    className?: string;
    children?: React.ReactNode;
}

const ClickableLink = (props: ClickableLinkProps) => {
    const {onClick, title = "", className = "", children = null} = props;
    const clickHandler = (event: any) => {
        event.preventDefault();
        onClick(event);
    };

    return (<button type="button" className={classNames(className, "link")} title={title} onClick={clickHandler}>{children}</button>);
};

export default ClickableLink;
