import React, {useCallback, useEffect, useState} from "react";

import {
    displayErrorMessage,
    imageUploadRequested, NewSimpleComparisonBatchPayload, StoreShape,
} from "../../redux/actions";
import SimpleComparisonForm, {SimpleComparisonFormValues} from "./SimpleComparisonForm";
import SimpleComparisonImagesSection, {SimpleComparisonTemplate} from "./SimpleComparisonImagesSection";
import {useDispatch, useSelector} from "react-redux";
import {initialSimpleComparisonState} from "../../redux/forms";
import {removeExtension} from "../../utils/fileNames";

interface SimpleComparisonUploaderProps {
    onSubmit: (submittedBatch: NewSimpleComparisonBatchPayload) => void;
}

const SimpleComparisonUploader  = (props: SimpleComparisonUploaderProps) => {
    const [images, setImages] = useState<File[]>([]);
    const [template, setTemplate] = useState<SimpleComparisonTemplate|undefined>(undefined);
    const [formValues, setFormValues] = useState<SimpleComparisonFormValues>(initialSimpleComparisonState);
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();
    const uploadStatus = useSelector((state: StoreShape) => state.imageUploadStatus);

    const uploadStatusEntries = images.map(image => uploadStatus[image.name]);
    const uploadDone = uploadStatusEntries.every(item => item !== undefined && item.done);
    const uploadFailed = uploadStatusEntries.some(item => item !== undefined && item.error);

    const onError = useCallback((title: string, text: string) => dispatch(displayErrorMessage({
        text,
        title
    })), [dispatch]);

    useEffect(() => {
        dispatch(imageUploadRequested({files: images}))
    }, [images, dispatch]);

    useEffect(() => {
        if (uploadFailed) {
            onError("Upload failed", "Failed to upload some files");
            return;
        }

        if (!submitted || !uploadDone) {
            return;
        }

        props.onSubmit({
            items: template!.imageOrder.map(name => {
                const image = images.find(image => removeExtension(name) === removeExtension(image.name))!;
                const params = template!.parameters[removeExtension(image.name)];

                return {
                    name: image.name,
                    imageId: uploadStatus[image.name].id!,
                    isletCountUser: params.isletCount,
                    minIsletSize: params.minIsletSize,
                    methodUser: formValues!.method,
                    purityUser: params.purity,
                    umPerPx: params.umPerPx,
                    volumeUserIe: params.volumeIe,
                }
            }),
            method: formValues!.method,
        })
    }, [submitted, uploadDone, uploadFailed, formValues, images, onError, props, template, uploadStatus]);

    const requestSubmit = () => {
        if (!template) {
            window.alert("Missing template file");
            return;
        }

        const missingImage = Object.keys(template.parameters).find(name => !images.some(image => removeExtension(image.name) === removeExtension(name)));
        if (missingImage !== undefined) {
            window.alert(`Image ${missingImage} specified in template file was not uploaded`);
            return;
        }

        const missingMetadata = images.find(image => template.parameters[removeExtension(image.name)] === undefined);
        if (missingMetadata !== undefined) {
            window.alert(`Metadata missing for image ${missingMetadata.name}`);
            return;
        }

        setSubmitted(true);
    };

    return <div>
        <SimpleComparisonImagesSection images={images} onImagesChanged={setImages} template={template} onTemplateChanged={setTemplate} />
        <SimpleComparisonForm onChange={setFormValues} onSubmit={requestSubmit} />
    </div>;
};

export default SimpleComparisonUploader;
