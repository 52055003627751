import hash from "hash.js";
import Tiff from "tiff.js";

export interface ReadImageResult {
    file: File;
    imageBase64: string;
}

const trimDataUrl = (dataUrl: string) => dataUrl.split(",")[1];
export const makePngDataUrl = (imageBase64: string) => `data:image/png;base64,${imageBase64}`;

export const readImage = (file: File) => new Promise<ReadImageResult>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
        if (file.type === "image/tiff") {
            resolve({
                file,
                imageBase64: trimDataUrl(new Tiff({buffer: reader.result}).toDataURL())
            });
        } else if (file.type === "image/bmp") {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.height = img.naturalHeight;
                canvas.width = img.naturalWidth;
                ctx!.drawImage(img, 0, 0);
                resolve({
                    file,
                    imageBase64: trimDataUrl(canvas.toDataURL("image/png"))
                })
            };

            img.src = reader.result as string;
        } else {
            resolve({
                file,
                imageBase64: trimDataUrl(reader.result as string)
            });
        }
    };

    if (file.type === "image/tiff") {
        reader.readAsArrayBuffer(file);
    } else if (file.type === "image/bmp") {
        reader.readAsDataURL(file);
    } else {
        reader.readAsDataURL(file);
    }
});

export const contentHash = (imageBase64: string) => hash.sha256().update(imageBase64).digest("hex");
