import React, {useState} from "react";

interface ContextMenuItemProps {
  children: React.ReactNode;
  onClickMenu?: (arg0: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ContextMenuItem = (props: ContextMenuItemProps) => {
  const [hover, setHover] = useState(false);

  return <div
    style={{
      padding: "0.7rem 1rem",
      backgroundColor: "white",
      cursor: "pointer",
      ...(hover ? {
        backgroundColor: "#F2F3F4"
      } : null)
    }}
    onClick={(e) => {
      if (props.onClickMenu) {
        props.onClickMenu(e);
      }
    }}
    onMouseEnter={() => {
      setHover(true);
    }}
    onMouseLeave={() => {
      setHover(false);
    }}
  >
    {props.children}
  </div>;
};

export default ContextMenuItem;
