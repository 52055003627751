import React from "react";
import Spinner from "react-spinkit";

interface LoadingScreenProps {
    text?: React.ReactNode;
}

const LoadingScreen: React.SFC<LoadingScreenProps> = ({text}) => {
    return (
        <div className="loading-screen-wrapper">
            <div className="loading-screen">
                <Spinner fadeIn="none" name="three-bounce"/>
                <p>{text || "Loading"}...</p>
            </div>
        </div>
    );
};

export default LoadingScreen;