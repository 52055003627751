import React from 'react';

import TruncatedText from "../TruncatedText";
import {SimpleComparisonItemResult} from "./types";

interface BatchSummaryProps {
    items: SimpleComparisonItemResult[];
    onSelect: (i: number) => void;
    selectedItem: number;
}

class SimpleComparisonBatchSummary extends React.Component<BatchSummaryProps> {
    public static defaultProps = {
        onSelect: () => {return;}
    };

    public render() {
        return (<table className="results-table">
            <thead>
                <tr>
                    <th className="primary-color bold">Image name</th>
                    <th className="primary-color bold">Islets</th>
                    <th className="primary-color bold">IE (Ricordi, short)</th>
                    <th className="primary-color bold">IE (Ellipse)</th>
                    <th className="primary-color bold">IE (Spinacle)</th>
                    <th className="primary-color bold">% (purity)</th>
                </tr>
            </thead>

            <tbody>
            {
                this.props.items.map((item, i) => <tr key={i} className={i === this.props.selectedItem ? "active" : ""}>
                    <td onClick={() => this.props.onSelect(i)}>
                        <TruncatedText text={item.name} />
                    </td>
                    <td>{item.result.maskStatistics.isletCount}</td>
                    <td>{item.result.maskStatistics.ieRicordiShort.toFixed(2)}</td>
                    <td>{item.result.maskStatistics.ieEllipse.toFixed(2)}</td>
                    <td>{item.result.maskStatistics.ieSpinacle.toFixed(2)}</td>
                    <td>{(item.result.maskStatistics.purity * 100).toFixed(0)}</td>
                </tr>)
            }
            </tbody>
        </table>);
    }
}


export default SimpleComparisonBatchSummary;
