import React from "react";
import Spinner from "react-spinkit";
import {AsyncRequestState} from "../redux/actions";

interface AsyncButtonProps extends React.Props<any> {
    asyncActionState: AsyncRequestState
    className?: string;
    onClick: () => void;
    title?: string;
    type?: "button" | "submit" | "reset";
}

const AsyncButton: React.SFC<AsyncButtonProps> = (props) =>
    <button
        className={props.className}
        disabled={props.asyncActionState === AsyncRequestState.PENDING}
        onClick={props.onClick}
        title={props.title}
        type={props.type || "button"}
    >
        {props.asyncActionState === AsyncRequestState.PENDING && <Spinner fadeIn="none" name="circle" />}{props.children}
    </button>;

export default AsyncButton;
