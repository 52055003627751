export const sleep = (ms: number) => new Promise(resolve => setTimeout(() => resolve(), ms));

interface LockData {
    processId: string;
    timestamp: number;
}

const get = (key: string): LockData | null => {
    const item = window.localStorage.getItem(key);

    if (item === null) {
        return null;
    }

    return JSON.parse(item);
};

const set = (key: string, lockData: LockData | null) => {
    if (lockData === null) {
        window.localStorage.removeItem(key);
    }

    window.localStorage.setItem(key, JSON.stringify(lockData));
};

const isFree = (lockData: LockData | null) => {
    if (lockData === null) {
        return true;
    }

    if (lockData.timestamp + 3000 < Date.now()) {
        return true;
    }

    return false;
};

const isLocked = (lockData: LockData | null, processId: string) => {
    if (lockData === null) {
        return false;
    }

    return lockData.processId === processId;
};

const getProcessId = (): string => {
    let processId = window.sessionStorage.getItem("processId");

    if (processId === null) {
        processId = String(Math.floor(Math.random() * 10000000)) + String(Date.now());
        window.sessionStorage.setItem("processId", processId);
    }

    return processId;
};

export const lock = async (key: string) => {
    const processId = getProcessId();

    if (!isFree(get(key))) {
        if (isLocked(get(key), processId)) {
            return true;
        }

        return false;
    }

    set(key, {
        processId,
        timestamp: Date.now()
    });

    await sleep(400 + 200 * Math.random());

    if (!isLocked(get(key), processId)) {
        return false;
    }

    return true;
};

export const unlock = (key: string) => {
    set(key, null);
};

export const refresh = (key: string) => {
    const current = get(key);

    if (current === null) {
        return;
    }

    set(key, {
        ...current,
        timestamp: Date.now()
    });
};