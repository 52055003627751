import React from "react";

export const EmbeddedFragmentedImageLegend: React.SFC<any> = () => <div className="row">
    <h3>Legend</h3>
    <div className="col-6">
        <div className="legend-description">
            <span className="legend-circle color-white"/> Free islets
        </div>
        <div className="legend-description">
            <span className="legend-circle color-green"/> Embedded islets
        </div>
        <div className="legend-description">
            <span className="legend-circle color-cyan"/> Mantled islets
        </div>
        <div className="legend-description">
            <span className="legend-circle color-red"/> Fragmented islets
        </div>
        <div className="legend-description">
            <span className="legend-circle color-grey"/> Exocrine tissue
        </div>
    </div>
</div>;

export const IsletsExoLegend: React.SFC<any> = () => <div className="row">
    <h3>Legend</h3>
    <div className="col-6">
        <div className="legend-description">
            <span className="legend-circle color-white"/> Islets
        </div>
        <div className="legend-description">
            <span className="legend-circle color-grey"/> Exocrine tissue
        </div>
    </div>
</div>;

