import React, {useEffect} from 'react'
import SimpleComparisonUploader from "../components/upload/SimpleComparisonUploader";
import {useDispatch} from "react-redux";
import {addSimpleComparisonBatch, NewSimpleComparisonBatchPayload, newSimpleComparisonSession} from "../redux/actions";
import { useHistory } from 'react-router-dom';

const SimpleComparison = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(newSimpleComparisonSession());
    }, [dispatch]);

    const onSubmit = (batch: NewSimpleComparisonBatchPayload) => {
        dispatch(addSimpleComparisonBatch(batch));
        history.push("/simple-comparison/results")
    };

    return <div className="card-wrapper">
        <SimpleComparisonUploader onSubmit={onSubmit}/>
    </div>
};

export default SimpleComparison