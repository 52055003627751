import React from "react";

import {Prompt, PromptProps} from "react-router";

interface PromptWrapperState {
    mounted: boolean;
}

/**
 * Workaround for a react-router bug
 */
class PromptWrapper extends React.Component<PromptProps, PromptWrapperState> {
    constructor(props: PromptProps) {
        super(props);
        this.state = {
            mounted: false
        }
    }

    public componentDidMount() {
        this.setState({mounted: true});
    }

    public render() {
        if (!this.state.mounted) {
            return "";
        }

        return <Prompt message={this.props.message} when={this.props.when} />
    }
}

export default PromptWrapper;