import React from "react";

const Contact = (props) => {
    return (
        <div className="card-wrapper">
            <h1>Contact</h1>
            <address>
                Institut klinické a experimentální medicíny<br/>
                Vídeňská 1958/9<br/>
                140 21 Praha 4<br/>
                IČO: 00023001<br/>
                DIČ: CZ00023001<br/><br/>

                David Habart<br/>
                david.habart (at) ikem (dot) cz<br/>
            </address>
        </div>
    );
};

export default Contact;
