import React from "react";

import {noop, zip} from "lodash";

import AnalysisUploadItem, {UploadedImageMetadata} from "./AnalysisUploadItem";
import {UploadListRenderer} from "./UploaderImagesSection";

import {FormRenderer, ImagesSectionRenderer, UploaderContentState, UploaderSubmitHandler} from "../Uploader";

import {Configuration, DefaultApi} from "../../api";
import UploaderFormSection from "../../containers/AnalysisUploaderFormSection";
import Uploader from "../../containers/Uploader";
import UploaderImagesSection from "../../containers/UploaderImagesSection";
import {API_URL, MAX_UPLOAD_RESOLUTION} from "../../env";
import {AnalysisBatchMetadata, AnalysisMaskData} from "../../redux/actions";

export interface SubmittedBatch {
    imagesMetadata: Array<AnalysisMaskData>;
    batchMetadata: AnalysisBatchMetadata;
}

interface AnalysisUploaderProps extends React.Props<AnalysisUploader> {
    disabledFields: string[];
    erasedFields: string[];
    existingFilenames: string[];
    initialValues?: SubmittedBatch;
    onError: (title: string, text: string) => void;
    onStateChanged: (state: UploaderContentState) => void;
    onSubmit: (submittedBatch: SubmittedBatch) => void;
}

class AnalysisUploader extends React.Component<AnalysisUploaderProps> {
    private api = new DefaultApi(new Configuration({basePath: API_URL}));

    public static defaultProps = {
        onStateChanged: noop
    };

    private makeFormRenderer = (props: AnalysisUploaderProps): FormRenderer<AnalysisBatchMetadata> => {
        return (onSubmit, onChange) => <UploaderFormSection
            onStateChanged={onChange}
            onSubmit={onSubmit}
            disabledFields={props.disabledFields}
            erasedFields={props.erasedFields}
            hiddenFields={["dilution", "pelletVolumeMl"]}
            initialValues={this.props.initialValues !== undefined ? this.props.initialValues.batchMetadata : undefined}
        />;
    };

    private findInitialMetadata(name: string): UploadedImageMetadata | undefined {
        if (this.props.initialValues === undefined) {
            return undefined;
        }

        const meta = this.props.initialValues.imagesMetadata.find(i => i.name === name);

        if (meta === undefined) {
            return undefined;
        }

        return {
            isletCountUser: meta!.isletCountUser !== undefined ? String(meta!.isletCountUser): "",
            volumeTableUser: meta!.volumeTableUser !== undefined ? String(meta!.volumeTableUser) : "",
            volumeUser: meta!.volumeUser !== undefined ? String(meta!.volumeUser) : ""
        };
    }

    private uploadListRenderer: UploadListRenderer = items =>
        items.map((item) => [
                <AnalysisUploadItem fileName={item.name} key={item.name} done={item.uploadStatus.done}
                                error={item.uploadStatus.error} selected={item.isSelected}
                                initialState={this.findInitialMetadata(item.name)}/>,
                item.name
            ] as [React.ReactElement<any>, string]
        );

    private imagesSectionRenderer: ImagesSectionRenderer<AnalysisMaskData> = (onChange) => <UploaderImagesSection
        buttonText="Add your segmentations"
        existingFilenames={this.props.existingFilenames}
        hint={<>
            <h4>Upload hint</h4>
            <p>Segmentations of islets created using a user-trusted method and with known islet indices (i.e. count, axes, size, volume)
               are uploaded. They will be interpreted by the algorithm used in Clinical islet isolation for calculation of islet 
               indices from their contours. Islet indices provided by the user and calculated by IsletNet can be downloaded for
               comparison. Requirements for the standard: B&W image (black = non-islets, white = islets).</p>
        </>}
        imageAttributeNames={["Other [IE]", "Table [IE]", "Islet count"]}
        onImagesChange={onChange}
        prefilledImages={
            this.props.initialValues !== undefined
                ? this.props.initialValues.imagesMetadata.map(item => ({...item, imageId: item.maskId}))
                : []
        }
        titleText="Uploaded segmentations"
        uploadListRenderer={this.uploadListRenderer}
        maxImageSizeNote={<>
            <p>Maximum image size: {MAX_UPLOAD_RESOLUTION}&times;{MAX_UPLOAD_RESOLUTION}px.</p>
        </>}
    />;

    private onSubmit: UploaderSubmitHandler<AnalysisMaskData, AnalysisBatchMetadata> =
        async (uploadIds, imagesMetadata, batchMetadata) => {
        if (imagesMetadata.length === 0) {
            this.props.onError("Validation error", "Please, upload at least one mask");
            return false;
        }

        const imagesMetadataWithId = imagesMetadata.map((imageMeta) => ({
            ...imageMeta,
            maskId: imageMeta.maskId || uploadIds[imageMeta.name!]
        }));

        try {
            const responses = await Promise.all(imagesMetadataWithId.map(image => this.api.imageInfo({imageId: image.maskId})));
            const nonGrayscaleMasks = zip(imagesMetadataWithId, responses)
                .filter(([_, response]) => !response!.grayscale)
                .map(([image, _]) => image!.name);

            const highResolutionResults = zip(imagesMetadataWithId, responses)
                .filter(([_, response]) => response!.resolution.some(dim => dim > MAX_UPLOAD_RESOLUTION))
                .map(([image, _]) => image!.name);

            if (nonGrayscaleMasks.length > 0) {
                this.props.onError(
                    "Validation error",
                    "The following images are not grayscale: " + nonGrayscaleMasks.join(", ")
                );

                return false;
            } else if (highResolutionResults.length > 0) {
                this.props.onError(
                    "Validation error",
                    `The resolution of following images is too large (larger than ${MAX_UPLOAD_RESOLUTION}px): `
                        + highResolutionResults.join(", ")
                );

                return false;
            } else {
                this.props.onSubmit({batchMetadata, imagesMetadata: imagesMetadataWithId});
                return true;
            }
        } catch(e) {
            this.props.onError(
                "Application error",
                "There was an error while submitting data to the server"
            );

            return false;
        }
    };

    public render() {
        return <Uploader
            onStateChanged={this.props.onStateChanged}
            onSubmit={this.onSubmit}
            formRenderer={this.makeFormRenderer(this.props)}
            imagesSectionRenderer={this.imagesSectionRenderer}
        />;
    }
}

export default AnalysisUploader;
