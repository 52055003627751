import React from 'react';
import {MaskStatistics} from "../../api";
import {mean, zeroToDash} from "./helpers"

interface IsolationBatchHeaderProps {
    includedStatistics: MaskStatistics[];
    itemCount: number;
}

const IsolationBatchHeader: React.SFC<IsolationBatchHeaderProps> = (props) => {
    return <tr>
        <th title="Arithmetic mean, n=images included/all images.">Batch summary (mean, n = {props.includedStatistics.length}/{props.itemCount}):</th>
        <th title="Number of islets per image equal or larger then the Minimum islet size."><span className="primary-color bold">
            {zeroToDash(mean(props.includedStatistics.map((item) => item.isletCount)).toFixed(0))}
        </span> islets
        </th>
        <th title="Ricordi et al, Acta Diabetologica Latina 27: 185–95, 1990."><span className="primary-color bold">
            {zeroToDash(mean(props.includedStatistics.map((item) => item.ieTable)).toFixed(0))}
        </span> IE (table)
        </th>
        <th title="V=(4/3)π(a/2)*(b/2)^2; a, long axis; b, short axis."><span className="primary-color bold">
            {zeroToDash(mean(props.includedStatistics.map((item) => item.ieEllipse)).toFixed(0))}
        </span> IE (ellipse)
        </th>
        <th title="Percentage of islets' area from all tissue area."><span className="primary-color bold">
            {zeroToDash((mean(props.includedStatistics.map((item) => item.purity)) * 100).toFixed(0))}
        </span> % (purity)</th>
    </tr>
};

export default IsolationBatchHeader;
