import React from "react";

import {sum} from "lodash";

import AsyncButton from "./AsyncButton";
import Icon from "./Icon";

import {AnalysisBatchMetadata, AsyncRequestState} from "../redux/actions";
import {meanEllipse, meanIsletCount, meanTable, SummaryTableProps} from "./results/SummaryTableUtils"

export interface AnalysisSummaryProps extends SummaryTableProps<AnalysisBatchMetadata> {
    onCloseSession: () => void;
    onZIP: () => void;
    zipDownloadState: AsyncRequestState;
}

const AnalysisSummary: React.SFC<AnalysisSummaryProps> = (props) => {
    const numFiles = sum(Object.values(props.batches).map(b => b.items.length));
    const fileSize = (numFiles * 0.21).toFixed(0);

    return (
        <div className="">
            <div className="">
                <h2>Overall Summary ({props.sessionCode})</h2>
                {<AnalysisSummaryTable sessionCode={props.sessionCode} batches={props.batches}/>}

                <h2>Download Results</h2>

                <div className="download-data-btns">
                    <AsyncButton className="link" asyncActionState={props.zipDownloadState} onClick={props.onZIP}
                                 title="Uploaded masks, histogram, image-wise XLSX, islet-wise XLSX" >
                        <Icon name="file-o"/>Selected files from all batches in structured ZIP ({fileSize} MB)
                    </AsyncButton>
                </div>

                <button onClick={props.onCloseSession}>Close session</button>
            </div>
        </div>
    );
};

const AnalysisSummaryTable: React.SFC<SummaryTableProps<AnalysisBatchMetadata>> = (props) => {
    const totalIslets = sum(Object.values(props.batches).filter(b => b.included).map(meanIsletCount)).toFixed(0);
    const totalEllipse = sum(Object.values(props.batches).filter(b => b.included).map(meanEllipse)).toFixed(0);
    const totalTable = sum(Object.values(props.batches).filter(b => b.included).map(meanTable)).toFixed(0);

    return (
        <table className="results-table">
            <thead>
            <tr>
                <th>Summary over included batches:</th>
                <th title="TODO TH description"><span className="primary-color bold">{totalIslets}</span> islets</th>
                <th title="TODO TH description 2"><span className="primary-color bold">{totalTable}</span> IE (table)</th>
                <th title="TODO TH description 3"><span className="primary-color bold">{totalEllipse}</span> IE (ellipse)</th>
                <th title="TODO TH description 3">Samples used</th>
            </tr>
            </thead>

            <tbody>
            {
                Object.entries(props.batches)
                    .sort(([a, _A], [b, _B]) => a.localeCompare(b))
                    .map(([batchId, batch], i) => <tr key={i}>
                    <td>{batchId}</td>
                    <td>{meanIsletCount(batch).toFixed(0)}</td>
                    <td>{meanTable(batch).toFixed(0)}</td>
                    <td>{meanEllipse(batch).toFixed(0)}</td>
                    <td>{sum(batch.items.map(item => item.included ? 1 : 0))}/{batch.items.length}</td>
                </tr>)
            }
            </tbody>
        </table>
    )
};

export default AnalysisSummary;
