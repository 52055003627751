import React from 'react';

import {concat, flatten, noop} from 'lodash';

import Pix2PixBatchSummary from './Pix2PixBatchSummary';
import {defaultTabState, MenuItem, TabState} from "./ResultsContent";
import {Pix2PixItemResult} from "./types";

import LoadingScreen from "../LoadingScreen";
import ResultsContent from "../../containers/results/ResultsContent";

const Pix2PixImageLegend: React.SFC<any> = () => <div className="row">
    <h3>Legend</h3>
    <div className="col-6">
        <div className="legend-description">
            <span className="legend-circle color-red"/><span className="legend-circle color-green"/> Islets by
            Standard
        </div>
        <div className="legend-description">
            <span className="legend-circle color-red"/><span className="legend-circle color-white"/> Islets by
            IsletNet
        </div>
    </div>
    <div className="col-6">
        <div className="legend-description">
            <span className="legend-circle color-red"/> True positive pixels
        </div>
        <div className="legend-description">
            <span className="legend-circle color-green"/> False negative pixels
        </div>
        <div className="legend-description">
            <span className="legend-circle color-white"/> False positive pixels
        </div>
    </div>
</div>;

const Pix2PixHistogramLegend: React.SFC<any> = () => <div className="row">
    <h3>Legend</h3>
    <div className="col-6">
        <div className="legend-description">
            <span className="legend-circle color-red"/> IsletNet
        </div>
        <div className="legend-description">
            <span className="legend-circle color-white"/> Standard
        </div>
    </div>
</div>;

interface Pix2PixBatchResultsProps {
    batchId: string;
    imagesLoading: boolean;
    items: Pix2PixItemResult[];
    onSelect: (itemIndex: number, batchId: string) => void;
    onStartLoading: (imageIds: string[]) => void;
    onTabStateChanged: (state: TabState) => void;
    selectedItem: number;
    tabState: TabState;
}

class Pix2PixBatchResults extends React.Component<Pix2PixBatchResultsProps> {
    public static defaultProps = {
        onSelect: noop,
        onTabStateChanged: noop,
        tabState: defaultTabState
    };

    private onSelect = (item: number) => {
        this.props.onSelect(item, this.props.batchId);
    };

    private onNext = () => this.props.onSelect(this.props.selectedItem + 1, this.props.batchId);
    private onPrevious = () => this.props.onSelect(this.props.selectedItem - 1, this.props.batchId);

    public static getLeftMenuItems(items: Pix2PixItemResult[], item: number): MenuItem[] {
        const result = items[item].result;

        return [
            {
                hotkey: "1",
                id: result.isletMaskComparisonId,
                legend: <Pix2PixImageLegend/>,
                title: "Compare islets"
            },
            {
                hotkey: "2",
                id: result.isletMaskComparisonEllipsesId,
                legend: <Pix2PixImageLegend/>,
                title: "Ellipses"
            },
            {
                extendedDescription: "Red columns represent values from Isletnet, grey columns are based on numbers supplied by the user",
                hotkey: "3",
                id: result.histogramValidationId,
                isZoomable: false,
                legend: <Pix2PixHistogramLegend/>,
                title: "Histogram"
            },
            {
                hotkey: "4",
                id: result.exoMaskComparisonId,
                legend: <Pix2PixImageLegend/>,
                title: "Compare exo"
            }
          ];
    }

    public static getRightMenuItems(items: Pix2PixItemResult[], item: number): MenuItem[] {
        const selectedItem = items[item];
        const result = selectedItem.result;

        return [
            {
                hotkey: "5",
                id: result.maskCombinedId,
                title: "Segmentation"
            },
            {
                hotkey: "6",
                id: selectedItem.userMaskId,
                title: "GT"
            },
            {
                hotkey: "7",
                id: result.contoursId,
                title: "Contours"
            },
            {
                hotkey: "8",
                id: result.ellipsesId,
                title: "Ellipses"
            },
            {
                hotkey: "9",
                id: result.imageContoursExoId,
                title: "Contours Exo"
            },
            {
                id: selectedItem.imageId,
                title: "Original"
            },
        ];
    }

    public static getDisplayedImages(items: Pix2PixItemResult[]) {
        return flatten(items.map( (value, index) =>
                concat(Pix2PixBatchResults.getLeftMenuItems(items, index), Pix2PixBatchResults.getRightMenuItems(items, index))
                    .map(i => i.id)))
    }

    public componentDidMount() {
        this.props.onStartLoading(Pix2PixBatchResults.getDisplayedImages(this.props.items));
    }

    public componentDidUpdate() {
        this.props.onStartLoading(Pix2PixBatchResults.getDisplayedImages(this.props.items));
    }

    public render() {
        const items = this.props.items.map(item => ({
            included: item.included,
            name: item.userMaskName,
            results: item.result
        }));

        return (
            <div>
                {
                    !this.props.imagesLoading
                        ? <ResultsContent tabState={this.props.tabState} onTabStateChanged={this.props.onTabStateChanged}
                                          leftMenuItems={Pix2PixBatchResults.getLeftMenuItems(this.props.items, this.props.selectedItem)}
                                          rightMenuItems={Pix2PixBatchResults.getRightMenuItems(this.props.items, this.props.selectedItem)}
                                          onPrevious={this.onPrevious} onNext={this.onNext} fileName={this.props.items[this.props.selectedItem].name}/>
                        : <LoadingScreen text="Loading images"/>
                }
                <Pix2PixBatchSummary batchId={this.props.batchId} items={items}
                                     selectedItem={this.props.selectedItem} onSelect={this.onSelect}/>
            </div>
        );
    }
}

export default Pix2PixBatchResults;
