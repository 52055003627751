import React from 'react';
import ResultsContent from "../../containers/results/ResultsContent";
import LoadingScreen from "../LoadingScreen";
import {SimpleComparisonItemResult} from "./types";
import {EmbeddedFragmentedImageLegend, IsletsExoLegend} from "./legends";
import SimpleComparisonBatchSummary from './SimpleComparisonBatchSummary';
import {TabState} from "./ResultsContent";

interface Props {
    items: SimpleComparisonItemResult[]
    selectedItem: number
    onSelect: (item: number) => void
    imagesLoading: boolean
    tabState: TabState
    onTabStateChanged: (state: TabState) => void
}

const SimpleComparisonBatchResults = (props: Props) => {
    const item = props.items[props.selectedItem];

    const leftMenuItems = [
        {
            hotkey: "1",
            id: item.result.contoursId,
            title: "Contours"
        },
        {
            hotkey: "2",
            id: item.result.ellipsesId,
            title: "Ellipses"
        },
        {
            hotkey: "3",
            id: item.imageId,
            title: "Original"
        },
        {
            hotkey: "4",
            id: item.result.contoursLabeledId,
            title: "IsletIDs"
        },
        {
            hotkey: "5",
            id: item.result.imageContoursExoId,
            title: "Exocrine tissue"
        },
    ];

    const rightMenuItems = [
        {
            hotkey: "6",
            id: item.result.maskCombinedId,
            legend: <IsletsExoLegend/>,
            title: "Islets + Exo"
        },
        {
            hotkey: "7",
            id: item.result.maskIsletsId,
            title: "Islets"
        },
        {
            hotkey: "8",
            id: item.result.maskExoColorId,
            isExperimental: true,
            legend: <EmbeddedFragmentedImageLegend/>,
            title: "Emb+Frgm"
        },
        {
            hotkey: "9",
            id: item.result.histogramId,
            isZoomable: false,
            title: "Histogram"
        },
    ];

    return (
        <div>
            {
                !props.imagesLoading
                    ? <ResultsContent tabState={props.tabState} onTabStateChanged={props.onTabStateChanged}
                                      leftMenuItems={leftMenuItems}
                                      rightMenuItems={rightMenuItems}
                                      onPrevious={() => props.onSelect(props.selectedItem - 1)}
                                      onNext={() => props.onSelect(props.selectedItem + 1)} fileName={item.name} />
                    : <LoadingScreen text="Loading images"/>
            }
            <SimpleComparisonBatchSummary items={props.items} selectedItem={props.selectedItem} onSelect={props.onSelect}/>
        </div>
    );
};

export default SimpleComparisonBatchResults;