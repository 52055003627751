import {flatten, fromPairs, sum} from "lodash";
import {createSelector} from "reselect";

import {AsyncRequestState, StoreShape} from "./actions";

import {
    mergeAnalysisBatchesWithResults,
    mergeBatchesWithResults,
    mergePix2PixBatchesWithResults
} from "../components/results/helpers";
import {SimpleComparisonItemResult} from "../components/results/types";
import {ComparisonSummaryRequest, ComparisonSummaryRequestItem} from "../api/models";

export const isolationBatchData = (state: StoreShape) => state.isolationBatches;

export const isolationResults = (state: StoreShape) => state.isolationResults;

export const analysisBatchData = (state: StoreShape) => state.analysisBatches;

export const analysisResults = (state: StoreShape) => state.analysisResults;

export const pix2pixBatchData = (state: StoreShape) => state.pix2pixBatches;

export const pix2pixResults = (state: StoreShape) => state.pix2pixResults;

export const simpleComparisonBatchData = (state: StoreShape) => state.simpleComparisonBatches;

export const simpleComparisonResults = (state: StoreShape) => state.simpleComparisonResults;

export const errorMessages = (state: StoreShape) => state.errorMessages;

export const isolationBatchIds = createSelector(
    isolationBatchData,
    batches => Object.values(batches).map(batch => batch.metadata.batchId)
);

export const analysisBatchIds = createSelector(
    analysisBatchData,
    batches => Object.values(batches).map(batch => batch.metadata.batchId)
);

export const pix2pixBatchIds = createSelector(
    pix2pixBatchData,
    batches => Object.values(batches).map(batch => batch.metadata.batchId)
);

export const lastErrorMessage = createSelector(
    errorMessages,
    errors => errors.length > 0 ? errors[errors.length - 1] : undefined
);

export const isolationFilenames = createSelector(
    isolationBatchData,
    batches => flatten(Object.values(batches).map(batch => batch.items.map(i => i.name)))
);

export const isolationImageCount = createSelector(isolationFilenames, names => names.length);

export const pix2pixFilenames = createSelector(
    pix2pixBatchData,
    batches => flatten(
        Object.values(batches)
            .map(b => b.items.map(i => i.name))
            .concat(Object.values(batches).map(b => b.items.map(i => i.userMaskName)))
    )
);

export const pix2pixImageCount = createSelector(pix2pixFilenames, names => names.length);

export const analysisFilenames = createSelector(
    analysisBatchData,
    batches => flatten(Object.values(batches).map(batch => batch.items.map(i => i.name)))
);

export const analysisImageCount = createSelector(analysisFilenames, names => names.length);

export const isolationResultData = createSelector(
    isolationBatchData,
    isolationResults,
    (batches, results) => mergeBatchesWithResults(
        fromPairs(Object.entries(results).map(([id, data]) => [id, data.result!])),
        batches
    )
);

export const simpleComparisonBatchCount = createSelector(simpleComparisonBatchData, batches => batches.batches.length);

export const simpleComparisonImageCount = createSelector(simpleComparisonBatchData, batches => sum(batches.batches.map(batch => batch.items.length)));

export const isolationSubmissionStatus = createSelector(
    isolationBatchData,
    (batches) => {
        if (Object.values(batches).some(b => b.items.some(i => i.isolationRequestState === AsyncRequestState.FAILED))) {
            return AsyncRequestState.FAILED;
        }

        if (Object.values(batches).some(b => b.items.some(i => i.isolationRequestState === AsyncRequestState.PENDING))) {
            return AsyncRequestState.PENDING;
        }

        if (Object.values(batches).every(b => b.items.every(i => i.isolationRequestState === AsyncRequestState.DONE))) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const isolationStatus = createSelector(
    isolationBatchData,
    isolationResults,
    (batches, results) => {
        if (Object.values(results).some(r => r.state === AsyncRequestState.FAILED)) {
            return AsyncRequestState.FAILED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.EXPIRED)) {
            return AsyncRequestState.EXPIRED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.PENDING)) {
            return AsyncRequestState.PENDING;
        }

        if (Object.values(batches).some(b => b.items.some(i => i.isolationRequestId === undefined))) {
            return AsyncRequestState.PENDING;
        }

        const requestIds = flatten(Object.values(batches).map(b => b.items.map(i => i.isolationRequestId!)));
        if (requestIds.every(id => results[id] !== undefined && results[id].state === AsyncRequestState.DONE)) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const analysisResultData = createSelector(
    analysisBatchData,
    analysisResults,
    (batches, results) => mergeAnalysisBatchesWithResults(
        fromPairs(Object.entries(results).map(([id, data]) => [id, data.result!])),
        batches
    )
);

export const analysisSubmissionStatus = createSelector(
    analysisBatchData,
    (batches) => {
        if (Object.values(batches).some(b => b.items.some(i => i.requestState === AsyncRequestState.FAILED))) {
            return AsyncRequestState.FAILED;
        }

        if (Object.values(batches).some(b => b.items.some(i => i.requestState === AsyncRequestState.PENDING))) {
            return AsyncRequestState.PENDING;
        }

        if (Object.values(batches).every(b => b.items.every(i => i.requestState === AsyncRequestState.DONE))) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const analysisStatus = createSelector(
    analysisBatchData,
    analysisResults,
    (batches, results) => {
        if (Object.values(results).some(r => r.state === AsyncRequestState.FAILED)) {
            return AsyncRequestState.FAILED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.EXPIRED)) {
            return AsyncRequestState.EXPIRED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.PENDING)) {
            return AsyncRequestState.PENDING;
        }

        if (Object.values(batches).some(b => b.items.some(i => i.requestId === undefined))) {
            return AsyncRequestState.PENDING;
        }

        const requestIds = flatten(Object.values(batches).map(b => b.items.map(i => i.requestId!)));
        if (requestIds.every(id => results[id] !== undefined && results[id].state === AsyncRequestState.DONE)) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const pix2pixResultData = createSelector(
    pix2pixBatchData,
    pix2pixResults,
    (batches, results) => mergePix2PixBatchesWithResults(
        fromPairs(Object.entries(results).map(([id, data]) => [id, data.result!])),
        batches
    )
);

export const pix2pixSubmissionStatus = createSelector(
    pix2pixBatchData,
    (batches) => {
        if (Object.values(batches).some(b => b.items.some(i => i.requestState === AsyncRequestState.FAILED))) {
            return AsyncRequestState.FAILED;
        }

        if (Object.values(batches).some(b => b.items.some(i => i.requestState === AsyncRequestState.PENDING))) {
            return AsyncRequestState.PENDING;
        }

        if (Object.values(batches).every(b => b.items.every(i => i.requestState === AsyncRequestState.DONE))) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const pix2pixStatus = createSelector(
    pix2pixBatchData,
    pix2pixResults,
    (batches, results) => {
        if (Object.values(results).some(r => r.state === AsyncRequestState.FAILED)) {
            return AsyncRequestState.FAILED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.EXPIRED)) {
            return AsyncRequestState.EXPIRED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.PENDING)) {
            return AsyncRequestState.PENDING;
        }

        if (Object.values(batches).some(b => b.items.some(i => i.requestId === undefined))) {
            return AsyncRequestState.PENDING;
        }

        const requestIds = flatten(Object.values(batches).map(b => b.items.map(i => i.requestId!)));
        if (requestIds.every(id => results[id] !== undefined && results[id].state === AsyncRequestState.DONE)) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const pix2pixZipRequestData = createSelector(
    pix2pixBatchData,
    batches => ({
        batches: Object.values(batches).map(batch => ({
            batchMetadata: {
                batchId: batch.metadata.batchId,
                camera: batch.metadata.camera,
                microscope: batch.metadata.microscope,
                objective: batch.metadata.objective,
                operator: batch.metadata.operator,
                sessionCode: batch.metadata.sessionCode,
            },
            requests: batch.items.map(item => ({
                imageFilename: item.name,
                included: item.included,
                maskFilename: item.userMaskName,
                requestId: item.requestId!
            }))
        }))
    })
);

export const simpleComparisonSubmissionStatus = createSelector(
    simpleComparisonBatchData,
    (batches) => {
        if (batches.batches.some(b => b.items.some(i => i.requestState === AsyncRequestState.FAILED))) {
            return AsyncRequestState.FAILED;
        }

        if (batches.batches.some(b => b.items.some(i => i.requestState === AsyncRequestState.PENDING))) {
            return AsyncRequestState.PENDING;
        }

        if (batches.batches.every(b => b.items.every(i => i.requestState === AsyncRequestState.DONE))) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const simpleComparisonStatus = createSelector(
    simpleComparisonBatchData,
    simpleComparisonResults,
    (batches, results) => {
        if (Object.values(results).length === batches.batches.flatMap(b => b.items).length && Object.values(results).every(r => r.state === AsyncRequestState.FAILED)) {
            return AsyncRequestState.FAILED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.EXPIRED)) {
            return AsyncRequestState.EXPIRED;
        }

        if (Object.values(results).some(r => r.state === AsyncRequestState.PENDING)) {
            return AsyncRequestState.PENDING;
        }

        if (batches.batches.some(b => b.items.some(i => i.requestId === undefined))) {
            return AsyncRequestState.PENDING;
        }

        const requestIds = flatten(batches.batches.map(b => b.items.map(i => i.requestId!)));
        if (requestIds.every(id => results[id] !== undefined && (results[id].state === AsyncRequestState.DONE || results[id].state === AsyncRequestState.FAILED))) {
            return AsyncRequestState.DONE;
        }

        return AsyncRequestState.NONE;
    }
);

export const simpleComparisonFailures = createSelector(
    simpleComparisonBatchData,
    simpleComparisonResults,
    (batches, results) => batches.batches.flatMap(b => b.items).filter(i => i.requestId !== undefined && results[i.requestId] !== undefined && results[i.requestId].state === AsyncRequestState.FAILED)
);

export const simpleComparisonResultData = createSelector(simpleComparisonBatchData, simpleComparisonResults, (batches, results): SimpleComparisonItemResult[] => {
    const items = batches.batches.flatMap(batch => batch.items);
    return items
        .filter(item => item.requestId !== undefined && results[item.requestId] !== undefined && results[item.requestId].state === AsyncRequestState.DONE)
        .map(item => ({...item, result: results[item.requestId!]!.result!}))
});

export const simpleComparisonSummaryRequest = createSelector(simpleComparisonBatchData, simpleComparisonResults, (batches, results): ComparisonSummaryRequest => {
    const requests: ComparisonSummaryRequestItem[] = batches.batches.flatMap((batch => batch.items.filter(item => item.requestId !== undefined && results[item.requestId] !== undefined && results[item.requestId].state === AsyncRequestState.DONE).map(item => ({
        imageFilename: item.name,
        isletCountUser: item.isletCountUser,
        methodUser: item.methodUser,
        purityUser: item.purityUser,
        requestId: item.requestId!,
        volumeUserIe: item.volumeUserIe
    }))));

    return {requests};
});
