import React from "react";

import {PositionProperty} from "csstype";
import {noop} from "lodash";

import LoadingScreen from "./LoadingScreen";
import ZoomableStack, {DEFAULT_ZOOM_INFO, ZoomInfo} from "./ZoomableStack";
import CustomPoint from "./custom-lines/CustomPoint";
import CustomLine from "./custom-lines/CustomLine";
import {CustomMenuData} from "./custom-lines/CustomPointMenu";
import CustomPointMenu from "./custom-lines/CustomPointMenu";
import {connect} from "react-redux";
import {
  editBatch,
  IsolationBatchMetadata,
  IsolationImageData,
  StoreShape
} from "../redux/actions";
import {isolationResultData} from "../redux/selectors";
import {Point} from "../api";
import EscDialog from "./custom-lines/EscDialog";

interface ZoomedImageInnerState {
  image: HTMLImageElement | null;
  loaded: boolean;
  pointsCoordinates: Array<Point>;
  allLines: Array<any>;
  showMenu: boolean;
  contextMenuData: Point;
  mousePosition: Point;
  imageIndex?: number;
  forceHideLines: boolean;
}

interface ZoomedImageProps {
  batches: {
    [key: string]: { items: Array<any>, metadata: any };
  };
  image: string;
  batchId?: string;
  imageName?: string;
  initialZoom?: ZoomInfo;
  maxScale: number;
  isZoomAdjustable?: boolean;
  onZoomChanged?: (info: ZoomInfo) => void;
  zoom?: ZoomInfo;
  onBatchEdited: (batchMetadata: IsolationBatchMetadata, images: Array<IsolationImageData>, editedBatchId: string, forceChange: boolean) => void;
  onLineChanged?: (onLineChanged: boolean) => void;
  isLineChanged?: boolean;
  onEsqClose?: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onEsqQuit?: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showEscMenu?: boolean;
  showCustomLines?: boolean;
}

class ZoomedImage extends React.Component<ZoomedImageProps, ZoomedImageInnerState> {
  private readonly canvasRef: React.RefObject<any>;

  constructor(props: ZoomedImageProps) {
    super(props);
    this.state = {
      image: null,
      loaded: false,
      pointsCoordinates: [],
      allLines: [],
      showMenu: false,
      contextMenuData: {x: 0, y: 0},
      mousePosition: {x: 0, y: 0},
      imageIndex: undefined,
      forceHideLines: false
    };
    this.canvasRef = React.createRef();
  }

  public static defaultProps = {
    isZoomAdjustable: true
  };

  public componentDidMount() {
    const image = new Image();

    image.onload = () => {
      this.setState({
        image,
        loaded: true
      });
    };

    image.src = this.props.image;

    this.loadLines();
  }

  private loadLines() {
    if (!this.props.batchId) {
      return;
    }

    const batch = this.props.batches[this.props.batchId];
    if (!batch) {
      this.setState({allLines: []})
      this.setState({forceHideLines: true})
      return;
    }

    const index = batch.items.findIndex((i: any) => i.name === this.props.imageName);
    this.setState({imageIndex: index});
    if (index === -1) {
      this.setState({allLines: []})
      this.setState({forceHideLines: true})
      return;
    }

    const item = batch.items[index]
    const previousLines = (item.userCutLines) ? item.userCutLines : item.result.userCutLines;
    if (previousLines) {
      this.setState({allLines: previousLines})
    }
  }

  private resetLines() {
    if (!this.props.batchId || this.state.imageIndex === undefined || this.state.imageIndex === -1) {
      return;
    }

    const batch = this.props.batches[this.props.batchId];
    const index = this.state.imageIndex
    const item = batch.items[index]
    let previousLines = item.result.userCutLines;

    if (!previousLines) {
      this.updateLines([]);
      return;
    }

    if (index !== -1) {
      this.updateLines(previousLines);
    }
  }

  private submitLines() {
    if (this.props.batchId && this.state.imageIndex !== undefined && this.state.imageIndex !== -1) {
      const batch = this.props.batches[this.props.batchId]
      batch.items[this.state.imageIndex].userCutLines = this.state.allLines
      this.props.onBatchEdited(batch.metadata, batch.items, this.props.batchId, true);
    }
  }

  private addNewPointCoordinates(point: Point) {
    if (!this.props.showCustomLines || this.state.forceHideLines) {
      return;
    }

    const newComponents = [...this.state.pointsCoordinates, {x: Math.floor(point.x), y: Math.floor(point.y)}];
    this.setState({pointsCoordinates: newComponents});
  }

  private updateLines(newLines: any[]) {
    if (!this.props.batchId || this.state.imageIndex === undefined || this.state.imageIndex === -1) {
      return;
    }

    const batch = this.props.batches[this.props.batchId]
    batch.items[this.state.imageIndex].userCutLines = newLines
    this.props.onBatchEdited(batch.metadata, batch.items, this.props.batchId, false);
    this.detectLineChanges(newLines)
  }

  private relativeCoordinates(e: React.MouseEvent<HTMLDivElement, MouseEvent>, translation: Point, scale: number): Point {
    const targetBounds = this.canvasRef.current.getBoundingClientRect();
    return {
      x: (translation.x * -1 + e.clientX - targetBounds.left) / scale,
      y: (translation.y * -1 + e.clientY - targetBounds.top) / scale
    };
  }

  private showContextMenu(data: CustomMenuData | Point, translation: Point, scale: number) {
    this.setState({showMenu: true});
    this.setState({
      contextMenuData: {
        ...data,
        x: (translation.x + data.x * scale),
        y: (translation.y + data.y * scale)
      }
    });
  }

  private deleteLine(data?: { lineId: number; }) {
    if (!data) {
      this.setState({allLines: []});
      this.updateLines([]);
      return;
    }

    const linesUpdate = [...this.state.allLines];
    linesUpdate.splice(data.lineId, 1);
    this.setState({allLines: linesUpdate});
    this.updateLines(linesUpdate);
  }

  private renderPoints(translation: Point, scale: number): any[] {
    if (!this.props.showCustomLines || this.state.forceHideLines) {
      return [];
    }

    let pointsToRender: any[] = [];
    if (this.state.pointsCoordinates.length) {
      this.state.pointsCoordinates.map(
        (t, i) =>
          pointsToRender.push(<CustomPoint translation={translation}
                                           onMenu={() => this.endLineDrawing()}
                                           position={t}
                                           scale={scale}
                                           key={i + "currentPoint"}/>)
      );
    }

    for (let i = 0; i < this.state.allLines.length; i++) {
      this.state.allLines[i].points.map(
        (t: { x: number, y: number }, index: number) =>
          pointsToRender.push(<CustomPoint translation={translation}
                                           onMenu={(data) => this.showContextMenu(data, translation, scale)}
                                           position={t}
                                           scale={scale}
                                           pointId={index}
                                           lineId={i}
                                           key={index + "allLinesPoint" + i}/>)
      );
    }

    return pointsToRender;
  }

  private renderLines(translation: Point, scale: number): any {
    if (!this.props.showCustomLines || this.state.forceHideLines) {
      return [];
    }

    let linesToRender: any[] = [];
    if (this.state.pointsCoordinates.length) {
      let lastPointCurrent: { x: number, y: number };
      this.state.pointsCoordinates.map(
        (t, i) => {
          if (lastPointCurrent) {
            linesToRender.push(<CustomLine translation={translation}
                                           position1={lastPointCurrent}
                                           position2={t}
                                           scale={scale}
                                           key={i + "currentLine"}/>);
          }
          lastPointCurrent = t;
          return linesToRender;
        }
      );
    }

    for (let i = 0; i < this.state.allLines.length; i++) {
      let lastPointAll: { x: number, y: number };
      this.state.allLines[i].points.map(
        (t: { x: number, y: number }, index: number) => {
          if (lastPointAll) {
            linesToRender.push(<CustomLine translation={translation}
                                           position1={lastPointAll}
                                           position2={t}
                                           scale={scale}
                                           key={index + "allLinesLine" + i}/>);
          }
          lastPointAll = t;
          return linesToRender;
        });
    }

    return linesToRender;
  }

  private _onMouseMove(point: Point) {
    this.setState({mousePosition: {x: point.x, y: point.y}});
  }

  private endLineDrawing() {
    if (this.state.pointsCoordinates.length < 2) {
      this.setState({pointsCoordinates: []});
      this.updateLines(this.state.allLines)
      return;
    }

    const newLines = [...this.state.allLines, {"points": this.state.pointsCoordinates}];
    this.setState({allLines: newLines});
    this.setState({pointsCoordinates: []});
    this.updateLines(newLines)
  }

  private detectLineChanges(currentLines: any[]) {
    if (!this.props.batchId || !this.props.onLineChanged || this.state.imageIndex === undefined || this.state.imageIndex === -1) {
      return;
    }

    const batch = this.props.batches[this.props.batchId];
    const item = batch.items[this.state.imageIndex]
    let previousLines = item.result.userCutLines;

    if (previousLines === undefined) {
      previousLines = []
    }

    if (previousLines.length !== currentLines.length) {
      this.props.onLineChanged(true)
      return;
    }

    let linesFound = 0
    for (const previousLine of previousLines) {
      for (const currentLine of currentLines) {
        if (previousLine.points[0].x === currentLine.points[0].x) {
          linesFound++;
          break;
        }
      }
    }
    if (linesFound !== previousLines.length) {
      this.props.onLineChanged(true)
      return;
    }

    this.props.onLineChanged(false)
  }

  public render() {
    let content;

    if (!this.state.loaded) {
      content = <LoadingScreen/>;
    } else {
      const indicatorStyle = (this.props.isZoomAdjustable && this.props.zoom) ? {
        color: "white",
        left: "1em",
        position: "absolute" as PositionProperty,
        top: "1em",
        zIndex: 999
      } : {
        display: "none"
      };

      content = <ZoomableStack
        contentSize={{width: this.state.image!.width, height: this.state.image!.height}}
        maxScale={this.props.maxScale}
        onChange={this.props.isZoomAdjustable ? this.props.onZoomChanged! : noop}
        zoom={this.props.isZoomAdjustable ? this.props.zoom : DEFAULT_ZOOM_INFO}
      >{(translation, scale, viewportSize) =>
        <React.Fragment>
          <div className="layer"
               style={{
                 position: "relative"
               }}
          >
            <div
              ref={this.canvasRef}
              onMouseMove={(e) => this._onMouseMove(this.relativeCoordinates(e, translation, scale))}
              onClick={(e) => this.addNewPointCoordinates(this.relativeCoordinates(e, translation, scale))}
              onContextMenu={(e) => {
                e.preventDefault();
                if (!this.state.pointsCoordinates.length) {
                  this.showContextMenu(this.relativeCoordinates(e, translation, scale), translation, scale)
                  return;
                }
                this.endLineDrawing()
              }}
              style={{
                overflow: "hidden",
                position: "relative",
                ...viewportSize
              }}>
              <img
                alt=""
                src={this.props.image}
                width={this.state.image!.width * scale}
                height={this.state.image!.height * scale}
                style={{
                  left: translation.x,
                  position: "absolute",
                  top: translation.y
                }}
              />
              {this.renderLines(translation, scale)}
              {this.state.pointsCoordinates.length > 0 &&
                  <CustomLine translation={translation}
                              position1={this.state.pointsCoordinates[this.state.pointsCoordinates.length - 1]}
                              position2={this.state.mousePosition}
                              scale={scale}/>}
              {this.state.pointsCoordinates.length > 0 &&
                  <CustomPoint translation={translation}
                               position={this.state.mousePosition}
                               scale={scale}
                               blockContext={true}
                               blockAnimation={true}/>}
            </div>
            {this.renderPoints(translation, scale)}
          </div>
          {this.props.isLineChanged && <button onClick={() => this.submitLines()}
                                               style={{
                                                 position: "absolute",
                                                 bottom: "2rem",
                                                 left: "50%",
                                                 transform: "translate(-50%, 0)",
                                                 boxShadow: "0px 24px 98px 0px rgb(0 0 0 / 30%)"
                                               }}
          ><p
              style={{
                margin: 0,
                fontSize: "0.8rem"
              }}>
              Submit Lines
          </p>
          </button>}
          <div className="layer" style={indicatorStyle}>
            {this.props.zoom && this.props.zoom.scale.toFixed(1)}x
          </div>
        </React.Fragment>
      }</ZoomableStack>;
    }


    return <div className="zoomed-image">
      {content}
      {this.state.showMenu && <CustomPointMenu contextMenuData={this.state.contextMenuData}
                                               onDelete={(e) => this.deleteLine(e)}
                                               onBgClick={() => this.setState({showMenu: false})}/>}
      {this.props.showEscMenu && this.props.onEsqClose && this.props.onEsqQuit &&
          <EscDialog
              onClose={(e) => {
                if (this.props.onEsqClose) {
                  this.props.onEsqClose(e)
                }
              }}
              onSubmit={() => this.submitLines()}
              onQuit={(e) => {
                if (this.props.onEsqQuit) {
                  this.resetLines()
                  this.props.onEsqQuit(e)
                }
              }}/>}
    </div>;
  }
}

export default connect(
  (state: StoreShape) => ({
    batches: isolationResultData(state)
  }),
  (dispatch) => ({
    onBatchEdited: (metadata: IsolationBatchMetadata, items: Array<IsolationImageData>, editedBatchId: string, forceChange: boolean) => {
      dispatch(editBatch({metadata, items, editedBatchId, forceChange}));
    },
  })
)(ZoomedImage);

