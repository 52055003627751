import {connect} from "react-redux";

import Pix2PixUploader from "../../components/upload/Pix2PixUploader";
import {displayErrorMessage, StoreShape} from "../../redux/actions";
import {pix2pixFilenames} from "../../redux/selectors";

export default connect(
    (state: StoreShape) => ({
        existingFilenames: pix2pixFilenames(state)
    }),
    (dispatch) => ({
        onError: (title: string, text: string) => dispatch(displayErrorMessage({
            text,
            title
        }))
    })
)(Pix2PixUploader);
