import {connect} from "react-redux";

import IsolationUploader from "../../components/upload/IsolationUploader";
import {displayErrorMessage, StoreShape} from "../../redux/actions";
import {isolationFilenames} from "../../redux/selectors";

export default connect(
    (state: StoreShape) => ({
        existingFilenames: isolationFilenames(state)
    }),
    (dispatch) => ({
        onError: (title: string, text: string) => dispatch(displayErrorMessage({
            text,
            title
        }))
    })
)(IsolationUploader);
