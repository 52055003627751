import React, { useState } from "react";
import {Point} from "../../api";
import {CustomMenuData} from "./CustomPointMenu";

interface CustomPointProps {
  translation: Point;
  position: Point;
  scale: number;
  onMenu?: (arg0: CustomMenuData) => void;
  pointId?: number;
  lineId?: number;
  blockAnimation?: boolean;
  blockContext?: boolean;
}

const CustomPoint = (props: CustomPointProps) => {
  const [hover, setHover] = useState(false);
  const pointSize = 10

  return <div style={{
    position: "absolute",
    left: (props.translation.x + props.position.x * props.scale) - pointSize / 2,
    top: (props.translation.y + props.position.y * props.scale) - pointSize / 2,
    zIndex: 1000
  }}>
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: pointSize + "px",
        height: pointSize + "px",
        backgroundColor: "red",
        borderRadius: "5px",
        zIndex: 1,
        transition: "all 0.2s ease-out",
        transformOrigin: "center",
        ...(hover && !props.blockAnimation ? {
          transform: "scale(2)"
        } : null)
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        if (props.blockContext || !props.onMenu) {
          return
        }

        props.onMenu({
          x: props.position.x,
          y: props.position.y,
          lineId: props.lineId
        });
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
    </div>
  </div>;
};

export default CustomPoint;
