import {connect} from "react-redux";
import UploaderFormSectionComponent from "../components/upload/UploaderFormSection";
import {analysisBatchIds} from "../redux/selectors";

export default connect(
    (state, props) => ({
        batchIds: analysisBatchIds(state),
        formModel: "analysisUploaderForm",
    }),
    (dispatch, props) => ({})
)(UploaderFormSectionComponent);
