import {isFloat, isInt} from "validator";

export const isNonNegative = (value: string): boolean => {
    if (value === "") {
        return true;
    }

    return isInt(String(value), {min: 0})
};

export const isPositive = (value: string): boolean => {
    if (value === "") {
        return true;
    }

    return isInt(String(value), {gt: 0})
};

export const isPositiveFloat = (value: string): boolean => {
    if (value === "") {
        return true;
    }

    return isFloat(String(value), {gt: 0});
};

export const required = (value: any): boolean =>
    value !== undefined
    && value !== null
    && String(value).length !== 0;