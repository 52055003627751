import React from 'react';

import {MaskStatistics} from "../../api";

import ImageDetailsIcon from "./ImageDetailsIcon";
import IsolationBatchHeader from "./IsolationBatchHeader";

import TruncatedText from "../TruncatedText";

interface BatchSummaryItem {
    statistics: MaskStatistics;
    included: boolean;
    name: string;
    userCutLines: boolean;
}

interface BatchSummaryProps {
    batchId: string;
    items: BatchSummaryItem[];
    onSelect: (i: number, batchId: string) => void;
    selectedItem: number;
}

class IsolationBatchSummary extends React.Component<BatchSummaryProps> {
    public static defaultProps = {
        onSelect: () => {return;}
    };

    public render() {
        const includedStatistics = this.props.items
            .filter(item => item.included)
            .map(item => item.statistics);

        return (<table className="results-table">
            <thead>
                <IsolationBatchHeader itemCount={this.props.items.length} includedStatistics={includedStatistics}/>
            </thead>

            <tbody>
            {
                this.props.items.map((item, i) => <tr key={i} className={i === this.props.selectedItem ? "active" : ""}>
                    <td onClick={() => this.props.onSelect(i, this.props.batchId)}>
                        <TruncatedText text={item.name} icon={<div title={(item.userCutLines) ? "Custom lines added" : "No custom lines"}><ImageDetailsIcon included={item.included} color={(item.userCutLines) ? "blue" : "red"}/></div>}/>
                    </td>
                    <td>{item.statistics.isletCount}</td>
                    <td>{item.statistics.ieTable.toFixed(2)}</td>
                    <td>{item.statistics.ieEllipse.toFixed(2)}</td>
                    <td>{(item.statistics.purity * 100).toFixed(0)}</td>
                </tr>)
            }
            </tbody>
        </table>);
    }
}


export default IsolationBatchSummary;
