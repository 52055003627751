import React from 'react';

import {connect} from "react-redux";
import {Redirect, RouteComponentProps, withRouter} from "react-router";

import PromptWrapper from "../components/PromptWrapper";

import {SubmittedBatch} from "../components/upload/AnalysisUploader";
import {UploaderContentState} from "../components/Uploader";
import AnalysisUploader from "../containers/upload/AnalysisUploader";

import {
    addAnalysisBatch,
    AnalysisBatchMetadata,
    AnalysisMaskData,
    newAnalysisSession,
} from "../redux/actions";

interface AnalysisProps extends RouteComponentProps<{}> {
    onStartup: () => void;
    onSubmit: (batchMetadata: AnalysisBatchMetadata, items: Array<AnalysisMaskData>) => void;
}

interface AnalysisState {
    uploading: boolean;
    uploaderState: UploaderContentState;
}

class Analysis extends React.Component<AnalysisProps, AnalysisState> {
    constructor(props: AnalysisProps) {
        super(props);
        this.state = {
            uploaderState: {
                modified: false
            },
            uploading: false
        };
    }

    public componentDidMount() {
        this.props.onStartup();
    }

    private onSubmit = (batch: SubmittedBatch) => {
        this.props.onSubmit(batch.batchMetadata, batch.imagesMetadata);
        this.setState({uploading: true});
    };

    private onUploaderStateChanged = (uploaderState: UploaderContentState) => this.setState({uploaderState});

    public render() {
        if (this.state.uploading) {
            return <Redirect to="/analysis/results"/>;
        }

        return <div className="card-wrapper">
            <PromptWrapper
                message="If you leave this page, the data in this session will be lost. Continue?"
                when={this.state.uploaderState.modified}
            />
            <AnalysisUploader onSubmit={this.onSubmit} disabledFields={[]} erasedFields={[]} onStateChanged={this.onUploaderStateChanged}/>
        </div>;
    }
}

export default connect(
    (state) => ({}),
    (dispatch) => ({
        onStartup: () => {
            dispatch(newAnalysisSession());
        },
        onSubmit: (metadata: AnalysisBatchMetadata, items: Array<AnalysisMaskData>) => {
            dispatch(addAnalysisBatch({
                items,
                metadata
            }));
        }
    })
)(withRouter(Analysis));
