import React from "react";
import {actions, Control, LocalForm} from 'react-redux-form';

import uniqueId from 'lodash/uniqueId';
import Icon from "../Icon";

import {MaskStatistics} from "../../api";
import {AsyncRequestState, IsolationBatchMetadata} from "../../redux/actions";

export interface ImageDetailsProps {
    imageId: string;
    imageIndex: number;
    included: boolean;
    isReported: boolean;
    minIsletSize: number;
    onExclude: () => void;
    onInclude: () => void;
    onSetReported: (batchId: string, imageIndex: number, isReported: boolean) => void;
    umPerPx: number;
    batchMetadata: IsolationBatchMetadata;
    maskStatistics: MaskStatistics;
    setReportedState: AsyncRequestState;
}

class ImageDetails extends React.Component<ImageDetailsProps> {
    public static defaultProps = {
        included: false,
        isReported: false,
        onExclude: () => {return;},
        onInclude: () => {return;},
        open: false,
        validated: false
    };

    private formDispatch: any;

    private onFormChanged = (data: any) => {
        const included = data.included === "include";

        if (this.props.included !== included) {
            included ? this.props.onInclude() : this.props.onExclude();
        }

        if (data.reported !== this.props.isReported) {
            this.props.onSetReported(this.props.batchMetadata.batchId, this.props.imageIndex, data.reported);
        }
    };

    private attachFormDispatch = (dispatch: any) => {
        this.formDispatch = dispatch;
    };

    public componentDidUpdate(prevProps: ImageDetailsProps) {
        if (prevProps.minIsletSize !== this.props.minIsletSize) {
            this.formDispatch(actions.change("data.minIsletSize", this.props.minIsletSize));
        }

        if (prevProps.included !== this.props.included) {
            this.formDispatch(actions.change("data.included", this.props.included ? "include" : "exclude"));
        }

        if (prevProps.isReported !== this.props.isReported) {
            this.formDispatch(actions.change("data.reported", this.props.isReported));
        }
    }

    public render() {
        const initialFormState = {
            included: this.props.included ? "include" : "exclude",
            minIsletSize: this.props.minIsletSize,
            reported: this.props.isReported
        };

        const idLeft = uniqueId();
        const idRight = uniqueId();
        const idUseForTraining = uniqueId();

        return (
            <div>
                <table className="navigation-details-table">
                    <tbody>
                    <tr>
                        <td>Microscope:</td>
                        <td>{this.props.batchMetadata.microscope || "-"}</td>
                    </tr>
                    <tr>
                        <td>Camera:</td>
                        <td>{this.props.batchMetadata.camera || "-"}</td>
                    </tr>
                    <tr>
                        <td>Objective:</td>
                        <td>{this.props.batchMetadata.objective ? this.props.batchMetadata.objective + "x" : "-"}</td>
                    </tr>
                    <tr>
                        <td>Pixel size [μm/px]</td>
                        <td>{this.props.umPerPx.toFixed(3)}</td>
                    </tr>
                    </tbody>
                </table>

                <table className="navigation-details-table-middle">
                    <tbody>
                    <tr>
                        <td>Minimum islet size [μm]:</td>
                        <td>{this.props.minIsletSize}</td>
                    </tr>
                    <tr>
                        <td>Sample dilution:</td>
                        <td>{this.props.batchMetadata.dilution}</td>
                    </tr>
                    </tbody>
                </table>

                <LocalForm model="data" onChange={this.onFormChanged} getDispatch={this.attachFormDispatch}
                           initialState={initialFormState}>
                    <table className="navigation-details-table">
                        <tbody>
                        <tr>
                            <td>Islets:</td>
                            <td>{this.props.maskStatistics.isletCount.toFixed(0)}</td>
                        </tr>
                        <tr>
                            <td>IE (table):</td>
                            <td>{this.props.maskStatistics.ieTable.toFixed(0)}</td>
                        </tr>
                        <tr>
                            <td>IE (ellipse):</td>
                            <td>{this.props.maskStatistics.ieEllipse.toFixed(0)}</td>
                        </tr>
                        </tbody>
                    </table>

                    <table className="navigation-details-table">
                        <tbody>
                        <tr>
                            <td>% (purity, area):</td>
                            <td>{(this.props.maskStatistics.purity * 100).toFixed(0)}</td>
                        </tr>
                        <tr>
                            <td>mL (pellet):</td>
                            <td>{this.props.batchMetadata.pelletVolumeMl.toFixed(1)}</td>
                        </tr>
                        </tbody>
                    </table>

                    {/* ===== Validace obrázku - po zaškrtnutí se zobrazí příslušná ikonka i v results-table + v triggeru aktivního panelu   ===== */}
                    <div className="switch-field">
                        <Control.radio model=".included" id={idLeft} value="include"/>
                        <label htmlFor={idLeft} title="Include image in the Overall Summary."><Icon name="check-circle"/> Include</label>
                        <Control.radio model=".included" id={idRight} value="exclude"/>
                        <label htmlFor={idRight} title="Remove image (artifact) from Overall Summary and use it for training."><Icon name="close"/> Exclude</label>
                    </div>
                    <div className="training-checkbox-wrap">
                        <label htmlFor={idUseForTraining} title="Even correctly segmented images can be used for training.">
                            <Control.checkbox
                                id={idUseForTraining}
                                model=".reported"
                                disabled={this.props.setReportedState === AsyncRequestState.PENDING}
                            /> Use for training
                        </label>
                    </div>
                </LocalForm>
            </div>
        );
    }
}

export default ImageDetails;