import {connect} from "react-redux";

import AnalysisMaskDetails, {AnalysisMaskDetailsProps} from "../../components/AnalysisMaskDetails";
import {setAnalysisIncluded} from "../../redux/actions";


export default connect(
    (state: any, props: AnalysisMaskDetailsProps) => ({
        included: state.analysisBatches[props.batchMetadata.batchId].items[props.imageIndex].included,
    }),
    (dispatch, props) => ({
        onExclude: () => dispatch(setAnalysisIncluded({
            batchId: props.batchMetadata.batchId,
            imageIndex: props.imageIndex,
            included: false
        })),
        onInclude: () => dispatch(setAnalysisIncluded({
            batchId: props.batchMetadata.batchId,
            imageIndex: props.imageIndex,
            included: true
        }))
    })
)(AnalysisMaskDetails);

