import React from "react";
import {actions, Control, LocalForm} from "react-redux-form";

import uniqueId from 'lodash/uniqueId';

import {AsyncRequestState, Pix2PixBatchMetadata} from "../../redux/actions";
import {MaskStatistics, Pix2PixResult} from "../../api/models";

export interface Pix2PixImageDetailsProps extends React.Props<Pix2PixImageDetails> {
    batchMetadata: Pix2PixBatchMetadata;
    comparisonResults: Pix2PixResult;
    computedMaskStatistics: MaskStatistics;
    imageIndex: number;
    isReported: boolean;
    onSetReported: (batchId: string, imageIndex: number, isReported: boolean) => void;
    setReportedState: AsyncRequestState;
    userMaskStatistics: MaskStatistics;
    umPerPx: number;
}

const TdRight: React.SFC<React.Props<any>> = (props) => <td style={{textAlign: "right"}}>{props.children}</td>;
const ThRight: React.SFC<React.Props<any>> = (props) => <th style={{textAlign: "right"}}>{props.children}</th>;

class Pix2PixImageDetails extends React.Component<Pix2PixImageDetailsProps> {
    private formDispatch: any;

    private onFormChanged = (data: any) => {
        if (data.reported !== this.props.isReported) {
            this.props.onSetReported(this.props.batchMetadata.batchId, this.props.imageIndex, data.reported);
        }
    };

    private attachFormDispatch = (dispatch: any) => {
        this.formDispatch = dispatch;
    };

    public componentDidUpdate(prevProps: Pix2PixImageDetailsProps) {
        if (prevProps.isReported !== this.props.isReported) {
            this.formDispatch(actions.change("data.reported", this.props.isReported));
        }
    }

    public render() {
        const idUseForTraining = uniqueId();

        return (
            <div>
                <table className="navigation-details-table">
                    <thead className="navigation-details-table-head">
                          <tr>
                             <th/>
                             <ThRight>IsletNet</ThRight>
                             <ThRight>Standard</ThRight>
                          </tr>
                    </thead>
                    <tbody className="navigation-details-table-two-col">
                    <tr>
                        <td>Islets:</td>
                        <td>{this.props.computedMaskStatistics.isletCount}</td>
                        <TdRight>{this.props.userMaskStatistics.isletCount}</TdRight>
                    </tr>
                    <tr>
                        <td>IE (table):</td>
                        <td>{this.props.computedMaskStatistics.ieTable.toFixed(0)}</td>
                        <TdRight>{this.props.userMaskStatistics.ieTable.toFixed(0)}</TdRight>
                    </tr>
                    <tr>
                        <td>IE (ellipse):</td>
                        <td>{this.props.computedMaskStatistics.ieEllipse.toFixed(0)}</td>
                        <TdRight>{this.props.userMaskStatistics.ieEllipse.toFixed(0)}</TdRight>
                    </tr>
                    <tr>
                        <td>Purity (area) [%]:</td>
                        <td>{(this.props.computedMaskStatistics.purity * 100).toFixed(0)}</td>
                        <TdRight>{(this.props.userMaskStatistics.purity * 100).toFixed(0)}</TdRight>
                    </tr>
                    </tbody>
                </table>

                <table className="navigation-details-table-middle">
                    <tbody>
                    <tr>
                        <td>Rel. islet area:</td>
                        <td>{this.props.comparisonResults.relativeIsletArea.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Rel. islet count:</td>
                        <td>{this.props.comparisonResults.relativeIsletCount.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Rel. islet volume (table):</td>
                        <td>{this.props.comparisonResults.relativeIsletVolumeTable.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Rel. islet volume (ellipse):</td>
                        <td>{this.props.comparisonResults.relativeIsletVolumeEllipse.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Rel. islet volume (spinacle):</td>
                        <td>{this.props.comparisonResults.relativeIsletVolumeSpinacle.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Rel. purity</td>
                        <td>{this.props.comparisonResults.relativePurity.toFixed(2)}</td>
                    </tr>
                    </tbody>
                </table>
                <table className="navigation-details-table">
                    <tbody>
                    <tr>
                        <td>Pixel size [μm/px]:</td>
                        <td colSpan={2}>{this.props.umPerPx.toFixed(3)}</td>
                    </tr>
                    <tr>
                        <td>Minimum islet size [μm]:</td>
                        <td colSpan={2}>{this.props.batchMetadata.minIsletSize.toFixed(0)}</td>
                    </tr>
                    </tbody>
                </table>
                <LocalForm model="data" onChange={this.onFormChanged} getDispatch={this.attachFormDispatch}
                           initialState={{reported: this.props.isReported}}>
                    <div className="training-checkbox-wrap">
                        <label htmlFor={idUseForTraining}>
                            <Control.checkbox
                                id={idUseForTraining}
                                model=".reported"
                                disabled={this.props.setReportedState === AsyncRequestState.PENDING}
                            /> Use for training
                        </label>
                    </div>
                </LocalForm>
            </div>
        );
    }
}

export default Pix2PixImageDetails;
