import {connect} from "react-redux";
import IsolationBatchResultList from "../components/results/IsolationBatchResultList";
import {excludeBatch, includeBatch, removeBatch} from "../redux/actions";

export default connect(
    () => ({}),
    (dispatch) => ({
        onExcludeBatch: (batchId: string) => {
            dispatch(excludeBatch({batchId}));
        },
        onIncludeBatch: (batchId: string) => {
            dispatch(includeBatch({batchId}));
        },
        onRemoveBatch: (batchId: string) => {
            dispatch(removeBatch({batchId}));
        },
    })
)(IsolationBatchResultList);