import {AnalysisResult, IsolationResult, Pix2PixResult} from "../../api";
import {AnalysisBatchState, IsolationBatchState, Pix2PixBatchState} from "../../redux/actions";
import {AnalysisBatchResult, IsolationBatchResult, Pix2PixBatchResult} from "./types";

import {sum} from "lodash";

export const mergeBatchesWithResults = (
    results: { [requestId: string]: IsolationResult },
    batches: { [batchId: string]: IsolationBatchState }):
    { [batchId: string]: IsolationBatchResult } =>
{
    const result: any = {};

    Object.values(batches).map(batch => ({
        ...batch,
        items: batch.items.map(item => ({
            ...item,
            result: results[Object.keys(results).find(id => id === item.isolationRequestId)!]
        }))
    })).forEach(batch => result[batch.metadata.batchId] = batch);

    return result;
};

export const mergeAnalysisBatchesWithResults = (
    results: { [requestId: string]: AnalysisResult },
    batches: { [batchId: string]: AnalysisBatchState }):
    { [batchId: string]: AnalysisBatchResult } =>
{
    const result: any = {};

    Object.values(batches).map(batch => ({
        ...batch,
        items: batch.items.map(item => ({
            ...item,
            result: results[Object.keys(results).find(id => id === item.requestId)!]
        }))
    })).forEach(batch => result[batch.metadata.batchId] = batch);

    return result;
};

export const mergePix2PixBatchesWithResults = (
    results: { [requestId: string]: Pix2PixResult },
    batches: { [batchId: string]: Pix2PixBatchState }):
    { [batchId: string]: Pix2PixBatchResult } =>
{
    const result: any = {};

    Object.values(batches).map(batch => ({
        ...batch,
        items: batch.items.map(item => ({
            ...item,
            result: results[Object.keys(results).find(id => id === item.requestId)!]
        }))
    })).forEach(batch => result[batch.metadata.batchId] = batch);

    return result;
};

export const mean = (items: number[]) => items.length > 0
    ? items.reduce((a, b) => a + b, 0) / items.length
    : 0;

export const harmonicMean = (items: number[]) => items.length > 0
    ? items.length / sum(items.map(i => 1 / i))
    : 0;

export const zeroToDash = (value: number|string): string => {
    if (value === "0" || value === 0) {
        return "-";
    }

    return value.toString();
};
