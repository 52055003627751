/* eslint-disable */
// tslint:disable
/**
 * Isletnet API
 *  # Islets  Rest API for [IsletNet](http://isletnet.com/) - an accurate neural network based tool for automated segmentation and analysis  of pancreatic (Langerhans) islets. For more information, see the IsletNet [User guide](http://isletnet.com/guide) or try the tool at  [www.isletnet.com](http://isletnet.com/).  Both IsletNet and this API serve for two main usecases:  ## Analysis   The main usecase.   User submits a request:   - images of the tissue AND/OR own segmentations (can be mixed) - user input data (information about batch of images)   The API returns the response containing: - automated segmentation of islets for each submitted image of the tissue  - histogram of islet size distribution for each image - results of the analysis (both detailed results per image and overall summary)  ## Validation  The usecase to assess the accuracy of one\'s own segmentation by submitting both the segmentation and the  original image. The API returns the comparison of his segmentation to the automated one, returning both  visual and statistical summary.  User submits a request:  - image&own_segmentation (mask) pairs  The API returns the response containing: - results of validation against the automated segmentation - visual comparison 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Line,
    LineFromJSON,
    LineFromJSONTyped,
    LineToJSON,
} from './';

/**
 * 
 * @export
 * @interface Pix2PixRequest
 */
export interface Pix2PixRequest {
    /**
     * 
     * @type {string}
     * @memberof Pix2PixRequest
     */
    imageId: string;
    /**
     * 
     * @type {number}
     * @memberof Pix2PixRequest
     */
    minIsletSize: number;
    /**
     * 
     * @type {number}
     * @memberof Pix2PixRequest
     */
    umPerPx: number;
    /**
     * 
     * @type {Array<Line>}
     * @memberof Pix2PixRequest
     */
    userCutLines?: Array<Line>;
    /**
     * 
     * @type {string}
     * @memberof Pix2PixRequest
     */
    userMaskId: string;
}

export function Pix2PixRequestFromJSON(json: any): Pix2PixRequest {
    return Pix2PixRequestFromJSONTyped(json, false);
}

export function Pix2PixRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pix2PixRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageId': json['image_id'],
        'minIsletSize': json['min_islet_size'],
        'umPerPx': json['um_per_px'],
        'userCutLines': !exists(json, 'user_cut_lines') ? undefined : (json['user_cut_lines'] as Array<any>).map(LineFromJSON),
        'userMaskId': json['user_mask_id'],
    };
}

export function Pix2PixRequestToJSON(value?: Pix2PixRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_id': value.imageId,
        'min_islet_size': value.minIsletSize,
        'um_per_px': value.umPerPx,
        'user_cut_lines': value.userCutLines == null ? undefined : (value.userCutLines as Array<any>).map(LineToJSON),
        'user_mask_id': value.userMaskId,
    };
}


