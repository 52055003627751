import React from "react";
import Modal, {Props as ModalProps} from "react-modal";
import ClickableLink from "./ClickableLink";

import noScroll from "no-scroll";

interface ModalWindowProps extends ModalProps {
    title?: React.ReactNode;
    hideLinesSwitch?: React.ReactNode;
    children: (onRequestClose: ModalProps["onRequestClose"]) => React.ReactNode;
}

class ModalWindow extends React.Component<ModalWindowProps> {
    public static defaultProps = {style: {overlay: {zIndex: 1}}, title: ""};

    private onRequestClose = (data: any) => {
        noScroll.off();

        if (this.props.onRequestClose) {
            this.props.onRequestClose(data);
        }
    };

    private onOpen = () => {
        noScroll.on();
    };

    public render() {
        const {style, title, onRequestClose, hideLinesSwitch, ...props} = {...this.props, onRequestClose: this.onRequestClose};

        return <Modal style={style} {...props} onAfterOpen={this.onOpen}>
            <div className="modal-inner">
                <div className="hide-lines">{hideLinesSwitch}</div>
                <div className="close-btn-wrapper">
                    <div className="modal-title">{title}</div>
                    <ClickableLink onClick={onRequestClose} className="close-btn"/>
                </div>
                <div className="modal-content">
                    {props.children(onRequestClose)}
                </div>
            </div>
        </Modal>;
    }
}

export default ModalWindow;