import {createForms} from "react-redux-form";
import {applyMiddleware, combineReducers, compose, createStore, Store} from 'redux';
import persistState, {mergePersistedState} from 'redux-localstorage';
import filter from 'redux-localstorage-filter';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import createSagaMiddleware from "redux-saga";

import reducers, {StoreShape} from "./actions";
import forms from "./forms";
import rootSaga from "./sagas";

import {lock, refresh, sleep, unlock} from "../utils/lock";

const sagaMiddleware = createSagaMiddleware();

const enhancers = [];

enhancers.push(applyMiddleware(sagaMiddleware));

if (window !== undefined) {
    const storage = filter([
        "analysisUploaderForm",
        "analysisBatches",
        "isolationBatches",
        "imageContentCache",
        "isolationUploaderForm",
        "pix2pixBatches",
        "pix2pixUploaderForm",
        "simpleComparisonBatches"
    ])(adapter(window.localStorage));
    enhancers.push(persistState(storage, "redux"));

    if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
    }
}

const reducer = mergePersistedState()(combineReducers({...reducers, ...createForms(forms)}));

const enhancer = compose(...enhancers);

const getStore = async () => {
    if (window !== undefined) {
        const locked = await lock("global-lock");
        if (!locked) {
            throw new Error();
        }

        window.addEventListener("onbeforeunload", () => unlock("global-lock"));

        (async () => {
            while (true) {
                await sleep(400);
                refresh("global-lock");
            }
        })();
    }

    const store: Store<StoreShape> = createStore(reducer, enhancer as any);
    sagaMiddleware.run(rootSaga);

    return store;
};

export default getStore;