import React from "react";
import {Icon as FaIcon} from 'react-fa';
import {any} from "prop-types";

const Icon = (props) => {
    props = Object.assign({}, props, {Component: "i"});

    return (
        <FaIcon {...props} />
    );
};

Icon.propTypes = {...FaIcon.propTypes, style: any};

export default Icon;
