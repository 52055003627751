import {connect} from "react-redux";

import AnalysisUploader from "../../components/upload/AnalysisUploader";
import {displayErrorMessage, StoreShape} from "../../redux/actions";
import {analysisFilenames} from "../../redux/selectors";

export default connect(
    (store: StoreShape) => ({
        existingFilenames: analysisFilenames(store)
    }),
    (dispatch) => ({
        onError: (title: string, text: string) => dispatch(displayErrorMessage({
            text,
            title
        }))
    })
)(AnalysisUploader);
