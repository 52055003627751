import {connect} from "react-redux";

import {flatten} from "lodash";

import Pix2PixBatchResultContainer from "../../components/results/Pix2PixBatchResultContainer";
import {AsyncRequestState, imageDownloadRequested, StoreShape} from "../../redux/actions";
import {pix2pixResultData} from "../../redux/selectors";

export default connect(
    (state: StoreShape) => {
        const results = pix2pixResultData(state);
        return ({
            imagesLoading: flatten(Object.values(results).map(b => Pix2PixBatchResultContainer.getDisplayedImages(b.items)))
                .map(id => state.imageDownloadStatus[id]).some(status =>
                    status === undefined ||
                    status === AsyncRequestState.PENDING ||
                    status === AsyncRequestState.NONE
                )
        });
    },
    dispatch => ({
        onStartLoading: (imageIds: string[]) => {
            imageIds.forEach(imageId => dispatch(imageDownloadRequested({imageId})))
        }
    })
)(Pix2PixBatchResultContainer);