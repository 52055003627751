import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";

import {
    addAnalysisBatch,
    AnalysisBatchMetadata, AnalysisBatchState,
    AnalysisMaskData,
    AsyncRequestState,
    editAnalysisBatch,
    newAnalysisSession,
    removeAnalysisBatch,
    StoreShape
} from "../redux/actions";

import PromptWrapper from "../components/PromptWrapper";

import LoadingScreen from "../components/LoadingScreen";
import ModalWindow from "../components/ModalWindow";
import AnalysisBatchResultList from "../components/results/AnalysisBatchResultList";
import {SubmittedBatch} from "../components/upload/AnalysisUploader";
import AnalysisSummary from "../containers/AnalysisSummary";
import {
    analysisImageCount,
    analysisResultData,
    analysisStatus,
    analysisSubmissionStatus
} from "../redux/selectors";


interface AnalysisResultsProps extends React.Props<AnalysisResults> {
    batchCount: number;
    imageCount: number;
    batches: {[batchId: string]: AnalysisBatchState};
    onBatchAdded: (batchMetadata: AnalysisBatchMetadata, images: Array<AnalysisMaskData>) => void;
    onBatchEdited: (batchMetadata: AnalysisBatchMetadata, images: Array<AnalysisMaskData>, editedBatchId: string) => void;
    onCloseSession: () => void;
    onRemoveBatch: (batchId: string) => void;
    resultsStatus: AsyncRequestState;
    submissionStatus: AsyncRequestState;
}

interface AnalysisResultsState {
    summaryOpen: boolean;
}

class AnalysisResults extends React.Component<AnalysisResultsProps, AnalysisResultsState> {
    constructor(props: AnalysisResultsProps) {
        super(props);

        this.state = {
            summaryOpen: false
        };
    }


    private openSummary = () => {
        this.setState({
            summaryOpen: true
        })
    };

    private closeSummary = () => {
        this.setState({
            summaryOpen: false
        })
    };

    private onBatchSubmit = (batch: SubmittedBatch, editedBatchId?: string) => {
        if (editedBatchId === undefined) {
            this.props.onBatchAdded(batch.batchMetadata, batch.imagesMetadata);
        } else {
            this.props.onBatchEdited(batch.batchMetadata, batch.imagesMetadata, editedBatchId);
        }
    };

    private closeSession = () => {
        if (!window.confirm("All data in this session will be lost. Continue?")) {
            return;
        }

        this.props.onCloseSession();
    };

    public render() {
        if (this.props.batchCount === 0) {
            return <Redirect to="/analysis"/>
        }

        const Wrapper: React.SFC<React.Props<any>> = props =>
            <div className="card-wrapper bg-gray">{props.children}</div>;

        const Alert: React.SFC<any> = () => <PromptWrapper
            when={this.props.batchCount > 0}
            message="If you leave this page, the data in this session will be lost. Continue?"
        />;

        const eta = this.props.imageCount * 0.25;

        if (this.props.submissionStatus === AsyncRequestState.PENDING) {
            return <Wrapper>
                <Alert/>
                <LoadingScreen text={<>Sending data to the server<br />(estimated remaining time is {eta} minutes)</>}/>
            </Wrapper>;
        }

        if (this.props.submissionStatus === AsyncRequestState.FAILED) {
            return <Wrapper>
                <Alert/>
                <p>There was an error while sending the data to the server. Please make sure your internet connection is not down, 
                    try refreshing the page (F5) and contact us at isletnet@mild.blue if problems persist.</p>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.PENDING || this.props.resultsStatus === AsyncRequestState.NONE) {
            return <Wrapper>
                <Alert/>
                <LoadingScreen text={<>Waiting for the server to process the data<br />(estimated remaining time is {eta} minutes)</>}/>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.EXPIRED) {
            return <Wrapper>
                <Alert/>
                <p>The results for this batch are not available anymore. Try refreshing the page (F5).</p>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.FAILED) {
            return <Wrapper>
                <Alert/>
                <p>Unexpected error happened and some of the requests could not be processed by the server. Please make sure your internet connection is not down, 
                    try refreshing the page (F5) and contact us at isletnet@mild.blue if problems persist.</p>
            </Wrapper>;
        }

        return (
            <Wrapper>
                <ModalWindow isOpen={this.state.summaryOpen} onRequestClose={this.closeSummary}>{requestClose =>
                    <AnalysisSummary onCloseSession={this.closeSession}/>
                }</ModalWindow>
                <Alert/>
                <ResultList onRemoveBatch={this.props.onRemoveBatch} onBatchSubmit={this.onBatchSubmit}
                            onSummary={this.openSummary} />
            </Wrapper>
        );
    }
}

const ResultList = connect(
    (state: StoreShape) => ({
        batches: analysisResultData(state)
    })
)(AnalysisBatchResultList);

export default connect(
    (state: StoreShape) => ({
        batchCount: Object.keys(state.analysisBatches).length,
        imageCount: analysisImageCount(state),
        resultsStatus: analysisStatus(state),
        submissionStatus: analysisSubmissionStatus(state)
    }),
    dispatch => ({
        onBatchAdded: (metadata: AnalysisBatchMetadata, items: Array<AnalysisMaskData>) => {
            dispatch(addAnalysisBatch({metadata, items}));
        },
        onBatchEdited: (metadata: AnalysisBatchMetadata, items: Array<AnalysisMaskData>, editedBatchId: string) => {
            dispatch(editAnalysisBatch({metadata, items, editedBatchId}));
        },
        onCloseSession: () => {
            dispatch(newAnalysisSession());
        },
        onRemoveBatch: (batchId: string) => {
            dispatch(removeAnalysisBatch({batchId}));
        }
    })
)(AnalysisResults);