import React from "react";

import produce from "immer";
import {maxBy, memoize} from "lodash";
import Collapsible from 'react-collapsible';
import classNames from "classnames";

import ClickableLink from "../ClickableLink";
import Icon from "../Icon";
import LoadingScreen from "../LoadingScreen";
import {defaultTabState, TabState} from "./ResultsContent";
import {SubmittedBatch} from "../upload/IsolationUploader";

export interface BatchResultListProps<BatchResultType, SubmittedBatchType> {
    batchEditationTransformer: (batch: BatchResultType) => SubmittedBatchType;
    batches: {
        [batchId: string]: BatchResultType;
    };
    onBatchSubmit: UploaderComponentProps<SubmittedBatch | SubmittedBatchType>["onSubmit"];
    onExcludeBatch: (batchId: string) => void;
    onIncludeBatch: (batchId: string) => void;
    onRemoveBatch: (batchId: string) => void;
    onSummary: () => void;
    resultComponent: React.ComponentType<ResultComponentProps<BatchResultType>>;
    summaryButton?: React.ReactNode;
    uploaderComponent: React.ComponentType<UploaderComponentProps<SubmittedBatchType>>
}

export interface ResultComponentProps<BatchResultType> {
    batch: BatchResultType;
    onSelect: (item: number, batchId: string) => void;
    onTabStateChanged: (state: TabState) => void;
    selectedItem: number;
    tabState: TabState;
}

export interface UploaderComponentProps<SubmittedBatchType> {
    onSubmit: (submittedBatch: SubmittedBatchType | SubmittedBatch, editedBatchId?: string) => void;
    disabledFields: string[];
    erasedFields: string[];
    initialValues?: SubmittedBatchType;
}

interface BatchResultShape {
    items: {result: any}[];
    included: boolean;
    lastModified: number;
}

interface TabListState extends TabState {
    batchId: string;
}

interface BatchResultListState {
    addingNewBatch: boolean;
    editedBatch?: string;
    isUploading: boolean;
    openBatches: {
        [batchId: string]: boolean;
    };
    selectedItemMap: {
        [batchId: string]: number;
    };
    tabState: TabListState;
}

class BatchResultList<BatchResultType extends BatchResultShape, SubmittedBatchType> extends React.Component<
    BatchResultListProps<BatchResultType, SubmittedBatchType>,
    BatchResultListState
> {
    constructor(props: BatchResultListProps<BatchResultType, SubmittedBatchType>) {
        super(props);

        const lastModifiedBatchId = this.findLastModifiedBatchId();
        const openBatches = lastModifiedBatchId ? {[lastModifiedBatchId]: true} : {};

        this.state = {
            addingNewBatch: false,
            isUploading: false,
            openBatches,
            selectedItemMap: {},
            tabState: {
                ...defaultTabState,
                batchId: lastModifiedBatchId || ""
            }
        }
    }

    private findLastModifiedBatchId() {
        const lastModifiedBatch = maxBy(Object.entries(this.props.batches), ([id, b]) => b.lastModified || 0);
        if (lastModifiedBatch !== undefined) {
            return lastModifiedBatch[0];
        }

        return undefined;
    }

    public static defaultProps = {
        onBatchSubmit: () => {
            return;
        },
        onExcludeBatch: (batchId: string) => {
            return;
        },
        onIncludeBatch: (batchId: string) => {
            return;
        },
        onRemoveBatch: (batchId: string) => {
            return;
        },
        onSummary: () => {
            return;
        }
    };

    private itemSelected = (item: number, batchId: string) => {
        const batch = this.props.batches[batchId];

        if (batch === undefined || item < 0 || item >= batch!.items.length) {
            return; // out of range
        }

        this.setState((prevState => produce(prevState, draft => {
            draft.selectedItemMap[batchId] = item;
        })));
    };

    private tabStateChanged = memoize((batchId: string) => (tabState: TabState) => this.setState({
        tabState: {...tabState, batchId}
    }));

    private batchSelected = (batchId?: string|number) => {
        if (batchId === undefined) {
            return;
        }

        this.setState(prevState => produce(prevState, draft => {
            draft.openBatches[batchId] = !prevState.openBatches[batchId];
        }));
    };

    private toggleNewBatch = () => {
        this.setState(prevState => produce(prevState, draft => {
            if (!draft.addingNewBatch) {
                Object.keys(draft.openBatches).forEach(key => {
                    draft.openBatches[key] = false;
                })
            } else if (!Object.values(draft.openBatches).some(open => open)) {
                draft.openBatches[Object.keys(this.props.batches)[0]] = true;
            }

            draft.addingNewBatch = !draft.addingNewBatch;

            if (draft.addingNewBatch) {
                draft.editedBatch = undefined;
            }
        }));
    };

    private onBatchSubmit = (submittedBatch: SubmittedBatch) => {
        this.setState({isUploading: true});
        if (this.state.editedBatch && submittedBatch.batchMetadata.initialBatchId) {
            const items = this.props.batches[submittedBatch.batchMetadata.initialBatchId].items;
            for (let i = 0; i < items.length; i++ ) {
                submittedBatch.imagesMetadata[i] = {...submittedBatch.imagesMetadata[i], userCutLines: items[i].result.userCutLines}
            }
        }
        this.props.onBatchSubmit(submittedBatch, this.state.editedBatch || undefined);
        this.setState({editedBatch: undefined});
    };

    public componentWillReceiveProps(nextProps: BatchResultListProps<BatchResultType, SubmittedBatchType>) {
        if (nextProps.batches.length > this.props.batches.length) {
            this.setState({isUploading: false});
        }
    }

    private onRemoveBatch = (batchId: string) => () => {
        if (window.confirm(`Data in batch "${batchId}" will be lost. Continue?`)) {
            this.props.onRemoveBatch(batchId);
        }
    };

    private onEditBatch = (batchId: string) => () => {
        this.setState(prevState => produce(prevState, draft => {
            draft.addingNewBatch = false;

            if (prevState.editedBatch === batchId) {
                // If the same batch id is provided twice, we cancel the editation
                draft.editedBatch = undefined;
            } else {
                draft.editedBatch = batchId;
                draft.openBatches[batchId] = true;
            }
        }));
    };

    private static sortCompare = (a: [string, any], b: [string, any]) => a[0].localeCompare(b[0]);

    public render() {
        if (Object.keys(this.props.batches).length === 0) {
            return <div>No batches</div>
        }

        return <div className={classNames({"results-content-collapsible": true, "showing-results": this.state.editedBatch === undefined})}>
            {
                Object.entries(this.props.batches).sort(BatchResultList.sortCompare).map(([batchId, batch]) => {
                    const selectedItem = this.state.selectedItemMap[batchId] || 0;
                    const batchIncluded = this.props.batches[batchId].included;
                    const suffix = !batchIncluded ? " (excluded)" : "";

                    return <div key={batchId} className="results-header row full">
                        <Collapsible transitionTime={100} trigger={<span title="Batches are ordered alphabetically">{batchId + suffix}</span>}
                                     open={this.state.openBatches[batchId] || false} lazyRender={true}
                                     accordionPosition={batchId}
                                     handleTriggerClick={this.batchSelected}>
                            {
                                this.state.editedBatch !== batchId
                                    ? <this.props.resultComponent
                                        batch={batch}
                                        selectedItem={selectedItem}
                                        onSelect={this.itemSelected}
                                        onTabStateChanged={this.tabStateChanged(batchId)}
                                        tabState={produce(this.state.tabState, draft => {
                                            if (draft.zoomed && draft.batchId !== batchId) {
                                                draft.zoomed = false;
                                            }
                                        })}/>
                                    : <this.props.uploaderComponent
                                        initialValues={this.props.batchEditationTransformer(batch)}
                                        onSubmit={this.onBatchSubmit}
                                        disabledFields={["sessionCode"]}
                                        erasedFields={[]} />
                            }
                        </Collapsible>

                        <ClickableLink className="results-header-edit"
                                       onClick={this.onEditBatch(batchId)}>
                            <Icon name="pencil"/>{this.state.editedBatch !== batchId ? "Edit batch" : "Cancel editing"}
                        </ClickableLink>
                        {/* the following link had originally class `results-header-remove`, the purpose of changing it to `results-header-exclude` is to enforce alignment to the right */}
                        <ClickableLink className="results-header-exclude"
                                       onClick={this.onRemoveBatch(batchId)}>
                            <Icon name="trash"/>Remove batch
                        </ClickableLink>
                        {/*{*/}
                        {/*batchIncluded*/}
                        {/*? <ClickableLink className="results-header-exclude"*/}
                        {/*onClick={() => this.props.onExcludeBatch(batch.id)}><Icon*/}
                        {/*name="close"/>Exclude whole batch</ClickableLink>*/}
                        {/*: <ClickableLink className="results-header-exclude"*/}
                        {/*onClick={() => this.props.onIncludeBatch(batch.id)}><Icon*/}
                        {/*name="check"/>Re-include the batch</ClickableLink>*/}
                        {/*}*/}
                    </div>;
                })
            }

            <Collapsible {...{className: "add-item"}} openedClassName={"add-item-opened"} transitionTime={100} trigger="Add another batch"
                         open={this.state.addingNewBatch} handleTriggerClick={this.toggleNewBatch}>
                <div>
                    {
                        this.state.isUploading
                            ? <LoadingScreen text="Uploading data to the server"/>
                            : this.state.addingNewBatch &&
                                <this.props.uploaderComponent
                                    onSubmit={this.onBatchSubmit}
                                    disabledFields={["sessionCode"]}
                                    erasedFields={["batchId"]}
                                    key={`@@@___new_batch_${this.state.addingNewBatch}`}
                                />
                    }
                    {
                        this.props.summaryButton !== undefined
                            ? this.props.summaryButton
                            : <button className="summary-btn" onClick={this.props.onSummary}>Show summary</button>
                    }
                </div>
            </Collapsible>
        </div>;
    }
}

export default BatchResultList;
