import React from "react";

import {uniqueId} from "lodash";
import {Control, LocalForm} from "react-redux-form";

import Icon from "./Icon";

import {MaskStatistics} from "../api";
import {AnalysisBatchMetadata} from "../redux/actions";

export interface AnalysisMaskDetailsProps extends React.Props<any> {
    batchMetadata: AnalysisBatchMetadata;
    imageIndex: number;
    included: boolean;
    maskStatistics: MaskStatistics;
    onInclude: () => void;
    onExclude: () => void;
}

const AnalysisMaskDetails: React.SFC<AnalysisMaskDetailsProps> = (props) => {
    const initialFormState = {
        included: props.included ? "include" : "exclude",
    };

    const idLeft = uniqueId();
    const idRight = uniqueId();

    const onFormChanged = (data: any) => {
        const included = data.included === "include";

        if (props.included !== included) {
            included ? props.onInclude() : props.onExclude();
        }
    };

    return <div>
        <table className="navigation-details-table">
            <tbody>
            <tr>
                <td>Microscope:</td>
                <td>{props.batchMetadata.microscope || "-"}</td>
            </tr>
            <tr>
                <td>Camera:</td>
                <td>{props.batchMetadata.camera || "-"}</td>
            </tr>
            <tr>
                <td>Objective:</td>
                <td>{props.batchMetadata.objective ? props.batchMetadata.objective + "x" : "-"}</td>
            </tr>
            <tr>
                <td>Pixel size [μm/px]</td>
                <td>{props.batchMetadata.umPerPx.toFixed(3)}</td>
            </tr>
            </tbody>
        </table>

        <table className="navigation-details-table-middle">
            <tbody>
            <tr>
                <td>Purity (area) [%]:</td>
                <td>{(props.maskStatistics.purity * 100).toFixed(0)}</td>
            </tr>
            <tr>
                <td>Minimum islet size [μm]:</td>
                <td>{props.batchMetadata.minIsletSize}</td>
            </tr>
            </tbody>
        </table>

        <LocalForm model="data" initialState={initialFormState} onChange={onFormChanged}>
            <table className="navigation-details-table">
                <tbody>
                <tr>
                    <td>Islets:</td>
                    <td>{props.maskStatistics.isletCount.toFixed(0)}</td>
                </tr>
                <tr>
                    <td>IE (table):</td>
                    <td>{props.maskStatistics.ieTable.toFixed(0)}</td>
                </tr>
                <tr>
                    <td>IE (ellipse):</td>
                    <td>{props.maskStatistics.ieEllipse.toFixed(0)}</td>
                </tr>
                </tbody>
            </table>

            <div className="switch-field">
                <Control.radio model=".included" id={idLeft} value="include"/>
                <label htmlFor={idLeft} title="Include image in the Overall Summary."><Icon name="check-circle"/> Include</label>
                <Control.radio model=".included" id={idRight} value="exclude"/>
                <label htmlFor={idRight} title="Remove image from Overall Summary."><Icon name="close"/> Exclude</label>
            </div>
        </LocalForm>
    </div>;
};

export default AnalysisMaskDetails;