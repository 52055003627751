import React, {Component} from "react";
import {connect} from "react-redux";

import ModalWindow from "../components/ModalWindow";
import {hideLastErrorMessage, NotificationMessagePayload} from "../redux/actions";
import {lastErrorMessage} from "../redux/selectors";

interface ErrorWindowProps {
    message?: NotificationMessagePayload;
    onClose: () => void;
}

class ErrorWindow extends Component<ErrorWindowProps> {
    public render() {
        return <ModalWindow
            isOpen={this.props.message !== undefined}
            onRequestClose={this.props.onClose}
            bodyOpenClassName="error-message"
            style={{
                overlay: {
                    zIndex: 10
                }
            }}
        >{requestClose => <>
            <h1>{this.props.message !== undefined ? this.props.message!.title : ""}</h1>
            <div>
                {this.props.message !== undefined ? this.props.message!.text : ""}
            </div>
            <div>
                <button onClick={requestClose as any}>OK</button>
            </div>
        </>}</ModalWindow>;
    }
}

export default connect(
    (state: any) => ({
        message: lastErrorMessage(state)
    }),
    (dispatch) => ({
        onClose: () => {
            return dispatch(hideLastErrorMessage({}));
        }
    })
)(ErrorWindow);