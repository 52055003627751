import React, {useEffect, useMemo, useRef} from "react";
import {actions, Control, LocalForm} from "react-redux-form";

import {uniqueId} from 'lodash';

import {AsyncRequestState} from "../../redux/actions";
import {ComparisonResult, MethodUser} from "../../api/models";

export interface Props {
    method: MethodUser;
    result: ComparisonResult;
    imageIndex: number;
    isReported: boolean;
    onSetReported: (imageIndex: number, isReported: boolean) => void;
    setReportedState: AsyncRequestState;
    umPerPx: number;
    minIsletSize: number;
}

const TdRight: React.SFC<React.Props<any>> = (props) => <td style={{textAlign: "right"}}>{props.children}</td>;
const ThRight: React.SFC<React.Props<any>> = (props) => <th style={{textAlign: "right"}}>{props.children}</th>;

const SimpleComparisonImageDetails = (props: Props) => {
    const idUseForTraining = useMemo(uniqueId, []);
    const formDispatchRef = useRef<any>(undefined);
    const isReportedRef = useRef(props.isReported);

    useEffect(() => {
        if (isReportedRef.current !== props.isReported) {
            formDispatchRef.current(actions.change("data.reported", props.isReported));
            isReportedRef.current = props.isReported;
        }
    }, [props.isReported, props.imageIndex]);

    const onFormChanged = (data: any) => {
        if (data.reported !== props.isReported) {
            props.onSetReported(props.imageIndex, data.reported);
        }
    };

    const ieUser = (method: MethodUser) => {
        if (props.method === method) {
            return props.result.volumeUser.toFixed(0);
        }

        return "-";
    };

    return (
        <div className="simple-comparison-navigation-details">
            <table className="navigation-details-table-alt">
                <thead className="navigation-details-table-head">
                <tr>
                    <th/>
                    <ThRight>IsletNet</ThRight>
                    <ThRight>User</ThRight>
                </tr>
                </thead>
                <tbody className="navigation-details-table-two-col">
                <tr className="navigation-details-table-underline">
                    <td>Islets:</td>
                    <td>{props.result.maskStatistics.isletCount}</td>
                    <TdRight>{props.result.isletCountUser}</TdRight>
                </tr>
                <tr>
                    <td>IE (Ricordi, short):</td>
                    <td>{props.result.maskStatistics.ieRicordiShort.toFixed(0)}</td>
                    <TdRight>{ieUser(MethodUser.RicordiShortArea)}</TdRight>
                </tr>
                <tr>
                    <td>IE (Buchwald):</td>
                    <td>{props.result.maskStatistics.ieBuchwald.toFixed(0)}</td>
                    <TdRight>{ieUser(MethodUser.BuchwaldArea)}</TdRight>
                </tr>
                <tr>
                    <td>IE (Kissler, table):</td>
                    <td>{props.result.maskStatistics.ieKissler.toFixed(0)}</td>
                    <TdRight>{ieUser(MethodUser.KisslerArea)}</TdRight>
                </tr>
                <tr>
                    <td>IE (Ricordi, long):</td>
                    <td>{props.result.maskStatistics.ieRicordiLong.toFixed(0)}</td>
                    <TdRight>{ieUser(MethodUser.RicordiLongArea)}</TdRight>
                </tr>
                <tr>
                    <td>IE (ellipse):</td>
                    <td>{props.result.maskStatistics.ieEllipse.toFixed(0)}</td>
                    <TdRight>{ieUser(MethodUser.Ellipse)}</TdRight>
                </tr>
                <tr>
                    <td>IE (spinacle):</td>
                    <td>{props.result.maskStatistics.ieSpinacle.toFixed(0)}</td>
                    <TdRight>-</TdRight>
                </tr>
                <tr className="navigation-details-table-underline">
                    <td>IE (sphere, area):</td>
                    <td>{props.result.maskStatistics.ieArea.toFixed(0)}</td>
                    <TdRight>{ieUser(MethodUser.AreaSphere)}</TdRight>
                </tr>
                <tr className="navigation-details-table-underline">
                    <td>Purity (area) [%]:</td>
                    <td>{(props.result.maskStatistics.purity * 100).toFixed(0)}</td>
                    <TdRight>{(props.result.purityUser * 100).toFixed(0)}</TdRight>
                </tr>
                <tr>
                    <td>Pixel size [μm/px]:</td>
                    <td colSpan={2}>{props.umPerPx.toFixed(3)}</td>
                </tr>
                <tr>
                    <td>Minimum islet size [μm]:</td>
                    <td colSpan={2}>{props.minIsletSize.toFixed(0)}</td>
                </tr>
                </tbody>
            </table>
            <LocalForm model="data" onChange={onFormChanged} getDispatch={(dispatch => formDispatchRef.current = dispatch)}
                       initialState={{reported: props.isReported}}>
                <div className="training-checkbox-wrap">
                    <label htmlFor={idUseForTraining}>
                        <Control.checkbox
                            id={idUseForTraining}
                            model=".reported"
                            disabled={props.setReportedState === AsyncRequestState.PENDING}
                        /> Use for training
                    </label>
                </div>
            </LocalForm>
        </div>
    );
};

export default SimpleComparisonImageDetails;
