import React from "react";

import {noop} from "lodash";

import {BatchResultListProps, ResultComponentProps} from "./BatchResultList";
import ImageDetailsIcon from "./ImageDetailsIcon";
import ResultsNavigation from "./ResultsNavigation";
import {IsolationBatchResult, IsolationItemResult} from "./types";

import TruncatedText from "../TruncatedText";

import BatchResultList from "../../components/results/BatchResultList";
import IsolationBatchResults from "../../containers/results/IsolationBatchResultContainer";
import IsolationImageDetails from "../../containers/results/IsolationImageDetails";
import IsolationUploader from "../../containers/upload/IsolationUploader";
import {AsyncRequestState} from "../../redux/actions";
import {SubmittedBatch} from "../upload/IsolationUploader";

class ResultContentRenderer extends React.Component<ResultComponentProps<IsolationBatchResult>> {
    private renderItem = (batch: IsolationBatchResult) => (item: IsolationItemResult, i: number) =>
        <IsolationImageDetails key={i} umPerPx={item.umPerPx || batch.metadata.umPerPx}
                               maskStatistics={item.result.maskStatistics} imageIndex={i}
                               batchMetadata={batch.metadata} imageId={item.imageId}
                               included={false} minIsletSize={0} isReported={false} onSetReported={noop} setReportedState={AsyncRequestState.NONE} />;  // TODO this line is a workaround for a strange bug in typing of connected components

    private renderItemHeader = (item: IsolationItemResult, i: number) => <React.Fragment>
        <TruncatedText icon={<ImageDetailsIcon key={i} included={item.included} />} text={item.name} />
    </React.Fragment> ;

    public render() {
        return <><div className="col-3">
                <ResultsNavigation onSelect={this.props.onSelect} batchId={this.props.batch.metadata.batchId}
                                   items={this.props.batch.items} selectedItem={this.props.selectedItem}
                                   itemRenderer={this.renderItem(this.props.batch)}
                                   itemHeaderRenderer={this.renderItemHeader}/>
            </div>
            <div className="col-9">
                <IsolationBatchResults batchId={this.props.batch.metadata.batchId} items={this.props.batch.items}
                                       selectedItem={this.props.selectedItem} tabState={this.props.tabState}
                                       onSelect={this.props.onSelect} onTabStateChanged={this.props.onTabStateChanged}/>
        </div>
        </>
    }
}

const batchEditationTransformer = (batch: IsolationBatchResult): SubmittedBatch => ({
    batchMetadata: batch.metadata,
    imagesMetadata: batch.items
});

interface IsolationBatchResultListProps {
    batches: {
        [batchId: string]: IsolationBatchResult;
    };
    onBatchSubmit: (batch: SubmittedBatch) => void;
    onExcludeBatch: (batchId: string) => void;
    onIncludeBatch: (batchId: string) => void;
    onRemoveBatch: (batchId: string) => void;
    onSummary: BatchResultListProps<IsolationBatchResult, SubmittedBatch>["onSummary"];
}

const IsolationBatchResultList: React.SFC<IsolationBatchResultListProps> = (props) =>
    <BatchResultList
        batchEditationTransformer={batchEditationTransformer}
        batches={props.batches}
        onBatchSubmit={props.onBatchSubmit}
        onExcludeBatch={props.onExcludeBatch}
        onIncludeBatch={props.onIncludeBatch}
        onRemoveBatch={props.onRemoveBatch}
        onSummary={props.onSummary}
        resultComponent={ResultContentRenderer}
        uploaderComponent={IsolationUploader}
    />;


export default IsolationBatchResultList;