import React from "react";
import {connect} from "react-redux";

import download from "downloadjs";
import {flatten, isEqual, zip} from "lodash";

import produce from "immer";
import {Configuration, DefaultApi, IsolationResult} from "../api";
import IsolationSummaryComponent, {IsolationSummaryProps as SummaryComponentProps} from "../components/IsolationSummary";
import LoadingScreen from "../components/LoadingScreen";
import {mergeBatchesWithResults} from "../components/results/helpers";
import {API_URL} from "../env";
import {
    AsyncRequestState,
    displayErrorMessage,
    displayNoticeMessage,
    IsolationBatchState,
    StoreShape
} from "../redux/actions";

interface IsolationSummaryProps extends React.Props<IsolationSummary> {
    batches: {[batchId: string]: IsolationBatchState};
    onCloseSession: () => void;
    onNotice: (title: string, text: string) => void;
    onError: (title: string, text: string) => void;
}

interface IsolationSummaryState {
    results: {[requestId: string]: IsolationResult};
    emailSendingState: AsyncRequestState;
    loaded: boolean;
    pdfCompleteDownloadState: AsyncRequestState;
    pdfDownloadState: AsyncRequestState;
    zipDownloadState: AsyncRequestState;
}

class IsolationSummary extends React.Component<IsolationSummaryProps, IsolationSummaryState> {
    private api = new DefaultApi(new Configuration({basePath: API_URL}));

    constructor(props: IsolationSummaryProps) {
        super(props);
        this.state = {
            emailSendingState: AsyncRequestState.NONE,
            loaded: false,
            pdfCompleteDownloadState: AsyncRequestState.NONE,
            pdfDownloadState: AsyncRequestState.NONE,
            results: {},
            zipDownloadState: AsyncRequestState.NONE
        }
    }

    private getBatchData() {
        return {
            batches: Object.values(this.props.batches).map(batch => ({
                batchMetadata: {
                    batchId: batch.metadata.batchId,
                    camera: batch.metadata.camera,
                    dilution: batch.metadata.dilution,
                    microscope: batch.metadata.microscope,
                    objective: batch.metadata.objective,
                    operator: batch.metadata.operator,
                    pelletVolumeMl: batch.metadata.pelletVolumeMl,
                    sessionCode: batch.metadata.sessionCode,
                },
                requests: batch.items.map(item => ({
                    imageFilename: item.name,
                    included: item.included,
                    requestId: item.isolationRequestId!
                }))
            }))
        };
    }

    private load() {
        this.setState({
            loaded: false
        });

        const requestIds = flatten(Object.values(this.props.batches)
            .map(batch => batch.items.map(item => item.isolationRequestId!))
        );

        Promise.all(requestIds.map(id => this.api.isolationResult({requestId: id}))).then(responses => {
            this.setState(prevState => produce(prevState, draft => {
                draft.loaded = true;

                zip(requestIds, responses).forEach(([requestId, response]) => {
                    draft.results[requestId!] = response!;
                })
            }));
        });
    }

    private getFilename = (response: Response) => {
        const header = response.headers.get("content-disposition");

        if (header === null) {
            return null;
        }

        return header.match(/filename=(.+)/)![1]
    };

    private downloadPDF = async () => {
        try {
            const response = await this.api.isolationPdfSummaryRaw({body: this.getBatchData()});
            const blob = await response.value();

            download(blob, this.getFilename(response.raw) || 'results.pdf');
            this.setState({
                pdfDownloadState: AsyncRequestState.DONE
            });
        } catch (e) {
            this.setState({
                pdfDownloadState: AsyncRequestState.FAILED
            })
        }
    };

    private downloadPDFComplete = async () => {
        this.setState({
            pdfCompleteDownloadState: AsyncRequestState.PENDING
        });

        try {
            const response = await this.api.isolationPdfCompleteRaw({body: this.getBatchData()});
            const blob = await response.value();

            download(blob, this.getFilename(response.raw) || 'results.pdf');
            this.setState({
                pdfCompleteDownloadState: AsyncRequestState.DONE
            });
        } catch (e) {
            this.setState({
                pdfCompleteDownloadState: AsyncRequestState.FAILED
            })
        }
    };

    private downloadXLSX = async () => {
        try {
            const response = await this.api.isolationXlsxImagesRaw({body: this.getBatchData()});
            const blob = await response.value();
            download(blob, this.getFilename(response.raw) || 'results.xlsx');
        } catch (e) {

        }
    };

    private downloadZIP = async () => {
        this.setState({
            zipDownloadState: AsyncRequestState.PENDING
        });

        try {
            const response = await this.api.isolationZipRaw({body: this.getBatchData()});
            const blob = await response.value();

            download(blob, this.getFilename(response.raw) || 'results.zip');
            this.setState({
                zipDownloadState: AsyncRequestState.DONE
            });
        } catch (e) {
            this.setState({
                zipDownloadState: AsyncRequestState.FAILED
            })
        }
    };

    private sendEmail = async (recipients: string[]) => {
        try {
            const response = await this.api.emailResultsRaw({
                body: {
                    recipients,
                    summaryRequest: this.getBatchData()
                }
            });
            if (response.raw.status === 200) {
                this.props.onNotice("E-mail sent", "The e-mail has been sent successfully");
                this.setState({emailSendingState: AsyncRequestState.DONE});
            } else {
                this.props.onError("Failure", "The e-mail could not be sent");
                this.setState({emailSendingState: AsyncRequestState.FAILED});
            }
        } catch (e) {
            this.props.onError("Failure", "The e-mail could not be sent");
            this.setState({emailSendingState: AsyncRequestState.FAILED});
        }
    };

    public componentDidMount() {
        this.load();
    }

    public componentDidUpdate(prevProps: IsolationSummaryProps) {
        if (!isEqual(prevProps.batches, this.props.batches)) {
            this.load();
        }
    }

    public render() {
        const firstBatch = this.props.batches[Object.keys(this.props.batches)[0]];

        if (firstBatch === undefined) {
            return <div>No batches supplied</div>
        }

        const batches: SummaryComponentProps["batches"] = {};

        if (this.state.loaded) {
            const batchesWithResults = mergeBatchesWithResults(this.state.results, this.props.batches);
            Object.entries(batchesWithResults).forEach(([batchId, batch]) => {
                batches[batchId] = {
                    included: batch.included,
                    items: batch.items.map(item => ({
                        included: item.included,
                        statistics: item.result.maskStatistics
                    })),
                    metadata: batch.metadata
                }
            });
        }

        return this.state.loaded
            ? <IsolationSummaryComponent batches={batches} onPDF={this.downloadPDF} onXLSX={this.downloadXLSX}
                                         onPDFComplete={this.downloadPDFComplete}
                                         pdfCompleteDownloadState={this.state.pdfCompleteDownloadState}
                                         onZIP={this.downloadZIP} onSendMail={this.sendEmail}
                                         sessionCode={firstBatch.metadata.sessionCode}
                                         emailSendingState={this.state.emailSendingState}
                                         zipDownloadState={this.state.zipDownloadState}
                                         pdfDownloadState={this.state.pdfDownloadState}
                                         onCloseSession={this.props.onCloseSession}/>
            : <LoadingScreen/>
        ;
    }
}


export default connect(
    (state: StoreShape) => ({
        batches: state.isolationBatches
    }),
    (dispatch) => ({
        onError: (title: string, text: string) => {
            dispatch(displayErrorMessage({title, text}));
        },
        onNotice: (title: string, text: string) => {
            dispatch(displayNoticeMessage({title, text}));
        }
    })
)(IsolationSummary);
