import {connect} from "react-redux";

import Uploader from "../components/Uploader";
import {
    displayErrorMessage,
    imageUploadRequested,
    StoreShape
} from "../redux/actions";

export default connect(
    (state: StoreShape) => ({
        uploadStatus: state.imageUploadStatus
    }), (dispatch) => ({
        onError: (title: string, text: string) => {
            dispatch(displayErrorMessage({title, text}));
        },
        onUploadRequested: (files: File[]) => {
            dispatch(imageUploadRequested({files}));
        },
    })
)(Uploader);