import React from "react";
import {Point} from "../../api";

interface CustomLineProps {
  translation: Point;
  position1: Point;
  position2: Point;
  scale: number;
}

const CustomLine = (props: CustomLineProps) => {
  return <svg
    style={{
      position: "absolute",
      top: 0,
      left: 0
    }}
    height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
    <line x1={(props.translation.x + props.position1.x * props.scale)}
          y1={(props.translation.y + props.position1.y * props.scale)}
          x2={(props.translation.x + props.position2.x * props.scale)}
          y2={(props.translation.y + props.position2.y * props.scale)}
          stroke="red"
    />
  </svg>;
};

export default CustomLine;
