import {connect} from "react-redux";
import UploaderFormSectionComponent from "../components/upload/UploaderFormSection";
import {isolationBatchIds} from "../redux/selectors";

export default connect(
    (state, props) => ({
        batchIds: isolationBatchIds(state),
        formModel: "isolationUploaderForm"
    }),
    (dispatch, props) => ({})
)(UploaderFormSectionComponent);