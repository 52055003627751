import React from "react";

import produce from "immer";
import {isEqual, memoize, noop, zip} from "lodash";
import DropZone from "react-dropzone";

import ClickableLink from "../ClickableLink";
import ModalWindow from '../ModalWindow';
import ZoomedImage from "../ZoomedImage";

import ImageFetcher from "../../containers/ImageFetcher";
import imagePlaceholder from '../../images/image-placeholder.jpg';
import {makePngDataUrl, readImage} from "../../utils/images";
import {matchUploadStatus, UploadStatus, UploadStatusItem} from "../Uploader";

export type UploadListRenderer = (
    items: Array<{
        name: string;
        uploadStatus: UploadStatusItem;
        isSelected: boolean;
    }>
) => [React.ReactElement<any>, string][];

interface UploaderImagesSectionProps<MetadataType> {
    buttonText: string;
    allowedTypes?: string[];
    existingFilenames: string[];
    hint: React.ReactNode;
    imageAttributeNames: string[];
    imageCountRenderer: (droppedMetadata: Array<Partial<MetadataType>>, prefilledMetadata: Array<Partial<MetadataType>>) => string;
    onError: (title: string, text: string) => void;
    onImagesChange: (images: Array<File>, metadata: Array<Partial<MetadataType>>) => void;
    prefilledImages: Array<MetadataType & {imageId: string}>;
    titleText?: string;
    uploadListRenderer: UploadListRenderer;
    uploadStatus: UploadStatus;
    maxImageSizeNote: React.ReactNode;
}

interface UploaderImagesSectionState<MetadataType> {
    files: Array<File>;
    metadata: Array<Partial<MetadataType>>;
    prefilledImages: Array<MetadataType>;
    selectedImage: string | null;
    selectedImageFile: string | null;
    selectedImageId: string | null;
    showHint: boolean;
    zoomed: boolean;
}

class UploaderImagesSection<MetadataType extends {name: string}> extends React.Component<
    UploaderImagesSectionProps<MetadataType>,
    UploaderImagesSectionState<MetadataType>
> {
    public static readonly defaultTypes = ["image/jpeg", "image/png", "image/tiff", "image/bmp"];

    constructor(props: UploaderImagesSectionProps<MetadataType>) {
        super(props);
        this.state = {
            files: [],
            metadata: [],
            prefilledImages: props.prefilledImages,
            selectedImage: null,
            selectedImageFile: null,
            selectedImageId: null,
            showHint: true,
            zoomed: false
        };
    }

    public static defaultProps = {
        buttonText: "Add images",
        imageCountRenderer: (droppedMetadata: Array<{}>, prefilledMetadata: Array<{}>) => {
            const imageCount = droppedMetadata.length + prefilledMetadata.length;
            return `${imageCount} uploaded images`;
        },
        onImagesChange: noop,
        prefilledImages: [],
        titleText: "Uploaded images"
    };

    private onDrop = (acceptedFiles: Array<File>, rejectedFiles: Array<File>) => {
        this.setState((previousState: UploaderImagesSectionState<MetadataType>, props: UploaderImagesSectionProps<MetadataType>) => {
            acceptedFiles = acceptedFiles.filter(file =>
                !previousState.files.map(filesItem => filesItem.name).includes(file.name)
            );

            const existingFiles = acceptedFiles
                .map(f => f.name)
                .filter(name => this.props.existingFilenames.includes(name));

            if (existingFiles.length > 0) {
                this.props.onError(
                    "Duplicate files",
                    `The following files are already present in the session: ${existingFiles.join(", ")}`
                );
                return {};
            }

            return produce(previousState, (draft: UploaderImagesSectionState<MetadataType>) => {
                draft.files.push(...acceptedFiles);

                if (acceptedFiles.length > 0) {
                    this.selectFile(draft, acceptedFiles[acceptedFiles.length - 1].name);
                }

                for (const file of acceptedFiles) {
                    draft.metadata.push({name: file.name} as Partial<MetadataType>);
                }
            });
        });
    };

    private metadataChanged = memoize((fileName: string) => (data: Partial<MetadataType>) => {
        this.setState(previousState => produce(previousState, (draft: UploaderImagesSectionState<MetadataType>) => {
            const index = draft.metadata.findIndex(item => item.name === fileName)!;
            draft.metadata[index] = {...draft.metadata[index], ...data};
        }));
    });

    private prefilledMetadataChanged = (fileName: string) => (data: Partial<MetadataType>) => {
        this.setState(previousState => produce(previousState, (draft: UploaderImagesSectionState<MetadataType>) => {
            const index = draft.prefilledImages.findIndex(item => item.name === fileName)!;
            draft.prefilledImages[index] = {...draft.prefilledImages[index], ...data};
        }));
    };

    private removeFile = (fileName: string) => {
        this.setState(previousState => produce(previousState, (draft: UploaderImagesSectionState<MetadataType>) => {
            const indexToDelete = previousState.files.findIndex(f => f.name === fileName);
            if (indexToDelete === -1) {
                return;
            }

            draft.files.splice(indexToDelete, 1);
            draft.metadata.splice(indexToDelete, 1);

            if (fileName === previousState.selectedImageFile) {
                this.selectSomeFile(draft);
            }
        }));
    };

    private removePrefilledImage = (fileName: string) => {
        const indexToDelete = this.state.prefilledImages.findIndex(f => f.name === fileName);

        if (indexToDelete !== -1) {
            this.setState(state => produce(state, (draft: UploaderImagesSectionState<MetadataType>) => {
                draft.prefilledImages.splice(indexToDelete, 1);

                if (draft.selectedImageFile === fileName) {
                    this.selectSomeFile(draft);
                }
            }));
        }
    };

    private selectSomeFile = (state: UploaderImagesSectionState<MetadataType>) => {
        if (state.files.length > 0) {
            this.selectFile(state, state.files[state.files.length - 1].name);
        } else if (state.prefilledImages.length > 0) {
            this.selectPrefilledImage(state, state.prefilledImages[state.prefilledImages.length - 1].name);
        } else {
            this.selectNothing(state);
        }
    };

    private selectNothing = (state: UploaderImagesSectionState<MetadataType>) => {
        state.selectedImage = null;
        state.selectedImageFile = null;
        state.selectedImageId = null;
    };

    private selectFile = (state: UploaderImagesSectionState<MetadataType>, file: string) => {
        readImage(state.files.find(f => f.name === file)! as File).then(result => {
            this.setState({
                selectedImage: makePngDataUrl(result.imageBase64),
                selectedImageFile: file,
                selectedImageId: null
            })
        });
    };

    private selectFileWrapper = (file: string) => {
        this.setState(state => produce(state, (draft: UploaderImagesSectionState<MetadataType>) => this.selectFile(draft, file)));
    };

    private selectPrefilledImage = (state: UploaderImagesSectionState<MetadataType>, name: string) => {
        state.selectedImage = null;
        state.selectedImageFile = name;
        state.selectedImageId = this.props.prefilledImages.find(i => i.name === name)!.imageId;
    };

    private selectPrefilledImageWrapper = (file: string) => {
        this.setState(state => produce(state, (draft: UploaderImagesSectionState<MetadataType>) => this.selectPrefilledImage(draft, file)));
    };

    public componentDidUpdate(prevProps: UploaderImagesSectionProps<MetadataType>, prevState: UploaderImagesSectionState<MetadataType>) {
        const filesChanged = !isEqual(this.state.files, prevState.files);
        const metadataChanged = !isEqual(this.state.metadata, prevState.metadata);
        const prefilledImagesChanged = !isEqual(this.state.prefilledImages, prevState.prefilledImages);

        if (filesChanged || metadataChanged || prefilledImagesChanged) {
            this.props.onImagesChange(this.state.files, this.state.metadata.concat(this.state.prefilledImages))
        }
    }

    public componentDidMount() {
        if (this.state.selectedImageFile === null || this.state.selectedImageId === null) {
            this.setState(state => produce(state, (draft: UploaderImagesSectionState<MetadataType>) => {
                this.selectSomeFile(draft);
            }));
        }
        
        this.props.onImagesChange(this.state.files, this.state.metadata.concat(this.state.prefilledImages))
    }

    private zoom = () => {
        this.setState((prevState) => produce(prevState, draft => {
            if (draft.selectedImage) {
                draft.zoomed = true;
            }
        }));
    };

    private unzoom = () => {
        this.setState({
            zoomed: false
        });
    };

    private hideHint = () => {
        this.setState({
            showHint: false
        });
    };

    public render() {
        const fileUploadStatus = matchUploadStatus(this.state.files, this.props.uploadStatus);

        const droppedFiles = zip(this.state.files, fileUploadStatus).map(([file, uploadStatus]) => ({
            isSelected: file!.name === this.state.selectedImageFile,
            name: file!.name,
            uploadStatus: uploadStatus!
        }));

        const prefilledImages = this.state.prefilledImages.map(item => ({
            isSelected: item.name === this.state.selectedImageFile,
            name: item.name,
            uploadStatus: {
                done: true,
                error: false
            }
        }));

        const totalImageCount = this.state.files.length + this.state.prefilledImages.length;
        const accept = (this.props.allowedTypes || UploaderImagesSection.defaultTypes).join(",");

        return (
            <DropZone style={{}} onDrop={this.onDrop} accept={accept} disableClick={true}>
                <ModalWindow isOpen={this.state.zoomed} onRequestClose={this.unzoom}>{requestClose =>
                    <ZoomedImage image={this.state.selectedImage!} maxScale={5}/>
                }</ModalWindow>
                <div className="row">

                    <div className="col-6">
                        <h2>{this.props.titleText}</h2>

                        <div className="light-text-color">
                            {this.props.imageCountRenderer(this.state.metadata, this.state.prefilledImages)}

                            {totalImageCount > 0 &&
                                <span className="uploaded-images-list-top-label">{
                                    this.props.imageAttributeNames.map((name, i) =>
                                        <span className="uploaded-images-list-top-label" key={i}>{name}</span>)
                                }</span>
                            }
                        </div>

                        <ul className="uploaded-images-list custom-scrollbar-style">
                            {
                                this.props.uploadListRenderer(prefilledImages.concat(droppedFiles)).map(
                                    ([listItem, name]) => {
                                        const isPrefilled = prefilledImages.map(it => it.name).includes(name);

                                        return React.cloneElement(listItem, isPrefilled
                                            ? {
                                                onClick: this.selectPrefilledImageWrapper,
                                                onMetadataChanged: this.prefilledMetadataChanged(name),
                                                onRemove: this.removePrefilledImage
                                            } : {
                                                onClick: this.selectFileWrapper,
                                                onMetadataChanged: this.metadataChanged(name),
                                                onRemove: this.removeFile
                                            }
                                        )
                                    }
                                )
                            }
                            {this.state.showHint && totalImageCount === 0 &&
                            <div className="hint-box upload">
                                {this.props.hint}
                                <ClickableLink className="close-btn" onClick={this.hideHint}/>
                            </div>
                            }
                        </ul>

                        <DropZone style={{}} onDrop={this.onDrop} accept="image/jpeg,image/png,image/tiff,image/bmp">
                            <button>{this.props.buttonText}</button>
                        </DropZone>

                        <div className="max-image-size-note">
                            {this.props.maxImageSizeNote}
                        </div>

                    </div>

                    <div className="col-6">
                        {
                            this.state.selectedImageId === null
                                ? <img src={this.state.selectedImage || imagePlaceholder} onClick={this.zoom}
                                    className="image-placeholder" alt="Your images will be shown here" />
                                : <ImageFetcher imageId={this.state.selectedImageId} imageRenderer={data =>
                                    <img alt="" src={makePngDataUrl(data)} />} />
                        }
                    </div>

                </div>
            </DropZone>
        );
    }
}

export default UploaderImagesSection;
