import React from "react";
import {connect} from 'react-redux';
import {Redirect} from 'react-router';

import PromptWrapper from "../components/PromptWrapper";

import LoadingScreen from "../components/LoadingScreen";
import ModalWindow from '../components/ModalWindow';
import {SubmittedBatch} from "../components/upload/IsolationUploader";
import IsolationBatchResultList from "../containers/IsolationBatchResultList";
import Summary from "../containers/IsolationSummary";

import {
    addBatch,
    AsyncRequestState,
    editBatch,
    IsolationBatchMetadata,
    IsolationImageData,
    newSession,
    StoreShape
} from "../redux/actions";
import {
    isolationImageCount,
    isolationResultData,
    isolationStatus,
    isolationSubmissionStatus
} from "../redux/selectors";

interface IsolationResultsProps extends React.Props<IsolationResults> {
    batchCount: number;
    imageCount: number;
    onBatchAdded: (batchMetadata: IsolationBatchMetadata, images: Array<IsolationImageData>) => void;
    onBatchEdited: (batchMetadata: IsolationBatchMetadata, images: Array<IsolationImageData>, editedBatchId: string) => void;
    onCloseSession: () => void;
    resultsStatus: AsyncRequestState;
    submissionStatus: AsyncRequestState;
}

interface IsolationResultsState {
    summaryOpen: boolean;
}

class IsolationResults extends React.Component<IsolationResultsProps, IsolationResultsState> {
    constructor(props: IsolationResultsProps) {
        super(props);

        this.state = {
            summaryOpen: false,
        };
    }

    private openSummary = () => {
        this.setState({
            summaryOpen: true
        })
    };

    private closeSummary = () => {
        this.setState({
            summaryOpen: false
        })
    };

    private onBatchSubmit = (batch: SubmittedBatch, editedBatchId?: string) => {
        if (editedBatchId === undefined) {
            this.props.onBatchAdded(batch.batchMetadata, batch.imagesMetadata);
        } else {
            this.props.onBatchEdited(batch.batchMetadata, batch.imagesMetadata, editedBatchId);
        }
    };

    private onCloseSession = () => {
        if (!window.confirm("All data in this session will be lost. Continue?")) {
            return;
        }

        this.props.onCloseSession();
    };

    public render() {
        if (this.props.batchCount === 0) {
            return <Redirect to="/isolation" />
        }

        const Wrapper: React.SFC<React.Props<any>> = props =>
            <div className="card-wrapper bg-gray">{props.children}</div>;

        const Alert: React.SFC<any> = () => <PromptWrapper
            when={this.props.batchCount > 0}
            message="If you leave this page, the data in this session will be lost. Continue?"
        />;

        const eta = this.props.imageCount * 0.25;

        if (this.props.submissionStatus === AsyncRequestState.PENDING) {
            return <Wrapper>
                <Alert/>
                <LoadingScreen text={<>Sending data to the server<br />(estimated remaining time is {eta} minutes)</>}/>
            </Wrapper>;
        }

        if (this.props.submissionStatus === AsyncRequestState.FAILED) {
            return <Wrapper>
                <Alert/>
                <p>There was an error while sending the data to the server. Please make sure your internet connection is not down, 
                    try refreshing the page (F5) and contact us at isletnet@mild.blue if problems persist.</p>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.EXPIRED) {
            return <Wrapper>
                <Alert/>
                <p>The results for this batch are not available anymore. Try refreshing the page (F5).</p>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.PENDING || this.props.resultsStatus === AsyncRequestState.NONE) {
            return <Wrapper>
                <Alert/>
                <LoadingScreen text={<>Waiting for the server to process the data<br />(estimated remaining time is {eta} minutes)</>}/>
            </Wrapper>;
        }

        if (this.props.resultsStatus === AsyncRequestState.FAILED) {
            return <Wrapper>
                <Alert/>
                <p>Unexpected error happened and some of the requests could not be processed by the server. Please make sure your internet connection is not down, 
                    try refreshing the page (F5) and contact us at isletnet@mild.blue if problems persist.</p>
            </Wrapper>;
        }

        return (
            <Wrapper>
                <ModalWindow isOpen={this.state.summaryOpen} onRequestClose={this.closeSummary}>{requestClose =>
                    <Summary onCloseSession={this.onCloseSession}/>
                }</ModalWindow>
                <Alert/>
                <ResultList onBatchSubmit={this.onBatchSubmit} onSummary={this.openSummary}/>
            </Wrapper>
        );
    }
}

const ResultList = connect(
    (state: StoreShape) => ({
        batches: isolationResultData(state)
    })
)(IsolationBatchResultList);

export default connect(
    (state: StoreShape) => ({
        imageCount: isolationImageCount(state),
        batchCount: Object.keys(state.isolationBatches).length,
        resultsStatus: isolationStatus(state),
        submissionStatus: isolationSubmissionStatus(state)
    }),
    (dispatch) => ({
        onBatchAdded: (metadata: IsolationBatchMetadata, items: Array<IsolationImageData>) => {
            dispatch(addBatch({metadata, items}));
        },
        onBatchEdited: (metadata: IsolationBatchMetadata, items: Array<IsolationImageData>, editedBatchId: string) => {
            dispatch(editBatch({metadata, items, editedBatchId}));
        },
        onCloseSession: () => {
            dispatch(newSession());
        }
    })
)(IsolationResults);
